import React, {ReactNode} from 'react';

import {IconView} from '../../IconView';
import {InputProps} from '../../Input';
import {Input} from '../../Input';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {XStack} from '../../XStack';
import {ColumnsSelector} from '../hooks/useColumnsSelector';
import {TableProps} from '../model';

import {VisibleColumnsSelector} from './RowSelector/VisibleColumnsSelector';

interface Props<T> {
  title: ReactNode;
  columns: TableProps<T>['columns'];
  columnsSelector: ColumnsSelector<T>;
  filterable?: number | ((value: string) => void);
  onFilter?: (value: string) => void;
  defaultFilter?: (value: string) => void;
  filterInputProps?: InputProps;
}
export function TableHeader<T>({
  title,
  filterable,
  columns,
  columnsSelector,
  onFilter,
  defaultFilter,
  filterInputProps,
}: Props<T>) {
  return (
    <XStack
      theme="translucent"
      gap="$1"
      alignItems="center"
      backgroundColor="$bg-primary"
      paddingVertical="$3"
      paddingHorizontal="$6"
      $xs={{
        [filterable ? 'height' : 'maxHeight']: 128,
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingHorizontal: '$3.5',
        paddingVertical: '$8',
        gap: '$6',
      }}>
      <XStack justifyContent="space-between" alignItems="center" flex={1} width="100%">
        <Typography variant="body1" fontWeight="700">
          {title}
        </Typography>
        <VisibleColumnsSelector columns={columns} columnsSelector={columnsSelector} />
      </XStack>
      {filterable && (
        <View width="40%" $xs={{width: '100%', flex: 1}}>
          <Input
            prefix={<IconView icon="Search01Icon" />}
            placeholder="Search"
            onChangeText={onFilter || defaultFilter}
            {...filterInputProps}
          />
        </View>
      )}
    </XStack>
  );
}
