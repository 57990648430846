import React from 'react';

import {Platform} from 'react-native';
import {
  Adapt,
  ButtonProps,
  PopoverContentProps,
  Popover as BasePopover,
  PopoverProps as BasePopoverProps,
} from 'tamagui';

import {AppletContext, useAppletContext} from '../../context/AppletContext';

export type PopoverProps = {
  children: any;
  trigger: React.JSX.Element;
  hideArrow?: boolean;
  closable?: boolean;
  closeOnClick?: boolean;
  contentProps?: PopoverContentProps;
  triggerProps?: ButtonProps;
} & BasePopoverProps;

export function Popover({
  trigger,
  children,
  hideArrow,
  closable,
  closeOnClick,
  contentProps,
  triggerProps,
  ...props
}: PopoverProps) {
  const appletContext = useAppletContext();

  return (
    <BasePopover size="$5" allowFlip {...props}>
      <BasePopover.Trigger
        hoverStyle={{cursor: 'pointer'}}
        alignItems="center"
        justifyContent="center"
        asChild={Platform.OS !== 'web'}
        {...triggerProps}>
        {trigger}
      </BasePopover.Trigger>
      <Adapt when="sm" platform="touch">
        <BasePopover.Sheet modal dismissOnSnapToBottom>
          <BasePopover.Sheet.Frame padding="$1">
            <AppletContext.Provider value={appletContext}>
              <Adapt.Contents />
            </AppletContext.Provider>
          </BasePopover.Sheet.Frame>
          <BasePopover.Sheet.Overlay zIndex={5} position="absolute" />
        </BasePopover.Sheet>
      </Adapt>
      <BasePopover.Content
        borderWidth={1}
        borderColor="$border"
        enterStyle={{x: 0, y: -10, opacity: 0}}
        exitStyle={{x: 0, y: -10, opacity: 0}}
        x={0}
        y={0}
        opacity={1}
        animation={['quick', {opacity: {overshootClamping: true}}]}
        elevate
        {...contentProps}>
        {!hideArrow && (
          <BasePopover.Arrow size="$5" borderWidth={1} backgroundColor="$background" borderColor="$border" />
        )}

        {!closable && <BasePopover.Close />}

        {closeOnClick ? <BasePopover.Close>{children}</BasePopover.Close> : children}
      </BasePopover.Content>
    </BasePopover>
  );
}
