import {ButtonSizes, ButtonStylesProps, HierarchyTypes} from '../types';

type HeirarchyStylesProps = {default?: ButtonStylesProps; disabled?: ButtonStylesProps; hover?: ButtonStylesProps};

export const getColorsStyleBasedOnHierarchy = (type: HierarchyTypes): HeirarchyStylesProps => {
  const _defaultDiableStyle = {
    backgroundColor: '$bg-primary',
    color: '$fg-disabled',
    borderColor: '$border-disabled-subtle',
  };

  const styles: Record<HierarchyTypes, HeirarchyStylesProps> = {
    primary: {
      default: {
        backgroundColor: '$button-primary-bg',
        color: '$button-primary-fg',
        borderColor: '$button-primary-bg',
      },
      hover: {
        color: '$button-primary-fg-hover',
        backgroundColor: '$button-primary-bg-hover',
        borderColor: '$button-primary-bg-hover',
      },
      disabled: {
        ..._defaultDiableStyle,
        backgroundColor: '$bg-disabled',
      },
    },
    'secondary-gray': {
      default: {
        color: '$button-secondary-fg',
        backgroundColor: '$button-secondary-bg',
        borderColor: '$button-secondary-border',
      },
      hover: {
        color: '$button-secondary-fg-hover',
        backgroundColor: '$button-secondary-bg-hover',
        borderColor: '$button-secondary-border-hover',
      },
      disabled: {
        ..._defaultDiableStyle,
      },
    },
    'secondary-color': {
      default: {
        color: '$button-secondary-color-fg',
        backgroundColor: '$button-secondary-color-bg',
        borderColor: '$button-secondary-color-border',
      },
      hover: {
        color: '$button-secondary-color-fg-hover',
        backgroundColor: '$button-secondary-color-bg-hover',
        borderColor: '$button-secondary-color-border-hover',
      },
      disabled: {
        ..._defaultDiableStyle,
      },
    },
    'tertiary-gray': {
      default: {
        color: '$button-tertiary-fg',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      hover: {
        color: '$button-tertiary-fg-hover',
        backgroundColor: '$button-tertiary-bg-hover',
        borderColor: '$button-tertiary-bg-hover',
      },
      disabled: {
        ..._defaultDiableStyle,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    'tertiary-color': {
      default: {
        color: '$button-tertiary-color-fg',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      hover: {
        color: '$button-tertiary-color-fg-hover',
        backgroundColor: '$button-tertiary-color-bg-hover',
        borderColor: '$button-tertiary-color-bg-hover',
      },
      disabled: {
        ..._defaultDiableStyle,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    'link-gray': {
      default: {
        color: '$button-tertiary-fg',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      hover: {
        color: '$button-tertiary-fg-hover',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      disabled: {
        ..._defaultDiableStyle,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    'link-color': {
      default: {
        color: '$button-tertiary-color-fg',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      hover: {
        color: '$button-tertiary-color-fg-hover',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      disabled: {
        ..._defaultDiableStyle,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
  };

  return styles[type];
};

export const getDestructiveColorsStylesBasedOnHierarchy = (type: HierarchyTypes = 'primary'): HeirarchyStylesProps => {
  const _defaultDiableStyle = {
    backgroundColor: '$bg-primary',
    color: '$fg-disabled',
    borderColor: '$border-disabled-subtle',
  };
  const _secondaryColors = {
    default: {
      color: '$button-secondary-error-fg',
      backgroundColor: '$button-secondary-error-bg',
      borderColor: '$button-secondary-error-border',
    },
    hover: {
      color: '$button-secondary-error-fg-hover',
      backgroundColor: '$button-secondary-error-bg-hover',
      borderColor: '$button-secondary-error-border-hover',
    },
    disabled: {
      ..._defaultDiableStyle,
    },
  };

  const _tertiaryColors = {
    default: {
      color: '$button-tertiary-error-fg',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    hover: {
      color: '$button-tertiary-error-fg-hover',
      backgroundColor: '$button-tertiary-error-bg-hover',
      borderColor: '$button-tertiary-error-bg-hover',
    },
    disabled: {
      ..._defaultDiableStyle,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  };

  const _linkColors = {
    default: {
      color: '$button-tertiary-error-fg',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    hover: {
      color: '$button-tertiary-error-fg-hover',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    disabled: {
      ..._defaultDiableStyle,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  };

  const styles: Record<HierarchyTypes, HeirarchyStylesProps> = {
    primary: {
      default: {
        backgroundColor: '$button-primary-error-bg',
        color: '$button-primary-error-fg',
        borderColor: '$button-primary-error-bg',
      },
      hover: {
        color: '$button-primary-error-fg-hover',
        backgroundColor: '$button-primary-error-bg-hover',
        borderColor: '$button-primary-error-bg-hover',
      },
      disabled: {
        ..._defaultDiableStyle,
        backgroundColor: '$bg-disabled',
      },
    },
    'secondary-gray': {
      ..._secondaryColors,
    },
    'secondary-color': {
      ..._secondaryColors,
    },
    'tertiary-gray': {
      ..._tertiaryColors,
    },
    'tertiary-color': {
      ..._tertiaryColors,
    },
    'link-gray': {
      ..._linkColors,
    },
    'link-color': {
      ..._linkColors,
    },
  };

  return styles[type];
};

export const getStyleBasedOnSize = (size: ButtonSizes): ButtonStylesProps => {
  const _sizesStyles: Record<ButtonSizes, ButtonStylesProps> = {
    '2xl': {
      fontSize: '$text-lg',
      paddingHorizontal: 22,
      paddingVertical: '$spacing-xl',
      borderRadius: '$rounded-lg',
      height: 60,
    },
    xl: {
      fontSize: '$text-md',
      paddingHorizontal: 18,
      paddingVertical: '$spacing-lg',
      borderRadius: '$rounded-md',
      height: 48,
    },
    lg: {
      fontSize: '$text-md',
      paddingHorizontal: '$spacing-xl',
      paddingVertical: 10,
      borderRadius: '$rounded-md',
      height: 44,
    },
    md: {
      fontSize: '$text-sm',
      paddingHorizontal: 14,
      paddingVertical: 10,
      borderRadius: '$rounded-md',
      height: 40,
    },
    sm: {
      fontSize: '$text-sm',
      paddingHorizontal: 14,
      paddingVertical: '$spacing-md',
      borderRadius: '$rounded-md',
      height: 36,
    },
  };

  return _sizesStyles[size];
};

export const getStyleWithNoChildrenBasedOnSize = (size: ButtonSizes): ButtonStylesProps => {
  const _sizesStyles: Record<ButtonSizes, ButtonStylesProps> = {
    '2xl': {
      paddingHorizontal: '$spacing-xl',
      paddingVertical: '$spacing-xl',
    },
    xl: {
      paddingHorizontal: 14,
      paddingVertical: 14,
    },
    lg: {
      paddingHorizontal: '$spacing-lg',
      paddingVertical: '$spacing-lg',
    },
    md: {
      paddingHorizontal: 10,
      paddingVertical: 10,
    },
    sm: {
      paddingHorizontal: '$spacing-md',
      paddingVertical: '$spacing-md',
    },
  };

  return _sizesStyles[size];
};
