import React, {forwardRef, useMemo} from 'react';

import {TextArea as BaseTextArea, TextAreaProps as BaseTextAreaProps} from 'tamagui';

import {useTokenColor} from '../../utils';
import {InputProps, Label, Subtext} from '../Input';
import {inputDefaultStyles, getInputDisabledStyles, inputErrorStyles} from '../Input/styles';
import {View} from '../View';
import {XStack} from '../XStack';

export type TextAreaProps = Omit<InputProps, 'size' | 'prefix' | 'multiline' | 'buttonProps' | 'addOnTextBefore'> &
  BaseTextAreaProps;

export const _TextArea = (
  {numberOfLines = 4, label, suffix, disabled, required, error = false, subtext, ...props}: TextAreaProps,
  ref,
) => {
  const placeholderColor = useTokenColor('$text-placeholder');

  const textAreaStyles = useMemo(() => {
    const _disabledStyles = disabled ? getInputDisabledStyles() : {};
    const _errorStyles = error ? inputErrorStyles : {};

    return {
      ...inputDefaultStyles,
      ..._disabledStyles,
      ..._errorStyles,
    };
  }, [disabled, suffix, error]);

  return (
    <>
      {(label || suffix) && (
        <XStack gap="$spacing-xs" alignItems="center" marginBottom="$spacing-sm">
          <Label label={label} required={required} />
          {suffix && <View marginBottom="$spacing-sm"> {suffix}</View>}
        </XStack>
      )}
      <BaseTextArea
        lineHeight="$true"
        numberOfLines={numberOfLines}
        {...textAreaStyles}
        alignItems="flex-start"
        justifyContent="flex-start"
        paddingVertical="$spacing-gl"
        paddingHorizontal={14}
        placeholderTextColor={placeholderColor as any}
        disabled={disabled}
        height={128}
        {...props}
        ref={ref}
      />
      <Subtext subtext={subtext} error={error} />
    </>
  );
};

export const TextArea = forwardRef(_TextArea);
