import React from 'react';

import {Trans, useTranslation} from 'react-i18next';

import {BuilderPropsGroup, PropsBuilder} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Select} from '../../../../Select';
import {XStack} from '../../../../XStack';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';
import {StateAwareInput} from '../../../components/StateAwareInput';

import {VideoElementProps} from './index';

export const _VideoPropsBuilder: PropsBuilder<VideoElementProps> = ({value: propsValue, onChange}) => {
  const {url, resizeMode} = propsValue;
  const {t} = useTranslation();

  const onUpdateProps = (key: string, value: string) => {
    onChange(key, value);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="settings.basic.url" />}>
        <XStack alignItems="center" justifyContent="space-between">
          <StateAwareInput
            placeholder="www.example.come"
            value={url || ''}
            onChangeText={(value) => onUpdateProps('url', value)}
            types={['string']}
          />
        </XStack>
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.resizeMode" />}>
        <Select
          value={resizeMode}
          options={[
            {label: t('settings.layout.contain'), value: 'contain'},
            {label: t('settings.layout.cover'), value: 'cover'},
            {label: t('settings.layout.stretch'), value: 'stretch'},
            {label: t('settings.layout.repeat'), value: 'repeat'},
            {label: t('settings.layout.center'), value: 'center'},
          ]}
          onChange={(value) => onUpdateProps('resizeMode', value)}
          placeholder={t('settings.layout.cover')}
          width="80%"
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const VideoPropsBuilder: BuilderPropsGroup<VideoElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.VideoElement'),
      Content: _VideoPropsBuilder,
    },
  ],
};
