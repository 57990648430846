import {CommonElements, ViewProps} from '../components';

import {AppletWidget} from './Applet';
import {AppContentPage} from './AppStructure';
import {IconType} from './IconType';
import {LocalizedTextType} from './LocalizedTextType';

export type CommonElementsNames = keyof typeof CommonElements;

export enum BlockCategory {
  GENERAL = 'GENERAL',
  LAYOUT = 'LAYOUT',
  NODE = 'NODE',
  META = 'META',
}

export interface BuilderBlockComponent {
  ({children, ...props}: any): React.JSX.Element | null;
}

export type BuilderWidgetElement = AppletWidget & {
  nodeId: string;
  props: ViewProps;
};

export const ScopeLevel = {
  app: 'app',
  page: 'page',
  api: 'api',
} as const;

export const StateTypes = {
  number: 'number',
  string: 'string',
  array: 'array',
  json: 'json',
  boolean: 'boolean',
} as const;

export type State = {
  id: string;
  name: string;
  value: string | unknown[] | number | boolean;
  type: keyof typeof StateTypes;
  isSecret: boolean;
  scopeId: string;
  level: keyof typeof ScopeLevel;
};

export type Loop = {
  name: string;
  stateId: string;
  stateScopeId: string;
};

export interface Block<T = Record<string, any>> {
  id: string;
  definitionId: string;
  definitionVersion: number;
  props: T;
  label?: string;
  children?: Block[];
}

export type BuilderPropsSection<Props> = {
  title: string;
  Content: PropsBuilder<Props>;
};

export interface BuilderPropsGroup<Props> {
  title: string;
  sections: BuilderPropsSection<Props>[];
}

export interface BlockDefinition<Props = {[key: string]: any}, T = Block> {
  Component: React.ForwardRefExoticComponent<Omit<Props, 'ref'> & React.RefAttributes<unknown>> | React.FC<Props>;
  category: BlockCategory;
  version: number;
  name: LocalizedTextType;
  id: string;
  props: Props;
  children?: Block[];
  appletId?: string;
  Icon?: IconType;
  propsBuilder?: BuilderPropsGroup<Props>[];
  initializer?: PropsInitializer<Props>;
  migrate?: (node: T) => T;
}

export interface DesignSpec<Props = {[key: string]: any}> {
  version: number;
  Component?: React.FC<Props>;
  blockDefinition: Record<string, BlockDefinition<any, any>>;
  migrate: (page: AppContentPage) => AppContentPage;
}

export type Options = {
  loop: Loop | null;
};

export type PropsBuilder<T> = React.FC<{
  value: T;
  onChange: (key: string, value: T[keyof T]) => void;
  onReset: (keys: string[]) => void;
  options?: Options;
}>;

export type PropsInitializer<T> = React.FC<{onSubmit: (values: T) => void}>;

// Type Guards
export function isBuilderWidgetElement(element: BlockDefinition): element is BlockDefinition & AppletWidget {
  return element && 'appletId' in element;
}

export function isBuilderElement(element: BlockDefinition): element is BlockDefinition {
  return element && !('appletId' in element);
}

// CHECK IF THE BLOCK ELEMNT IS A CONTAINER BASED ON IT'S CHILDREN PROP
export function isLeafElement(node: Block | AppContentPage): node is (Block | AppContentPage) & {children?: undefined} {
  return node && !node.children;
}

export function isSiblingElement(node: Block | AppContentPage): node is (Block | AppContentPage) & {children: Block[]} {
  return !!node.children;
}
