import {ReactNode} from 'react';

import {XStackProps} from 'tamagui';

import {InputProps} from '../../Input';
import {PaginationProps} from '../../Pagination';
import {ViewProps} from '../../View';

export type CellContent = ReactNode;

export enum SortDirection {
  'ASC' = 1,
  'DESC' = -1,
}

export type SortedBy<T> = Map<keyof T, SortDirection>;

export type Column<T> = {
  field: keyof T;
  label: CellContent;
  width?: number;
};

export type Row = {
  [key: string]: CellContent;
};

export type OnSelectHandler<T> = (selectToggled: Set<T>, selectAll?: boolean) => void;
export type OnSelect<T> = OnSelectHandler<T> | {key: keyof T; handler: OnSelectHandler<any>};

export type TableProps<T> = ViewProps & {
  title?: CellContent;
  itemPrimaryKey?: keyof T;
  stickyHeader?: boolean;
  data?: T[];
  filterBy?: (keyof T)[];
  sortBy?: (keyof T)[];
  filterInputProps?: InputProps;
  formatters?: Partial<{[key in keyof T]: (args: {value: T[key]; item: T}) => CellContent}>;
  footer?: ReactNode;
  pageSize?: number;
  totalItems?: number;
  columns: Column<T>[];
  pageSizeOptions?: number[];
  onFilter?: (value: string) => void;
  onSort?: (sortBy: SortedBy<T>) => void;
  onSelect?: OnSelect<T>;
  actions?: (item: T) => ReactNode;
  onPagination?: (page: number) => void;
  extendedView?: (item: T) => ReactNode;
  onPaginationSizeChange?: (page: number) => void;
  isLoading?: boolean;
  hasNoData?: boolean;
  minContentHeight?: number;
  showIndices?: boolean;
  hidePagination?: boolean;
  subRow?: (item: T) => ReactNode;
  rowProps?: (item: T) => XStackProps | undefined;
  cellProps?: (item: T, field: keyof T) => XStackProps | undefined;
  defaultValue?: ReactNode;
  paginationProps?: PaginationProps;
};

export type CellProps = ViewProps;
