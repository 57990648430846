import React, {useState} from 'react';

import {useMedia} from 'tamagui';

import {dayjs} from '../../utils/extendedDayjs';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {DatepickerContent} from './components/DatepickerContent';
import {DatePickerMenu} from './components/DatePickerMenu';
import {DatePickerValueInput} from './components/DatePickerValueInput';
import {Footer} from './components/Footer';
import {DATE_PICKER_CONTENT_WIDTH, DATE_PICKER_SIDEBAR_WIDTH} from './constants';
import {DatepickerProvider} from './context/DatepickerProvider';
import {FocusedInput, START_DATE, useDatepicker, OnDatesChangeProps} from './hooks';
import {DatepickerProps} from './models';

export const Datepicker = ({
  value,
  mode = 'single',
  type = 'gregory',
  onChange,
  onCancel,
  format = 'YYYY-MM-DD',
  minimumDate,
  maximumDate,
  excludeDates,
  allowedDates,
  isDisabled,
  containerProps,
  filterDate,
  inline,
}: DatepickerProps) => {
  const media = useMedia();

  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: Array.isArray(value) && value.length > 1 ? new Date(value[0]) : new Date(),
    endDate: Array.isArray(value) && value.length > 1 ? new Date(value[1]) : new Date(),
    focusedInput: 'startDate',
  });

  const isRangePicker = mode === 'dual';
  const monthsCount: number = isRangePicker ? (media.xs ? 1 : 2) : 1;

  const filterBlockedDates = (date: Date) => {
    const normalizedDate = date.setHours(0, 0, 0, 0);
    let isDateBlocked = false;

    if (allowedDates) {
      const normalizedAllowedDates = allowedDates.map((date) => date.setHours(0, 0, 0, 0));
      isDateBlocked = !normalizedAllowedDates?.includes(normalizedDate);
    }

    if (isDisabled) {
      return isDateBlocked;
    }

    if (excludeDates) {
      const normalizedExcludeDates = excludeDates.map((date) => date.setHours(0, 0, 0, 0));
      isDateBlocked = normalizedExcludeDates?.includes(normalizedDate);
    }
    return isDateBlocked;
  };

  const formattDateResult = (data: OnDatesChangeProps): string & string[] => {
    const range: string[] = [];
    let date;
    if (mode === 'dual') {
      range[0] = dayjs(data.startDate).calendar(type).format(format);
      range[1] = dayjs(data.endDate).calendar(type).format(format);
      date = range;
    } else {
      date = dayjs(data.startDate).calendar(type).format(format);
    }

    return date;
  };

  const {activeMonths, firstDayOfWeek, ...context} = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    maxBookingDate: maximumDate,
    minBookingDate: minimumDate,
    isDateBlocked: typeof filterDate === 'function' ? filterDate : filterBlockedDates,
    focusedInput: state.focusedInput as FocusedInput,
    onDatesChange: (data) => {
      if (!data.focusedInput) {
        setState({...data, focusedInput: START_DATE});
      } else {
        setState({...data});
      }
    },
    numberOfMonths: monthsCount,
    // todo : change this implementation
    ...(mode !== 'dual' && {
      minSelectedDays: 1,
      exactMinSelectedDays: true,
    }),
  });

  return (
    <DatepickerProvider {...context}>
      <XStack
        backgroundColor="$bg-primary"
        borderRadius="$rounded-xl"
        borderWidth={inline || media.xs ? 0 : 1}
        flex={0}
        borderColor="$border-secondary"
        width="unset"
        maxWidth={isRangePicker ? DATE_PICKER_CONTENT_WIDTH * 2 + DATE_PICKER_SIDEBAR_WIDTH : DATE_PICKER_CONTENT_WIDTH}
        $xs={{alignSelf: 'center'}}
        {...containerProps}>
        {isRangePicker && media.gtMd && <DatePickerMenu />}
        <YStack>
          <YStack maxWidth={DATE_PICKER_CONTENT_WIDTH * 2}>
            <XStack>
              {activeMonths.map((month, index) => (
                <DatepickerContent
                  key={`${month.year}-${month.month}`}
                  year={month.year}
                  month={month.month}
                  monthsCount={monthsCount}
                  firstDayOfWeek={firstDayOfWeek}
                  date={month.date}
                  type={type}
                  isFirstMonth={monthsCount === 0 || index === 0}
                  isLastMonth={monthsCount === 0 || index === monthsCount - 1}
                  isRangePicker={isRangePicker}
                  state={state}
                />
              ))}
            </XStack>
            <XStack alignItems="center" justifyContent="center" padding="$spacing-xl" height={72}>
              {isRangePicker && (
                <DatePickerValueInput
                  type={type}
                  state={{
                    startDate: state.startDate,
                    endDate: state.endDate,
                  }}
                  isRangePicker={isRangePicker}
                  showContent={isRangePicker && media.gtMd}
                />
              )}
              <Footer
                monthsCount={monthsCount}
                onAccept={() => {
                  if (typeof onChange === 'function') {
                    const result = formattDateResult(state);
                    onChange(result);
                  }
                }}
                onCancel={() => {
                  if (typeof onCancel === 'function') {
                    onCancel();
                  }
                }}
              />
            </XStack>
          </YStack>
        </YStack>
      </XStack>
    </DatepickerProvider>
  );
};
