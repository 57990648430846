import React from 'react';

import {Typography, TypographyProps} from '../Typography';

import {InputProps} from './types';

type SubtextProps = {
  subtext?: InputProps['subtext'];
  error?: boolean;
};

export const Subtext: React.FC<SubtextProps> = ({error = false, subtext}) => {
  const getSubTextStyles = (error: boolean): TypographyProps => {
    return {
      color: !error ? '$text-tertiary' : '$text-error-primary',
      fontWeight: '400',
      fontSize: '$text-sm',
      marginTop: '$spacing-sm',
      fontFamily: '$body',
      display: 'flex',
    };
  };

  if (!subtext) {
    return null;
  }

  return <Typography {...getSubTextStyles(error)}>{subtext}</Typography>;
};
