import {createVariable} from 'tamagui';
import tinyColor from 'tinycolor2';

import {Theme} from '../../types';

import {TamaConfigType} from '.';

export const tintTamaguiTheme = (baseConfig: TamaConfigType, brand: string) => {
  const brandLight = tinyColor(brand).setAlpha(0.2);

  /* variables */

  /* light */
  baseConfig.themes[Theme.light].backgroundHover = createVariable({
    key: 'backgroundHover',
    name: 'backgroundHover',
    val: brandLight,
  });

  /* dark */
  baseConfig.themes[Theme.dark].backgroundHover = createVariable({
    key: 'backgroundHover',
    name: 'backgroundHover',
    val: brandLight,
  });

  baseConfig.themes[Theme.dark].highlightColor = createVariable({
    key: 'highlightColor',
    name: 'highlightColor',
    val: brand,
  });

  return baseConfig;
};
