import {useState, useCallback} from 'react';

export const useSwitchState = (checked: boolean) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleSwitch = useCallback((checked: boolean) => {
    setIsChecked(checked);
  }, []);

  return {isChecked, handleSwitch};
};
