import {Stack, styled} from 'tamagui';

export interface SliderProps {
  onChange?: (values: [start: number, end: number]) => void;
  labelPosition?: 'false' | 'top' | 'bottom';
}

export type HandleType = 'start' | 'end';

export const CONSTANTS = {
  HANDLE_SIZE: 24,
  TRACK_HEIGHT: 8,
  MIN_VALUE: 0,
  MAX_VALUE: 100,
  HANDLE_BUFFER: 5,
} as const;

export const SliderTrack = styled(Stack, {
  height: CONSTANTS.TRACK_HEIGHT,
  backgroundColor: '$bg-quaternary',
  borderRadius: '$10',
  position: 'absolute',
  top: CONSTANTS.TRACK_HEIGHT,
  left: 0,
  right: -CONSTANTS.TRACK_HEIGHT,
  overflow: 'hidden',
});

export const SliderHandle = styled(Stack, {
  width: CONSTANTS.HANDLE_SIZE,
  height: CONSTANTS.HANDLE_SIZE,
  backgroundColor: '$bg-primary',
  borderRadius: '$12',
  borderWidth: 2,
  borderColor: '$fg-brand-primary',
  position: 'absolute',
  top: 0,
  marginLeft: -CONSTANTS.HANDLE_SIZE / 2,
  pressStyle: {scale: 0.95},
  animation: 'quick',
  hoverStyle: {scale: 1.1},
});

export const FloatingLabelTop = styled(Stack, {
  position: 'absolute',
  backgroundColor: '$bg-primary_alt',
  borderWidth: 1,
  borderColor: '$border-secondary',
  justifyContent: 'center',
  alignItems: 'center',
  shadowColor: '0px 4px 6px -1px rgba(10, 13, 18, 0.10), 0px 2px 4px -2px rgba(10, 13, 18, 0.06)',
  shadowOffset: {width: 0, height: 2},
  shadowOpacity: 0.1,
  shadowRadius: 6,

  variants: {
    size: {
      default: {
        paddingHorizontal: '$3',
        paddingVertical: '$2',
      },
      fixed: {
        width: 40,
        paddingVertical: '$3',
        paddingHorizontal: 0,
      },
    },
    alignment: {
      left: {
        marginLeft: -20,
      },
      right: {
        right: -8,
      },
      center: {
        transform: [{translateX: -10}],
      },
    },
    borderStyle: {
      rounded: {
        borderRadius: 8,
      },
      squared: {
        borderRadius: 2,
      },
    },
  },

  defaultVariants: {
    size: 'default',
    alignment: 'left',
    borderStyle: 'rounded',
  },
});

export const FloatingLabelBottom = styled(Stack, {
  position: 'absolute',
  top: '100%',
  marginTop: '$3',
  width: 40,
  marginLeft: '$-2',
  alignItems: 'center',
});
