import React, {useMemo, useState} from 'react';

import {GLOBAL_SCOPE_KEY, STATE_SYNTAX} from '../../../../config';
import {Options, ScopeLevel, State} from '../../../../types';
import {Badge} from '../../../Badge';
import {IconView} from '../../../IconView';
import {Input} from '../../../Input';
import {ListItem} from '../../../ListItem';
import {Popover} from '../../../Popover';
import {CoreTrans} from '../../../ScopedTrans';
import {useStateContext} from '../../../StateProvider';
import {TruncatedTooltipText} from '../../../TruncatedTooltipText';
import {Typography} from '../../../Typography';
import {XStack} from '../../../XStack';
import {YStack} from '../../../YStack';
import {generatePathsFromData} from '../../utils';

import {useFilterScopes} from './hooks/useFilterScopes';
import {ScopesFilter} from './ScopesFilter';

type StatePopoverProps = {
  value: string;
  caretPosition: number;
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeText?: (value: string) => void;
  onStateSelected?: (value: {scopeId: string; stateId: string}) => void;
  types?: State['type'][];
  options?: Options;
};

export const StatePopover = ({
  types,
  showPopover,
  setShowPopover,
  value,
  onStateSelected,
  caretPosition,
  onChangeText,
  options,
}: StatePopoverProps) => {
  const [searchText, setSearchText] = useState('');

  const context = useStateContext();

  const state = context?.state || {};
  const allVariables = context?.allVariables || [];

  const scopes = Object.keys(state);
  const scopesSelector = useFilterScopes(scopes);

  const handleStateSelect = (state: string) => {
    const newText =
      value.substring(0, caretPosition - 2) +
      `${STATE_SYNTAX.PREFIX}${state}${STATE_SYNTAX.SUFFIX}` +
      value.substring(caretPosition);
    onChangeText?.(newText);
    setShowPopover(false);
  };

  const availableVars = useMemo(() => {
    if (!state || !allVariables.length) return [];
    return types
      ? allVariables.filter((variable) => types.includes(variable.type.toLocaleLowerCase() as State['type']))
      : allVariables;
  }, [state, allVariables, types]);

  const availableListKeys = useMemo(() => {
    if (!options?.loop) return [];

    const stateVars = context?.state[options.loop.stateScopeId];
    const targetState = stateVars?.find((state) => options.loop && state.id === options.loop.stateId);

    if (!targetState) return [];
    const predifinedPaths = generatePathsFromData(targetState?.value || []);
    const pathsWithListsName = predifinedPaths?.map((p) => ({...p, name: options.loop?.name || 'List', level: 'List'}));

    return types ? pathsWithListsName?.filter((p) => types.includes(p.type)) || [] : pathsWithListsName || [];
  }, [options, state]);

  const selectedScope = scopesSelector.filtererdScopes;

  const filteredVars = useMemo(() => {
    const trimmedSearchText = searchText.trim();
    return availableVars.filter(
      (v) =>
        (trimmedSearchText === '' || v.name?.toLowerCase().includes(trimmedSearchText.toLowerCase())) &&
        selectedScope.includes(v.scopeId as string),
    );
  }, [availableVars, searchText, selectedScope]);

  return (
    <Popover
      triggerProps={{
        position: 'absolute',
        top: 40,
        left: caretPosition + 20,
        zIndex: 200,
      }}
      contentProps={{padding: '$2'}}
      closeOnClick
      closable
      onOpenChange={setShowPopover}
      open={showPopover}
      trigger={<Typography display="none">{value}</Typography>}>
      <XStack alignItems="center" gap="$2" marginVertical="$4">
        <Input value={searchText} onChangeText={setSearchText} flex={1} />
        <ScopesFilter scopes={scopes} scopesSelector={scopesSelector} />
      </XStack>
      {!filteredVars.length && !availableListKeys.length ? (
        <ListItem prefix={<IconView icon="DatabaseIcon" />} title={<CoreTrans i18nKey="builder.state.noStateMsg" />} />
      ) : (
        <YStack maxHeight="$15" overflow="scroll">
          {filteredVars.map((v) => (
            <ListItem
              cursor="pointer"
              height="$3"
              prefix={<IconView icon="ThirdBracketCircleIcon" />}
              suffix={
                <XStack gap="$1">
                  <Badge label={v.type.toLocaleLowerCase()} color="brand" minWidth={60} />
                  <Badge
                    label={<TruncatedTooltipText fontSize="$12" color="$warning-600" text={v.name} maxLength={5} />}
                    color="warning"
                    minWidth={60}
                    maxWidth={60}
                  />
                  <Badge label={v.level || ScopeLevel.page} color="success" minWidth={50} maxWidth={50} />
                </XStack>
              }
              key={v.id}
              title={<TruncatedTooltipText marginRight="$2" text={v.name} maxLength={14} />}
              onPress={() => {
                onStateSelected?.({scopeId: v.scopeId, stateId: v.id});
                handleStateSelect(GLOBAL_SCOPE_KEY === v.scopeId ? v.name : `${v.scopeId}.${v.name}`);
              }}
            />
          ))}

          {availableListKeys.map((item) => (
            <ListItem
              cursor="pointer"
              height="$3"
              prefix={<IconView icon="LeftToRightListBulletIcon" />}
              suffix={
                <XStack gap="$1">
                  <Badge label={item.type} color="brand" minWidth={60} />
                  <Badge
                    label={<TruncatedTooltipText fontSize="$12" color="$warning-600" text={item.name} maxLength={5} />}
                    color="warning"
                    minWidth={60}
                    maxWidth={60}
                  />
                  <Badge label={item.level} color="purple" minWidth={50} maxWidth={50} />
                </XStack>
              }
              key={item?.jsonPath}
              title={<TruncatedTooltipText marginRight="$2" text={item.jsonPath || ''} maxLength={14} />}
              onPress={() => {
                handleStateSelect(item.jsonPath || '');
              }}
            />
          ))}
        </YStack>
      )}
    </Popover>
  );
};
