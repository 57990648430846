import React from 'react';

import {XStack} from 'tamagui';

import {IconView, View} from '../..';
import {isCurrentStep, isPreviousStep} from '../utils';

type IconStepProps = {
  isHorizontal: boolean;
  size: number;
  isMinimal: boolean;
  finishedLine: boolean;
  lineWidth: number;
  currentStep: number;
  index: number;
};

export const IconStep = ({
  isHorizontal,
  size,
  isMinimal,
  lineWidth,
  finishedLine,
  currentStep,
  index,
}: IconStepProps) => {
  return (
    <>
      <XStack alignItems="center" flexDirection={isHorizontal ? 'row' : 'column'}>
        {isPreviousStep(currentStep, index) && (
          <IconView icon="CheckmarkCircle02Icon" size={size} color="$fg-brand-primary" variant="solid" />
        )}
        {isCurrentStep(currentStep, index) && (
          <View
            width={size}
            height={size}
            borderRadius={size / 2}
            padding="$0.5"
            backgroundColor="$alpha-white-100"
            borderWidth={1}
            borderColor="$fg-brand-primary"
            alignItems="center"
            justifyContent="center">
            <View
              width="100%"
              height="100%"
              borderRadius={size / 2}
              backgroundColor="$fg-brand-primary"
              alignItems="center"
              justifyContent="center">
              <View width={size / 4} height={size / 4} borderRadius={size / 8} backgroundColor="$alpha-white-100" />
            </View>
          </View>
        )}
        {!isCurrentStep(currentStep, index) && !isPreviousStep(currentStep, index) && (
          <View
            width={size}
            height={size}
            borderRadius={size / 2}
            backgroundColor="$gray-100"
            borderWidth={1}
            borderColor="$gray-300"
            alignItems="center"
            justifyContent="center">
            <View width={size / 4} height={size / 4} borderRadius={size / 8} backgroundColor="$gray-300" />
          </View>
        )}
      </XStack>
      {!isMinimal && (
        <View
          height={isHorizontal ? 2 : lineWidth}
          backgroundColor={finishedLine ? '$fg-brand-primary' : '$bg-quaternary'}
          width={isHorizontal ? lineWidth : 2}
        />
      )}
    </>
  );
};
