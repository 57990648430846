import {Sizes} from '../model';

const sizes: Sizes = {
  small: {
    stepSize: 24,
    fontSize: '$text-sm',
    gap: 12,
  },
  medium: {
    stepSize: 32,
    fontSize: '$text-md',
    gap: 16,
  },
  large: {
    stepSize: 40,
    fontSize: '$text-md',
    gap: 20,
  },
};

export default sizes;
