import React, {useState, useCallback, useRef} from 'react';
import {Stack, styled} from 'tamagui';
import {YStack} from '../YStack';
import {Text} from '../Text';
import {
  CONSTANTS,
  SliderProps,
  SliderTrack,
  SliderHandle as SliderHandleDesktop,
  FloatingLabelBottom,
  HandleType,
  FloatingLabelTop as FloatingLabelTopDesktop,
} from './shared';

interface HandleConfig {
  value: number;
  type: HandleType;
}

const SliderHandle = styled(SliderHandleDesktop, {
  hoverStyle: {scale: 1.1, cursor: 'pointer'},
});

const FloatingLabelTop = styled(FloatingLabelTopDesktop, {
  top: -45,
  variants: {
    size: {default: true},
    alignment: {right: true},
    borderStyle: {rounded: true},
  },
});

export const Sliders: React.FC<SliderProps> = ({onChange, labelPosition = 'bottom'}) => {
  const [startValue, setStartValue] = useState(Math.round(CONSTANTS.MIN_VALUE));
  const [endValue, setEndValue] = useState(Math.round(CONSTANTS.MAX_VALUE));
  const containerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef<HandleType | null>(null);

  const calculateValue = useCallback((clientX: number) => {
    if (!containerRef.current) return null;

    const rect = containerRef.current.getBoundingClientRect();
    const containerWidth = rect.width - CONSTANTS.HANDLE_SIZE;
    const relativeX = Math.max(0, Math.min(clientX - rect.left, containerWidth));

    return Math.round(Math.max(0, Math.min(100, (relativeX / containerWidth) * 100)));
  }, []);

  const updateValue = useCallback(
    (newValue: number | null, handle: HandleType) => {
      if (newValue === null) return;

      const isValidMove = (handle === 'start' && newValue < endValue) || (handle === 'end' && newValue > startValue);

      if (isValidMove) {
        const updatedValues = {
          start: handle === 'start' ? newValue : startValue,
          end: handle === 'end' ? newValue : endValue,
        };

        if (handle === 'start') setStartValue(newValue);
        else setEndValue(newValue);

        onChange?.([updatedValues.start, updatedValues.end]);
      }
    },
    [startValue, endValue, onChange],
  );

  const handleEvent = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!isDraggingRef.current) return;

      const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
      const newValue = calculateValue(clientX);
      updateValue(newValue, isDraggingRef.current);
    },
    [calculateValue, updateValue],
  );

  const stopDragging = useCallback(() => {
    isDraggingRef.current = null;
    document.removeEventListener('mousemove', handleEvent);
    document.removeEventListener('mouseup', stopDragging);
  }, [handleEvent]);

  const startDragging = useCallback(
    (handle: HandleType) => {
      isDraggingRef.current = handle;
      document.addEventListener('mousemove', handleEvent);
      document.addEventListener('mouseup', stopDragging);
    },
    [handleEvent, stopDragging],
  );

  const renderLabel = (value: number) => {
    if (labelPosition === 'false') return null;

    const Label = labelPosition === 'top' ? FloatingLabelTop : FloatingLabelBottom;
    const textColor = labelPosition === 'top' ? '$text-secondary' : '$text-primary';
    const lineHeight = labelPosition === 'top' ? 18 : undefined;

    return (
      <Label>
        <Text fontSize="$12" color={textColor} lineHeight={lineHeight}>
          {value}%
        </Text>
      </Label>
    );
  };

  const handles: HandleConfig[] = [
    {value: startValue, type: 'start'},
    {value: endValue, type: 'end'},
  ];

  return (
    <YStack width="100%" maxWidth={320} height={64} position="relative">
      <Stack ref={containerRef} width="100%" height={24} position="relative" zIndex={1}>
        <SliderTrack>
          <Stack
            height={CONSTANTS.TRACK_HEIGHT}
            backgroundColor="$fg-brand-primary"
            borderRadius="$10"
            position="absolute"
            top={0}
            left={`${startValue}%`}
            right={`${100 - endValue}%`}
          />
        </SliderTrack>

        <Stack position="relative">
          {handles.map(({value, type}) => (
            <SliderHandle
              key={type}
              left={`${value}%`}
              onMouseDown={(e) => {
                e.stopPropagation();
                startDragging(type);
              }}>
              {renderLabel(value)}
            </SliderHandle>
          ))}
        </Stack>
      </Stack>
    </YStack>
  );
};
