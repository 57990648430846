import React, {ComponentProps} from 'react';

import {StyleSheet} from 'react-native';

import {useAppDirection} from '../../utils/useAppDirection';
import {IconView} from '../IconView';

type Props = ComponentProps<typeof IconView>;

export const BidirectionalIcon: React.FC<Props> = (props) => {
  const {direction} = useAppDirection();

  return <IconView style={styles[direction]} {...props} />;
};

const styles = StyleSheet.create({
  ltr: {
    transform: [{rotate: '0deg'}],
  },
  rtl: {
    transform: [{rotate: '90deg'}],
  },
});
