import React, {useMemo} from 'react';

import {YStack as BaseYStack, YStackProps as BaseYStackProps} from 'tamagui';

import {SHADOW_BASE} from '../../lib/tamagui/constant';
import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export interface YStackProps extends BaseYStackProps {
  shadow?: keyof typeof SHADOW_BASE;
}

export const YStack = ({shadow, ...props}: YStackProps) => {
  const _props = useDirectionAwareProps(props);
  const shadowStyle = useMemo(() => (shadow ? SHADOW_BASE[shadow] : {}), [shadow]);

  return <BaseYStack {..._props} {...shadowStyle} />;
};
