import {IconListType} from '../../../lib';
import {TextProps} from '../../Text';

export type Step = {
  icon?: IconListType;
  label?: string;
  subtitle?: string;
};
export type Sizes = {
  [key in keyof typeof StepSize]: {
    stepSize: number;
    fontSize: TextProps['fontSize'];
    gap: number;
  };
};

export const StepSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export const StepsOrientation = {
  horizontal: 'horizontal',
  vertical: 'vertical',
} as const;

export const StepsType = {
  Icons: 'Icons',
  FeaturedIcons: 'FeaturedIcons',
  Lines: 'Lines',
};
export type StepsProps = {
  steps: Step[];
  currentStep: number;
  type?: typeof StepsType[keyof typeof StepsType];
  showLabels?: boolean;
  orientation?: typeof StepsOrientation[keyof typeof StepsOrientation];
  stepSize?: typeof StepSize[keyof typeof StepSize];
  showCurrentStepLabel?: boolean;
  isMinimal?: boolean;
  size?: number;
};
