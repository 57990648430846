import React from 'react';

import {dayjs} from '../../../utils/extendedDayjs';
import {Pressable} from '../../Pressable';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';
import {YStack} from '../../YStack';
import {useDatepickerContext} from '../context/DatepickerProvider';
import {useAppDirection} from '../../../utils';

export function DatePickerMenu() {
  const {goToPreviousYear, goToPreviousMonths, onDateSelect} = useDatepickerContext();
  const {isLTR} = useAppDirection();

  const labelActions = {
    today: () => {
      const today = dayjs().toDate();
      onDateSelect(today);
    },
    yesterday: () => {
      const yesterday = dayjs().subtract(1, 'day').toDate();
      onDateSelect(yesterday);
    },
    lastWeek: () => {
      const lastWeekStart = dayjs().subtract(1, 'week').startOf('week').toDate();
      onDateSelect(lastWeekStart);
    },
    thisWeek: () => {
      const thisWeekStart = dayjs().startOf('week').toDate();
      onDateSelect(thisWeekStart);
    },
    lastMonth: goToPreviousMonths,
    thisMonth: () => {
      const thisMonthStart = dayjs().startOf('month').toDate();
      onDateSelect(thisMonthStart);
    },
    lastYear: goToPreviousYear,
    thisYear: () => {
      const thisYearStart = dayjs().startOf('year').toDate();
      onDateSelect(thisYearStart);
    },
    allTime: () => {
      const today = dayjs().toDate();
      onDateSelect(today);
    },
  };

  const getBorderProps = (isLTR: boolean) => ({
    ...(isLTR
      ? {borderRightWidth: 1, borderRightColor: '$border-secondary'}
      : {borderLeftWidth: 1, borderLeftColor: '$border-secondary'}),
  });

  return (
    <YStack
      width={192}
      paddingVertical="$spacing-lg"
      paddingRight="$spacing-xl"
      paddingLeft="$spacing-xl"
      borderBottomWidth={1}
      borderBottomColor="$border-secondary"
      {...getBorderProps(isLTR)}>
      {Object.keys(labelActions).map((l) => {
        return (
          <Pressable
            height={40}
            paddingVertical="$spacing-md"
            paddingHorizontal="$spacing-xl"
            key={l}
            onPress={labelActions[l]}>
            <Typography fontSize="$text-sm" fontWeight="500">
              <CoreTrans i18nKey={`calendar.dateRanges.${l}`} /> {}
            </Typography>
          </Pressable>
        );
      })}
    </YStack>
  );
}
