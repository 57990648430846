import tinyColor from 'tinycolor2';

export function generateShades(baseColor: string, numOfShades = 12) {
  const shades: string[] = [];
  const hsl = tinyColor(baseColor).toHsl();

  for (let i = numOfShades - 1; i >= 0; i--) {
    hsl.l = (i + 0.5) / numOfShades;
    shades.push(tinyColor(hsl).toHexString());
  }

  return shades;
}
