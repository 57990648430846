import {TypographyProps} from '../../Typography';
import {InputSize} from '../types';

import {getInputStylesBaedONSize} from './inputStyles';

const getBaseAddOnStyles = ({size}: {size: InputSize}) => {
  return {
    ...getInputStylesBaedONSize(size),
    fontWeight: '400',
    fontSize: '$text-sm',
    color: '$fg-tertiary',
    borderWidth: 1,
    borderColor: '$border-primary',
    borderStyle: 'solid',
  } as TypographyProps;
};
export const getAddonBeforeStyles = ({
  size,
  isRTL,
  isReactElement,
}: {
  size: InputSize;
  isRTL?: boolean;
  isReactElement?: boolean;
}): TypographyProps => {
  const _restBorderEndStyle = isRTL ? {borderLeftWidth: 0} : {borderRightWidth: 0};

  return {
    ...(!isReactElement && getBaseAddOnStyles({size})),
    borderRadius: '$rounded-none',
    borderStartStartRadius: '$rounded-md',
    borderEndStartRadius: '$rounded-md',
    ..._restBorderEndStyle,
  } as TypographyProps;
};

export const getAddonAfterStyles = ({
  size,
  isRTL,
  isReactElement = false,
}: {
  size: InputSize;
  isRTL?: boolean;
  isReactElement?: boolean;
}): TypographyProps => {
  const _restBorderEndStyle = !isRTL ? {borderLeftWidth: 0} : {borderRightWidth: 0};

  return {
    ...(!isReactElement && getBaseAddOnStyles({size})),
    borderRadius: '$rounded-none',
    borderStartEndRadius: '$rounded-md',
    borderEndEndRadius: '$rounded-md',
    ..._restBorderEndStyle,
  } as TypographyProps;
};
