import { IconSvgObject } from '@hugeicons/core-shared';

const Tornado02Icon: IconSvgObject = [
  ["path", { d: "M6.8246 5.15741C6.35159 5.58074 6.11185 5.98349 6.04303 6.34533C5.93715 6.90204 6.03681 7.28095 6.21891 7.56562C6.41518 7.87245 6.77176 8.16798 7.33453 8.4148C8.47891 8.9167 10.1977 9.09366 12.018 8.9539C13.8267 8.81503 15.5776 8.37569 16.7491 7.7829C17.3415 7.48316 17.7025 7.18577 17.8778 6.94403C18.0221 6.74501 18.0166 6.63377 17.9712 6.51436C17.8859 6.29028 17.6291 6.02051 16.951 5.82997C16.2861 5.64312 15.3941 5.59018 14.404 5.66163C13.425 5.73227 12.4109 5.91998 11.5305 6.17033C10.6299 6.42643 9.95064 6.72518 9.59033 6.9777C9.13806 7.29467 8.51447 7.18498 8.1975 6.73271C7.88053 6.28044 7.99022 5.65684 8.44249 5.33988C9.07112 4.89931 9.99886 4.5266 10.9835 4.2466C11.9883 3.96085 13.1375 3.74782 14.26 3.66681C15.3715 3.58661 16.5191 3.63112 17.4921 3.90455C18.452 4.17429 19.4313 4.72762 19.8405 5.80322C20.1651 6.65653 19.9675 7.46926 19.4969 8.11815C19.0573 8.72431 18.3807 9.19874 17.6521 9.56746C16.1815 10.3116 14.1566 10.7956 12.1711 10.948C10.1972 11.0996 8.10317 10.9358 6.53124 10.2464C5.73584 9.89754 5.00839 9.38477 4.53412 8.64334C4.04567 7.87975 3.88829 6.97044 4.07825 5.97166C4.25659 5.03396 4.82035 4.26714 5.49083 3.66709C6.16401 3.06462 7.00433 2.57517 7.8684 2.19089C9.58406 1.42787 11.5765 1 12.9724 1C13.5247 1 13.9724 1.44772 13.9724 2C13.9724 2.55228 13.5247 3 12.9724 3C11.896 3 10.1797 3.35185 8.68112 4.01831C7.93808 4.34877 7.29491 4.7365 6.8246 5.15741Z", fill: "currentColor", key: "0" }],
  ["path", { d: "M18.8083 10.6046C19.1335 11.051 19.0352 11.6765 18.5888 12.0017C17.0815 13.0997 14.5082 13.7815 12.0711 13.9551C10.8299 14.0435 9.56664 14.0049 8.42337 13.7999C7.29373 13.5974 6.2026 13.2183 5.37885 12.5654C4.94605 12.2223 4.87331 11.5933 5.21638 11.1605C5.55946 10.7277 6.18843 10.655 6.62123 10.998C7.08603 11.3665 7.81704 11.6593 8.77634 11.8313C9.72201 12.0008 10.8145 12.0395 11.929 11.9601C14.2034 11.7982 16.3415 11.1644 17.4112 10.3851C17.8576 10.06 18.4831 10.1582 18.8083 10.6046Z", fill: "currentColor", key: "1" }],
  ["path", { d: "M14.21 22.6767C14.7525 22.5727 15.1079 22.0488 15.004 21.5063C14.9001 20.9639 14.3761 20.6085 13.8337 20.7124C12.912 20.889 11.9356 20.9901 10.9895 21C10.4373 21.0058 9.99427 21.4581 10.0001 22.0104C10.0058 22.5626 10.4582 23.0056 11.0105 22.9999C12.0798 22.9887 13.1752 22.8749 14.21 22.6767Z", fill: "currentColor", key: "2" }],
  ["path", { d: "M6.07921 14.7758C6.2943 14.2672 6.88103 14.0292 7.38971 14.2443C8.2611 14.6127 9.46095 14.8025 10.8006 14.8059C12.1284 14.8092 13.5241 14.6291 14.75 14.3123C15.2847 14.1741 15.8302 14.4955 15.9684 15.0302C16.1066 15.5649 15.7852 16.1104 15.2505 16.2486C13.8599 16.608 12.2943 16.8096 10.7956 16.8059C9.30863 16.8022 7.81672 16.5963 6.6108 16.0863C6.10212 15.8713 5.86412 15.2845 6.07921 14.7758Z", fill: "currentColor", key: "3" }],
  ["path", { d: "M8.27703 17.5328C7.74058 17.4015 7.19926 17.73 7.06797 18.2664C6.93667 18.8029 7.26511 19.3442 7.80156 19.4755C9.4096 19.869 11.3338 19.888 13.1276 19.6554C13.6753 19.5844 14.0617 19.0828 13.9907 18.5351C13.9197 17.9874 13.4181 17.601 12.8704 17.672C11.2399 17.8834 9.57694 17.851 8.27703 17.5328Z", fill: "currentColor", key: "4" }]
];

export default Tornado02Icon;
