import React, {ReactNode} from 'react';

import {Typography} from '../Typography';
import {ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

export type ListItemProps = ViewProps & {
  prefix?: ReactNode;
  suffix?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
};

export const ListItem = ({prefix, suffix, title, subTitle, disabled, ...props}: ListItemProps) => {
  const hoverStyle: ViewProps = disabled ? {} : {borderRadius: '$rounded-lg', backgroundColor: '$bg-brand-primary'};

  return (
    <XStack
      padding="$1"
      paddingHorizontal="$3"
      gap="$1"
      height="$5"
      cursor="pointer"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      backgroundColor={disabled ? '$bg-quaternary' : '$bg-primary'}
      hoverStyle={hoverStyle}
      {...props}>
      {prefix}
      <YStack flex={1} marginHorizontal="$0.5">
        <Typography>{title}</Typography>
        {subTitle && <Typography variant="caption">{subTitle}</Typography>}
      </YStack>
      {suffix}
    </XStack>
  );
};
