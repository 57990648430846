import React, {useEffect} from 'react';

import Animated, {useAnimatedProps, useSharedValue, withTiming} from 'react-native-reanimated';
import {Svg, Path} from 'react-native-svg';
import {ColorTokens, FontSizeTokens, getFontSize} from 'tamagui';

import {useTokenColor} from '../../utils/useTokenColor';
import {Typography} from '../Typography';
import {View} from '../View';

import {getCirclePath} from './utils';

const AnimatedPath = Animated.createAnimatedComponent(Path);

type CircularProgressProps = {
  value: number;
  size?: FontSizeTokens;
  thickness?: FontSizeTokens;
  activeColor?: ColorTokens;
  restColor?: ColorTokens;
  backgroundColor?: ColorTokens;
  showPercentage?: boolean;
  text?: string;
  isHalfCircle?: boolean;
};

export const CircularProgress: React.FC<CircularProgressProps> = ({
  value,
  size = '$24',
  thickness = '$3',
  activeColor = '$brand-700',
  restColor = '$gray-200',
  backgroundColor = '$background',
  showPercentage = true,
  text = '',
  isHalfCircle = false,
}) => {
  const sizeValue = getFontSize(size);
  const thicknessValue = getFontSize(thickness);

  const progress = Math.min(Math.max(value, 0), 100);
  const radius = (sizeValue - thicknessValue) / 2;

  const circumference = 2 * Math.PI * radius * (isHalfCircle ? 0.5 : 1);
  const strokeDashoffset = useSharedValue(circumference);

  useEffect(() => {
    const offset = circumference - (progress / 100) * circumference;
    strokeDashoffset.value = withTiming(offset, {duration: 750});
  }, [progress, circumference]);

  const ActiveColorValue = useTokenColor(activeColor);
  const RestColorValue = useTokenColor(restColor);
  const BackgroundColorValue = useTokenColor(backgroundColor);

  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: strokeDashoffset.value,
  }));

  const circlePath = getCirclePath(sizeValue / 2, sizeValue / 2, radius, isHalfCircle);

  return (
    <View alignItems="center">
      <View
        width={size}
        height={size}
        backgroundColor={BackgroundColorValue}
        borderRadius={sizeValue / 2}
        overflow="hidden">
        <Svg width={size} height={isHalfCircle ? sizeValue / 2 : size}>
          <Path d={circlePath} stroke={RestColorValue} strokeWidth={thicknessValue} fill="none" />

          <AnimatedPath
            d={circlePath}
            stroke={ActiveColorValue}
            strokeWidth={thicknessValue}
            fill="none"
            strokeDasharray={circumference}
            animatedProps={animatedProps}
            strokeLinecap="round"
          />
        </Svg>

        {showPercentage && (
          <View
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            justifyContent="center"
            alignItems="center"
            gap="$1">
            {text && (
              <Typography variant="body2" color="$text-tertiary" fontSize={sizeValue / 12}>
                {text}
              </Typography>
            )}
            <Typography variant="body1" fontSize={sizeValue / 6}>
              {Math.round(progress)}%
            </Typography>
          </View>
        )}
      </View>
    </View>
  );
};
