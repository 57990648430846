import {TabsListProps} from 'tamagui';

import {TypographyProps} from '../../Typography';
import {ViewProps} from '../../View';
import {TabsProps} from '../types';

const sharedTabStyles: Partial<TabsListProps> = {
  paddingVertical: '$spacing-md',
  paddingHorizontal: '$spacing-lg',
  gap: '$spacing-md',
  height: 36, //!! not existe in tokens and no token used in DS
  flex: 1,
  backgroundColor: '$background',
};

export const genTabStyles = (
  isSelected = false,
  orientation: TabsProps['orientation'] = 'horizontal',
  type: TabsProps['type'] = 'buttonBrand',
): Partial<TabsListProps> => {
  const isHorizontal = orientation === 'horizontal';
  const underLineBorder = isHorizontal ? 'borderBottom' : 'borderLeft';

  const verticalStyles: TabsListProps = {
    width: '100%',
    justifyContent: 'flex-start',
    minWidth: '$12',
  };

  const styleMap: Partial<Record<NonNullable<TabsProps['type']>, Partial<TabsListProps>>> = {
    buttonBrand: {
      borderWidth: 0,
      borderRadius: '$rounded-lg',
      backgroundColor: isSelected ? '$bg-brand-primary-alt' : '$background',
    },
    buttonGray: {
      borderWidth: 0,
      borderRadius: '$rounded-lg',
      backgroundColor: isSelected ? '$bg-active' : '$transparent',
    },
    underline: {
      borderColor: '$transparent',
      [`${underLineBorder}Width`]: isSelected ? 2 : isHorizontal ? 1 : 0,
      [`${underLineBorder}Color`]: isSelected ? '$fg-brand-primary-alt' : '$border-secondary',
      backgroundColor: isSelected ? '$transparent' : '$background',
    },
    buttonBorder: {
      backgroundColor: isSelected ? '$bg-primary-alt' : '$transparent',
      borderColor: '$transparent',
      borderRadius: '$rounded-sm',
    },
    buttonMinimal: {
      backgroundColor: isSelected ? '$bg-primary-alt' : '$transparent',
      borderColor: isSelected ? '$border-primary' : '$transparent',
      borderRadius: '$rounded-md',
      borderWidth: 1,
    },
  };

  return {
    ...sharedTabStyles,
    ...(styleMap[type] || {}),
    ...(isHorizontal ? {} : verticalStyles),
  };
};

const sharedTextStyles: Partial<TypographyProps> = {
  fontWeight: '600',
  fontSize: '$text-md',
};

export const genTextStyles = (
  isSelected = false,
  type: TabsProps['type'] = 'buttonBrand',
): Partial<TypographyProps> => {
  const styleMap: Partial<Record<NonNullable<TabsProps['type']>, Partial<TypographyProps>>> = {
    buttonBrand: {
      color: isSelected ? '$text-brand-secondary' : '$text-quaternary',
    },
    buttonGray: {
      color: isSelected ? '$text-secondary' : '$text-quaternary',
    },
    underline: {
      color: isSelected ? '$text-brand-secondary' : '$text-quaternary',
    },
    buttonBorder: {
      color: isSelected ? '$text-secondary' : '$text-quaternary',
    },
  };

  return {
    ...sharedTextStyles,
    ...(styleMap[type] || {}),
  };
};

export const genContainerStyles = (
  orientation: TabsProps['orientation'] = 'horizontal',
  type: TabsProps['type'] = 'buttonBrand',
): Partial<ViewProps> => {
  const isHorizontal = orientation === 'horizontal';

  const shared: ViewProps = {
    flex: 1,
    alignItems: 'center',
    gap: '$spacing-xs',
    width: '100%',
    alignSelf: isHorizontal ? 'center' : 'flex-start',
    backgroundColor: '$background',
  };

  const styleMap: Partial<Record<NonNullable<TabsProps['type']>, Partial<ViewProps>>> = {
    underline: {
      gap: '$0',
    },
    buttonBorder: {
      padding: '$spacing-xs',
      height: isHorizontal ? 44 : 'unset',
      borderWidth: 1,
      backgroundColor: '$bg-secondary-alt',
      borderRadius: '$rounded-lg',
      borderColor: '$border-secondary',
    },
    buttonMinimal: {
      padding: '$0',
      height: 'unset',
      borderWidth: 1,
      backgroundColor: '$bg-secondary-alt',
      borderRadius: '$rounded-lg',
      borderColor: '$border-secondary',
    },
  };

  return {
    ...shared,
    ...(styleMap[type] || {}),
  };
};
