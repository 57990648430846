import React from 'react';

import {View} from '../../View';
import {XStack} from '../../XStack';

type LineStepProps = {
  isHorizontal: boolean;
  stepWidth: number;
  index: number;
  currentStep: number;
  gap: number;
};

export const LineStep = ({isHorizontal, stepWidth, index, currentStep, gap}: LineStepProps) => {
  return (
    <XStack alignItems="center" flexDirection={isHorizontal ? 'row' : 'column'} justifyContent="space-between">
      <View
        height={isHorizontal ? 4 : stepWidth}
        backgroundColor={index + 1 <= currentStep ? '$fg-brand-primary' : '$bg-quaternary'}
        width={isHorizontal ? stepWidth : 4}
        marginLeft={!!index && isHorizontal ? gap : 0}
        marginTop={!!index && !isHorizontal ? gap : 0}
      />
    </XStack>
  );
};
