import React, {useState} from 'react';

import {Button, useMedia, ScrollView} from 'tamagui';

import {BidirectionalIcon} from '../BidirectionalIcon';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

export type PaginationProps = {
  currentPage: number;
  onPageChange?: (page: number) => void | undefined;
  displayCount?: {start: number; end: number};
  showEllipsis?: boolean;
  compactMode?: boolean;
  directionButtonsOnly?: boolean;
  layout?: 'default' | 'compact' | 'icons-only';
  position?: 'left' | 'center' | 'right';
  showNumbersOrInfo?: 'numbers' | 'info';
  roundedOnFocusOrHover?: boolean;
  navigationButtonType?: 'icons' | 'words' | 'both';
  borderedPageNumbers?: boolean;
  paginationInfoPosition?: 'left' | 'center' | 'right';
  totalRecords?: number;
  totalRecordsPerPage?: number;
  totalNumberOfPages?: number;
};

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  onPageChange = () => {},
  displayCount = {start: 3, end: 3},
  showEllipsis = true,
  position = 'center',
  showNumbersOrInfo = 'numbers',
  roundedOnFocusOrHover = false,
  navigationButtonType = 'both',
  borderedPageNumbers = false,
  paginationInfoPosition = 'center',
  totalRecords = 0,
  totalRecordsPerPage = 0,
  totalNumberOfPages = 0,
}) => {
  const [internalPage, setInternalPage] = useState(1);
  const [clickedButton, setClickedButton] = React.useState<'prev' | 'next' | null>(null);
  const [hoveredButton, setHoveredButton] = React.useState<'prev' | 'next' | null>(null);

  const media = useMedia();

  const totalPages = totalNumberOfPages ? totalNumberOfPages : Math.ceil(totalRecords / totalRecordsPerPage);
  const activePage = currentPage < totalNumberOfPages ? currentPage || internalPage : 1;

  if (!totalRecords || activePage === totalPages) return null;

  const isMobile = media.xs;
  const adjustedShowNumbersOrInfo = isMobile ? 'info' : showNumbersOrInfo;
  const adjustedNavigationButtonType = isMobile ? 'icons' : navigationButtonType;
  const layoutPosition = position === 'left' ? 'flex-start' : position === 'right' ? 'flex-end' : 'center';

  const getBorderColor = (button: 'prev' | 'next') => {
    if (clickedButton === button || hoveredButton === button) {
      return '$brand-700';
    }
    return '$gray-200';
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      if (onPageChange) {
        onPageChange(page);
      } else {
        setInternalPage(page);
      }
    }
  };

  const renderPageNumbers = (): JSX.Element[] => {
    const pages: JSX.Element[] = [];
    const {start, end} = displayCount;

    if (start + end >= totalPages || totalPages <= start + end) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Button
            key={i}
            size="sm"
            color="$text-tertiary"
            fontSize="$14"
            fontWeight="600"
            backgroundColor="$bg-primary"
            borderColor={activePage === i ? '$brand-700' : '$gray-200'}
            borderWidth={activePage === i ? 1 : 0}
            onPress={() => handlePageChange(i)}
            borderRadius={roundedOnFocusOrHover ? 24 : 8}
            onMouseEnter={(e) => {
              if (activePage !== i) e.currentTarget.style.backgroundColor = '$gray-50';
            }}
            onMouseLeave={(e) => {
              if (activePage !== i) e.currentTarget.style.backgroundColor = '$bg-primary';
            }}>
            {i}
          </Button>,
        );
      }
      return pages;
    }

    for (let i = 1; i <= Math.min(start, totalPages); i++) {
      pages.push(
        <Button
          key={i}
          size="sm"
          color="$text-tertiary"
          fontSize="$14"
          fontWeight="600"
          backgroundColor="$bg-primary"
          borderColor={activePage === i ? '$brand-700' : borderedPageNumbers ? '$gray-200' : 'transparent'}
          borderWidth={borderedPageNumbers || activePage === i ? 1 : 0}
          onPress={() => handlePageChange(i)}
          borderRadius={roundedOnFocusOrHover ? 24 : 8}
          onMouseEnter={(e) => {
            if (activePage !== i) e.currentTarget.style.backgroundColor = '$gray-50';
          }}
          onMouseLeave={(e) => {
            if (activePage !== i) e.currentTarget.style.backgroundColor = '$bg-primary';
          }}>
          {i}
        </Button>,
      );
    }

    if (!showEllipsis) {
      for (let i = start + 1; i <= totalPages; i++) {
        pages.push(
          <Button
            key={i}
            size="sm"
            color="$text-tertiary"
            fontSize="$14"
            fontWeight="600"
            backgroundColor="$bg-primary"
            borderColor={activePage === i ? '$brand-700' : borderedPageNumbers ? '$gray-200' : 'transparent'}
            borderWidth={borderedPageNumbers || activePage === i ? 1 : 0}
            borderRadius={roundedOnFocusOrHover ? 24 : 8}
            onPress={() => handlePageChange(i)}
            onMouseEnter={(e) => {
              if (activePage !== i) e.currentTarget.style.backgroundColor = '$gray-50';
            }}
            onMouseLeave={(e) => {
              if (activePage !== i) e.currentTarget.style.backgroundColor = '$bg-primary';
            }}>
            {i}
          </Button>,
        );
      }
      return pages;
    }

    if (activePage >= start + 1) {
      for (let i = start + 1; i <= activePage; i++) {
        if (!pages.some((page) => page.key === String(i))) {
          pages.push(
            <Button
              key={i}
              size="sm"
              color="$text-tertiary"
              fontSize="$14"
              fontWeight="600"
              backgroundColor="$bg-primary"
              borderColor={activePage === i ? '$brand-700' : borderedPageNumbers ? '$gray-200' : 'transparent'}
              borderWidth={borderedPageNumbers || activePage === i ? 1 : 0}
              onPress={() => handlePageChange(i)}
              borderRadius={roundedOnFocusOrHover ? 24 : 8}
              onMouseEnter={(e) => {
                if (activePage !== i) e.currentTarget.style.backgroundColor = '$gray-50';
              }}
              onMouseLeave={(e) => {
                if (activePage !== i) e.currentTarget.style.backgroundColor = '$bg-primary';
              }}>
              {i}
            </Button>,
          );
        }
      }
    }

    if (activePage <= totalPages - end - 1) {
      pages.push(
        <Typography
          key="ellipsis"
          color="$text-tertiary"
          fontSize="$14"
          fontWeight="600"
          marginHorizontal={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          ...
        </Typography>,
      );
    }

    for (let i = Math.max(totalPages - end + 1, activePage + 1); i <= totalPages; i++) {
      pages.push(
        <Button
          key={i}
          size="sm"
          color="$text-tertiary"
          fontSize="$14"
          fontWeight="600"
          backgroundColor="$bg-primary"
          borderColor={activePage === i ? '$brand-700' : borderedPageNumbers ? '$gray-200' : 'transparent'}
          borderWidth={borderedPageNumbers || activePage === i ? 1 : 0}
          onPress={() => handlePageChange(i)}
          borderRadius={roundedOnFocusOrHover ? 24 : 8}
          onMouseEnter={(e) => {
            if (activePage !== i) e.currentTarget.style.backgroundColor = '$gray-50';
          }}
          onMouseLeave={(e) => {
            if (activePage !== i) e.currentTarget.style.backgroundColor = '$bg-primary';
          }}>
          {i}
        </Button>,
      );
    }

    return pages;
  };

  const renderPaginationInfo = () => {
    return (
      <Typography
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        color="$text-tertiary"
        fontSize="$14"
        fontWeight="600">
        Page {activePage} of {totalPages}
      </Typography>
    );
  };

  const renderNavigationButton = (type: 'prev' | 'next', isDisabled: boolean, onClick: () => void) => {
    const isPrev = type === 'prev';

    return (
      <Button
        size="sm"
        width={navigationButtonType === 'icons' ? 45 : 'unset'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        backgroundColor="$bg-primary"
        color="$text-tertiary"
        onMouseEnter={() => setHoveredButton(type)}
        onMouseLeave={() => setHoveredButton(null)}
        borderColor={getBorderColor(type)}
        borderWidth={isDisabled ? 0 : 1}
        borderRadius={12}
        gap={3}
        fontSize="$14"
        fontWeight="600"
        disabled={totalPages === 1 || isDisabled}
        onPress={() => {
          onClick();
          setClickedButton(type);
        }}>
        {adjustedNavigationButtonType !== 'words' && isPrev && (
          <BidirectionalIcon icon="ArrowLeft02Icon" size={media.md ? 20 : 15} />
        )}
        {adjustedNavigationButtonType !== 'icons' && <CoreTrans i18nKey={isPrev ? 'action.previous' : 'action.next'} />}
        {adjustedNavigationButtonType !== 'words' && !isPrev && (
          <BidirectionalIcon icon="ArrowRight02Icon" size={media.md ? 20 : 15} />
        )}
      </Button>
    );
  };

  const renderLayout = () => {
    if (adjustedShowNumbersOrInfo === 'info') {
      if (paginationInfoPosition === 'left') {
        return (
          <XStack flex={1} gap={3} alignItems="center">
            {renderPaginationInfo()}
            {renderNavigationButton('prev', currentPage === 1, () => onPageChange(currentPage - 1))}
            {renderNavigationButton('next', currentPage === totalPages, () => onPageChange(currentPage + 1))}
          </XStack>
        );
      } else if (paginationInfoPosition === 'right') {
        return (
          <XStack flex={1} justifyContent={layoutPosition} gap={10} alignItems="center">
            {renderNavigationButton('prev', currentPage === 1, () => onPageChange(currentPage - 1))}
            {renderNavigationButton('next', currentPage === totalPages, () => onPageChange(currentPage + 1))}
            {renderPaginationInfo()}
          </XStack>
        );
      }
    }

    return (
      <XStack justifyContent="center" gap={10} alignItems="center">
        {renderNavigationButton('prev', currentPage === 1, () => onPageChange(currentPage - 1))}
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <XStack gap={2}>
            {adjustedShowNumbersOrInfo === 'numbers' ? renderPageNumbers() : renderPaginationInfo()}
          </XStack>
        </ScrollView>
        {renderNavigationButton('next', currentPage === totalPages, () => onPageChange(currentPage + 1))}
      </XStack>
    );
  };

  return (
    <XStack justifyContent={layoutPosition} paddingVertical={20}>
      {renderLayout()}
    </XStack>
  );
};
