import {config} from '@tamagui/config';
import {createFont} from 'tamagui';

import {FONT_TOKENS} from './constant';

const face = {
  600: {normal: 'Tajawal-Bold'},
  bold: {normal: 'Tajawal-Bold'},
};

export const fonts = {
  ...config.fonts,
  body: createFont({
    ...config.fonts.body,
    size: FONT_TOKENS,
    family: 'Tajawal',
    face,
  }),
  heading: createFont({
    ...config.fonts.heading,
    family: 'Tajawal',
    face,
  }),
};
