import {AccordionVariantsConfig} from '../models';

export const AccordionConfig: AccordionVariantsConfig = {
  outlined: {
    headerBackground: '$background',
    openedHeaderBackground: '$transparent',
    iconColor: '$gray-600',
    headerTextColor: '$gray-600',
    padding: '$5',
    contentPadding: '0',
  },
  filled: {
    headerBackground: '$bg-primary',
    openedHeaderBackground: '$bg-brand-primary',
    iconColor: '$gray-600',
    headerTextColor: '$textColor',
    padding: '$0',
    contentPadding: 16,
  },
};
