import React, {cloneElement, isValidElement, ReactElement, ReactNode} from 'react';

import {View} from '../View';

import {getAddonAfterStyles, getAddonBeforeStyles} from './styles/addOnStyles';
import {InputProps} from './types';

type Props = {
  element: ReactNode;
  size: InputProps['size'];
  isRTL: boolean;
  position: 'before' | 'after';
};

export const AddOnBeforeAfter: React.FC<Props> = ({element, size = 'sm', isRTL, position}) => {
  if (!element) {
    return null;
  }

  if (typeof element === 'string') {
    return (
      <View {...(position === 'after' ? getAddonAfterStyles({size, isRTL}) : getAddonBeforeStyles({size, isRTL}))}>
        {element}
      </View>
    );
  } else if (isValidElement(element)) {
    const Component: ReactElement = element;

    return cloneElement(Component, {
      ...(position === 'after'
        ? getAddonAfterStyles({size, isRTL, isReactElement: true})
        : getAddonBeforeStyles({size, isRTL, isReactElement: true})),
      ...Component.props,
    });
  }

  return null;
};
