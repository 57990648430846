import React from 'react';

import {Slider} from 'antd';
import {Trans, useTranslation} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {ColorInput} from '../../../../ColorInput';
import {LocalizedInput} from '../../../../LocalizedInput';
import {Select} from '../../../../Select';
import {XStack} from '../../../../XStack';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';
import {StateAwareInput} from '../../../components/StateAwareInput';

import {LinkElementProps} from './types';

export const _LinkPropsBuilder: PropsBuilder<LinkElementProps> = ({value: propsValue, onChange, onReset, options}) => {
  const {t} = useTranslation();

  const {href, lineHeight, fontSize, value, color, backgroundColor, fontWeight, justifyContent} = propsValue;

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };

  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="content" />} onReset={() => onResetProps(['value'])}>
        <LocalizedInput
          value={value}
          onChange={(value) => {
            onUpdateProps('value', value);
          }}
          input={({localizedValue, onLocalizedValueChange}) => (
            <StateAwareInput
              value={localizedValue}
              onChangeText={onLocalizedValueChange}
              options={options}
              types={['number', 'string']}
            />
          )}
        />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.basic.url" />}>
        <XStack alignItems="center" justifyContent="space-between">
          <StateAwareInput
            value={href || ''}
            onChangeText={(value) => onUpdateProps('href', value)}
            width="80%"
            options={options}
          />
        </XStack>
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.flex.alignSelf" />}
        onReset={() => onResetProps(['justifyContent'])}>
        <Select
          width="80%"
          value={justifyContent}
          options={[
            {label: t('settings.layout.left'), value: 'flex-start'},
            {label: t('settings.layout.center'), value: 'center'},
            {label: t('settings.layout.right'), value: 'flex-end'},
          ]}
          onChange={(value) => onUpdateProps('justifyContent', value)}
          placeholder={t('settings.layout.left')}
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.fontWeight" />}
        onReset={() => onResetProps(['fontWeight'])}>
        <Select
          value={fontWeight}
          options={[
            {label: '700', value: '700'},
            {label: '600', value: '600'},
            {label: '500', value: '500'},
            {label: '400', value: '400'},
          ]}
          onChange={(value) => onUpdateProps('fontWeight', value)}
          width="80%"
          placeholder="400"
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.fontSize" />}
        onReset={() => onResetProps(['fontSize'])}>
        <Slider
          value={(fontSize || 50) as number}
          defaultValue={30}
          onChange={(value) => onUpdateProps('fontSize', value)}
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.lineHeight" />}
        onReset={() => onResetProps(['lineHeight'])}>
        <Slider
          value={lineHeight as number}
          defaultValue={30}
          onChange={(value) => onUpdateProps('lineHeight', value)}
        />
      </EditSectionElement>
      <EditSectionElement title={<Trans i18nKey="settings.style.color" />} onReset={() => onResetProps(['color'])}>
        <ColorInput value={color || 'black'} onChange={(value) => onUpdateProps('color', value)} />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.style.backgroundColor" />}
        onReset={() => onResetProps(['backgroundColor'])}>
        <ColorInput
          value={backgroundColor || 'transparent'}
          onChange={(value) => onUpdateProps('backgroundColor', value)}
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const LinkPropsBuilder: BuilderPropsGroup<LinkElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.LinkElement'),
      Content: _LinkPropsBuilder,
    },
  ],
};
