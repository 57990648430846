import React from 'react';

import {
  Progress as BaseProgress,
  ProgressProps as BaseProgressProps,
  ColorTokens,
  ProgressIndicatorProps,
  SizeTokens,
  XStackProps,
} from 'tamagui';

import {Badge} from '../Badge';
import {Text, TextProps} from '../Text';
import {XStack} from '../XStack';

import {getContainerStyle} from './utils';

export type ProgressBarProps = Omit<BaseProgressProps, 'width'> & {
  color?: ColorTokens;
  animation?: ProgressIndicatorProps['animation'];
  textValueProps?: TextProps;
  displayValue?: boolean;
  textValuePosition?: 'right' | 'bottom';
  badgePosition?: 'top' | 'bottom';
  showBadge?: boolean;
  width?: SizeTokens;
};

export const ProgressBar = ({
  color = '$fg-brand-primary',
  animation = 'bouncy',
  textValueProps,
  displayValue = true,
  textValuePosition = 'right',
  badgePosition = 'top',
  showBadge = false,
  minWidth = '100%',
  maxWidth = '100%',
  width = '$10',
  ...props
}: ProgressBarProps) => {
  const containerStyle = {
    width,
    maxWidth,
    minWidth,
    ...getContainerStyle({
      showBadge,
      textValuePosition,
      badgePosition,
    }),
  } as XStackProps;

  return (
    <XStack {...containerStyle}>
      {displayValue && showBadge && (
        <Badge
          label={`${props.value}%`}
          paddingVertical="$2"
          paddingHorizontal="$2"
          borderRadius="$rounded-md"
          marginLeft={`${props.value}%`}
          type="badge-color"
          color="gray"
        />
      )}

      <BaseProgress backgroundColor="$bg-quaternary" borderRadius={999} flex={1} minWidth={0} {...props}>
        <BaseProgress.Indicator animation={animation} backgroundColor={color} />
      </BaseProgress>

      {displayValue && !showBadge && (
        <Text color="$text-secondary" fontSize="$14" fontWeight="500" {...textValueProps}>
          {(props.value || 0).toFixed(0)}%
        </Text>
      )}
    </XStack>
  );
};
