import React from 'react';

import {CircularProgress} from '../CircularProgress';
import {IconView} from '../IconView';
import {Pressable} from '../Pressable';
import {ProgressBar} from '../ProgressBar';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View, ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

export type FileProgressProps = {
  progress: number;
  fileName: string;
  fileSize: number; // in bytes
  error?: string;
  progressType: 'percentage' | 'progress';
  onDelete?: (fileName: string) => void;
};

export const FileProgress = ({
  fileName,
  fileSize,
  progress,
  error = '',
  progressType,
  onDelete,
  ...props
}: FileProgressProps & ViewProps) => {
  const isErrored = !!error;
  const isLoaded = progress === 100;
  const isUploading = !isErrored && progress > 0 && progress < 100;
  const sizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
  const isProgress = progressType === 'progress';
  return (
    <View
      padding="$4"
      gap="$4"
      backgroundColor="$bg-primary"
      borderRadius="$rounded-lg"
      borderWidth={1}
      borderColor={isErrored ? '$border-error' : '$border-secondary'}
      {...props}>
      {isProgress && (
        <View
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width={`${progress}%`}
          backgroundColor="$bg-disabled"
          borderTopLeftRadius="$rounded-lg"
          borderBottomLeftRadius="$rounded-lg"
        />
      )}
      <XStack justifyContent="space-between">
        <XStack gap="$3" alignItems="center">
          <IconView icon="File02Icon" size={40} color="$gray-300" />
          <YStack gap="$0.5">
            <Typography numberOfLines={1} fontWeight="500" variant="body2" color="$text-secondary">
              {fileName}
            </Typography>
            <Typography fontWeight="400" variant="body2" color="$text-secondary">
              {sizeInMB} <CoreTrans i18nKey="fileProgress.MB" />
            </Typography>
          </YStack>
        </XStack>
        {isProgress && isUploading && (
          <CircularProgress
            value={Number(progress.toFixed(2))}
            activeColor={isErrored ? '$bg-error-solid' : '$fg-brand-primary'}
            showPercentage={false}
            size={24}
          />
        )}
        {!isUploading && onDelete && (
          <Pressable onPress={() => onDelete?.(fileName)}>
            <IconView icon="Delete02Icon" size={24} color="$fg-quinary" />
          </Pressable>
        )}
      </XStack>
      <YStack>
        {progressType === 'percentage' && <ProgressBar value={Number(progress.toFixed(2))} height={8} />}
        <XStack gap="$1" justifyContent="space-between">
          <Typography numberOfLines={1} fontWeight="700" variant="body2" color="$text-secondary">
            {isUploading && <CoreTrans i18nKey="fileProgress.uploading" />}
            {isLoaded && !isErrored && <CoreTrans i18nKey="fileProgress.fileUploadedSuccessfully" />}
            {isErrored && error}
          </Typography>
        </XStack>
      </YStack>
    </View>
  );
};
