import React, {createContext, ReactNode, useState, useEffect} from 'react';

import {useTranslation} from 'react-i18next';

import {API_BUILDER_REQUESTS_URL} from '../../config';
import {useAPI} from '../../services';
import {AppContentPage, PageResource, State} from '../../types';
import {coreScopedKey} from '../../utils';
import {useStateContext} from '../StateProvider';
import {showToast} from '../Toast';

import {extractPath} from './utils/extractPath';

type ResourceProviderProps = {
  children: ReactNode;
  resource: AppContentPage['resource'];
};

type ResourceContextProps = {
  isLoading: boolean;
};

const ResourceContext = createContext<ResourceContextProps>({
  isLoading: false,
});

export const Provider = ({resource, children}: ResourceProviderProps) => {
  const api = useAPI();
  const {createState} = useStateContext();
  const [isLoading, setIsLoading] = useState(!!resource);
  const {t} = useTranslation();

  const fetchResourceData = async (resourceId: PageResource['id'], resourceName: PageResource['name']) => {
    try {
      const response = await api.post(`${API_BUILDER_REQUESTS_URL}/${resourceId}/send`);
      const dataResponse: any = await response.json();
      return {data: dataResponse?.data, resourceName};
    } catch (error) {
      showToast({title: t(coreScopedKey('resourceErrorMsg'), {resourceName}), preset: 'error'});
      return null;
    }
  };

  const processArrayData = (
    data: unknown[],
    resourceId: PageResource['id'],
    resourceName: PageResource['name'],
    key: string,
  ) => {
    const state: State = {
      id: String(resourceId),
      isSecret: false,
      level: 'api',
      name: resourceName,
      scopeId: key,
      type: 'array',
      value: data,
    };
    createState(state, key);
  };

  const processPredefinedPaths = (data: unknown, predefinedPaths: PageResource['predefinedPaths'], key: string) => {
    if (!predefinedPaths) return;
    for (const path of predefinedPaths) {
      const pathData = extractPath(data, path.jsonPath);

      if (!pathData) {
        continue;
      }

      const state: State = {
        id: String(path.jsonPath),
        isSecret: false,
        level: 'api',
        name: path.name,
        scopeId: key,
        type: path.type,
        value: pathData,
      };

      createState(state, key);
    }
  };

  const processResourceItems = async (resourceItems: PageResource[], key: string) => {
    for (const resource of resourceItems) {
      const result = await fetchResourceData(resource.id, resource.name);

      if (!result?.data) continue;

      if (Array.isArray(result.data)) {
        processArrayData(result.data, resource.id, result.resourceName, key);
      }

      if (resource?.predefinedPaths) {
        processPredefinedPaths(result.data, resource.predefinedPaths, key);
      }
    }
  };

  useEffect(() => {
    const processResources = async () => {
      if (!resource) return;

      for (const key of Object.keys(resource)) {
        const resourceItems = resource[key];
        await processResourceItems(resourceItems, key);
      }

      setIsLoading(false);
    };

    processResources();
  }, [resource]);

  return <ResourceContext.Provider value={{isLoading}}>{children}</ResourceContext.Provider>;
};

export const useResourceContext = () => {
  const context = React.useContext(ResourceContext);
  return context;
};
