import React from 'react';

import {ColorTokens} from 'tamagui';

import {ListItem} from '../../../../../ListItem';
import {Popover} from '../../../../../Popover';
import {CoreTrans} from '../../../../../ScopedTrans';
import {Typography} from '../../../../../Typography';
import {View} from '../../../../../View';
import {XStack} from '../../../../../XStack';
import {YStack} from '../../../../../YStack';
import {ConditionData, RelationOperator} from '../types';

type CommonProps = {
  color?: ColorTokens;
};

type OperatorEditorProps =
  | (CommonProps & {
      value: RelationOperator;
      values: RelationOperator[];
      onChange: (value: RelationOperator) => void;
    })
  | (CommonProps & {
      value: ConditionData['operator'];
      values: ConditionData['operator'][];
      onChange: (value: ConditionData['operator']) => void;
    });

export const OperatorEditor = ({value, values, color = '$green-400', onChange}: OperatorEditorProps) => {
  return (
    <YStack justifyContent="center" alignItems="center">
      <View height="$1" backgroundColor="$border" width={0.1}></View>
      <XStack alignItems="center" justifyContent="center">
        <Popover
          closable
          closeOnClick
          contentProps={{padding: 0, width: '$16'}}
          trigger={
            <YStack
              height="$2"
              borderColor={color}
              borderWidth={1}
              borderRadius="$4"
              alignItems="center"
              paddingHorizontal="$1"
              justifyContent="center"
              width="unset">
              <Typography fontWeight="700" color={color}>
                <CoreTrans i18nKey={`builder.condition.${value}`} />
              </Typography>
            </YStack>
          }>
          {values?.map((value) => (
            <ListItem
              title={<CoreTrans i18nKey={`builder.condition.${value}`} />}
              height="$4"
              cursor="pointer"
              key={value}
              onPress={() => onChange(value as never)}
            />
          ))}
        </Popover>
      </XStack>
      <View height="$1" backgroundColor="$border" width={0.1}></View>
    </YStack>
  );
};
