import React from 'react';

import HTML, {RenderHTMLProps} from 'react-native-render-html';
import {ColorTokens, useTheme} from 'tamagui';

import {View, ViewProps} from '../View';

type HtmlViewProps = ViewProps & {
  content: string;
  htmlProps?: Omit<RenderHTMLProps, 'source'>;
  color?: ColorTokens;
};

export const HtmlView: React.FC<HtmlViewProps> = ({content, htmlProps, color = '$text-primary', ...props}) => {
  const theme = useTheme();
  const htmlViewColor = color && theme[color]?.val;

  return (
    <View {...props}>
      <HTML source={{html: content}} {...htmlProps} baseStyle={{color: htmlViewColor}} />
    </View>
  );
};
