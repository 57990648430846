import React, {ReactNode, useState} from 'react';

import {Pressable} from '../Pressable';
import {Typography} from '../Typography';
import {ViewProps} from '../View';
import {XStack} from '../XStack';

import {IconView} from './../IconView';

export interface BreadcrumbTypes extends ViewProps {
  prefix?: ReactNode;
  items: {title: string; routeId: string}[];
  onNavigate?: (routeId: string) => void;
  bordered?: boolean;
}

export const Breadcrumb = ({prefix, items, onNavigate, bordered = true, ...props}: BreadcrumbTypes) => {
  const [showAll, setShowAll] = useState(false);

  let visibleItems = items;

  if (!showAll && items.length > 2) {
    visibleItems = [items[0], {title: '...', routeId: 'more'}, items[items.length - 1]];
  }

  const _borderStyles = bordered
    ? {
        borderColor: '$border-secondary',
        borderTopWidth: '$0.5',
        borderBottomWidth: '$0.5',
      }
    : {};

  return (
    <XStack {..._borderStyles} $sm={{paddingHorizontal: '$4'}} height="$3.5" gap="$4" alignItems="center" {...props}>
      {prefix}

      {visibleItems.map((item, index) => (
        <React.Fragment key={item.routeId}>
          {index > 0 && <IconView icon="ArrowRight01Icon" size={14} color="$fg-senary" />}

          {item.routeId === 'more' ? (
            <Pressable onPress={() => setShowAll(true)}>
              <Typography fontSize="$text-sm" fontWeight="600" color="$breadcrumb-fg">
                ...
              </Typography>
            </Pressable>
          ) : (
            <Pressable onPress={() => onNavigate && onNavigate(item.routeId)}>
              <Typography
                fontWeight="600"
                color={index === visibleItems.length - 1 ? '$breadcrumb-brand-fg-hover' : '$breadcrumb-fg'}>
                {item.title}
              </Typography>
            </Pressable>
          )}
        </React.Fragment>
      ))}
    </XStack>
  );
};
