import React, {createContext, ReactNode, useContext, useState, useMemo, useEffect} from 'react';

import {ScopeLevel, State} from '../../types';

import {useGetGlobalVariables} from './hooks/useGetGlobalVariables';

type StateProviderProps = {
  children: ReactNode;
  state: Record<string, State[]>;
};

export interface StatesContextProps {
  state: Record<string, State[]>;
  createState: (newState: State, scopeId: string) => void;
  editState: (id: string, newValue: State, scopeId: string) => void;
  deleteState: (id: string) => void;
  allVariables: State[];
}

const defaultStateContext: StatesContextProps = {
  state: {},
  createState: () => {},
  editState: () => {},
  deleteState: () => {},
  allVariables: [],
};

const StateContext = createContext<StatesContextProps>(defaultStateContext);

export const Provider = ({state, children}: StateProviderProps) => {
  const [states, setStates] = useState<Record<string, State[]>>(state);
  const {states: globalState} = useGetGlobalVariables();

  useEffect(() => setStates(state), [state]);

  const allVariables = useMemo(() => {
    const variables = Object.entries({...states, ...globalState}).flatMap(([scopeId, states]) =>
      states.map((state) => ({...state, scopeId})),
    );
    variables.sort((a, b) => {
      if (a.level === ScopeLevel.page && b.level !== ScopeLevel.page) return -1;
      if (a.level !== ScopeLevel.page && b.level === ScopeLevel.page) return 1;
      return 0;
    });
    return variables;
  }, [states, globalState]);

  const createState = (newState: State, scopeId: string) => {
    setStates((prevStates) => {
      const updatedState = {...prevStates};

      if (!updatedState[scopeId]) {
        updatedState[scopeId] = [];
      }

      updatedState[scopeId].push(newState);

      return updatedState;
    });
  };

  const editState = (id: string, newValue: State, scopeId: string) => {
    setStates((prevStates) => {
      const updatedState = {...prevStates};

      if (updatedState[scopeId]) {
        updatedState[scopeId] = updatedState[scopeId].map((state) => (state.id === id ? newValue : state));
      }

      return updatedState;
    });
  };

  const deleteState = (id: string) => {
    setStates((prevStates) => {
      const updatedState = {...prevStates};

      for (const scopeId in updatedState) {
        updatedState[scopeId] = updatedState[scopeId].filter((state) => state.id !== id);
      }

      return updatedState;
    });
  };
  return (
    <StateContext.Provider
      value={{state: {...states, ...globalState}, createState, editState, deleteState, allVariables}}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext<StatesContextProps>(StateContext);
};

export const useStateValue = (stateId?: string, scopeId?: string): State | undefined => {
  const context = useStateContext();

  if (!context?.state) {
    return undefined;
  }

  if (scopeId) {
    return context.state[scopeId]?.find((state) => state.id === stateId);
  }

  for (const [_, states] of Object.entries(context.state)) {
    const foundState = states.find((state) => state.id === stateId);
    if (foundState) {
      return foundState;
    }
  }

  return undefined;
};
