import React, {useMemo} from 'react';

import {XStack as BaseXStack, XStackProps as BaseXStackProps} from 'tamagui';

import {SHADOW_BASE} from '../../lib/tamagui/constant';
import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export interface XStackProps extends BaseXStackProps {
  shadow?: keyof typeof SHADOW_BASE;
}

export const XStack = ({shadow, ...props}: XStackProps) => {
  const _props = useDirectionAwareProps(props);
  const shadowStyle = useMemo(() => (shadow ? SHADOW_BASE[shadow] : {}), [shadow]);

  return <BaseXStack {..._props} {...shadowStyle} />;
};
