import {InputProps, InputSize} from '../types';

type StylesProps = InputProps;

export const inputDefaultStyles: StylesProps = {
  backgroundColor: '$bg-primary',
  outlineColor: '$transparent',
  borderWidth: 1,
  outlineWidth: 0,
  borderStyle: 'solid',
  borderColor: '$border-primary',
  borderRadius: '$rounded-md',
  color: '$text-primary',
  fontWeight: '400',
  fontSize: '$text-md',
  flex: 1,
  alignItems: 'center',
  focusStyle: {
    borderColor: '$border-brand',
    outlineColor: '$border-brand',
    outlineStyle: 'solid',
    outlineWidth: 0,
  },
  hoverStyle: {
    borderColor: '$border-brand',
    outlineColor: '$border-brand',
    outlineStyle: 'solid',
    outlineWidth: 0,
  },
};

export const getInputDisabledStyles = (): StylesProps => {
  const _styles = {
    backgroundColor: '$bg-disabled-subtle',
    borderColor: '$border-disabled',
    placeholderTextColor: '$text-disabled',
    outlineWidth: 0,
    color: '$text-disabled',
  };

  return {
    ..._styles,
    cursor: 'not-allowed',
    focusStyle: {
      ..._styles,
    },
    hoverStyle: {
      ..._styles,
    },
  };
};

export const getInputStylesBaedONSize = (size: InputSize): StylesProps => {
  const _sizesStyle: {[key in InputSize]: StylesProps} = {
    sm: {
      paddingVertical: '$spacing-md',
      paddingHorizontal: '$spacing-lg',
      height: 40,
    },
    md: {
      paddingVertical: 10,
      paddingHorizontal: 14,
      height: 44,
    },
  };

  return _sizesStyle[size];
};

export const inputErrorStyles: StylesProps = {
  borderColor: '$border-error-subtle',
  focusStyle: {
    borderColor: '$border-error',
    outlineColor: '$border-error',
    outlineStyle: 'solid',
    outlineWidth: 0,
  },
  hoverStyle: {
    borderColor: '$border-error',
    outlineColor: '$border-error',
    outlineStyle: 'solid',
    outlineWidth: 0,
  },
};

export const getInputStylesBasedOnAddOnBefore = (): StylesProps => {
  return {
    borderStartStartRadius: 0,
    borderEndStartRadius: 0,
  };
};

export const getInputStylesBasedOnAddOnAfter = (): StylesProps => {
  return {
    borderStartEndRadius: 0,
    borderEndEndRadius: 0,
  };
};

export const resetInputStyles = (): StylesProps => {
  return {
    backgroundColor: '$transparent',
    outlineColor: '$transparent',
    borderWidth: 0,
    padding: 0,
    borderRadius: 0,
    color: '$text-primary',
    placeholderTextColor: '$text-placeholder',
    fontWeight: '400',
    fontSize: '$text-md',
    flex: 1,
    height: '100%',
    alignItems: 'center',
    focusStyle: {
      borderWidth: 0,
      outlineWidth: 0,
    },
    hoverStyle: {
      borderWidth: 0,
      outlineWidth: 0,
    },
  };
};
