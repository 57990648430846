import React, {ReactNode} from 'react';

import {AppContentPage} from '../../types';

import {Provider} from './Provider';

export type ResourceElementProps = {
  resource?: AppContentPage['resource'];
  children?: ReactNode;
};

export const ResourceProvider = ({children, resource}: ResourceElementProps) => {
  return <Provider resource={resource || {}}>{children}</Provider>;
};
