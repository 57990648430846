import React from 'react';

import {Button} from '../../Button';
import {CoreTrans} from '../../ScopedTrans';
import {XStack} from '../../XStack';

type Props = {
  monthsCount: number;
  onAccept: () => void;
  onCancel: () => void;
};
export function Footer({monthsCount, onAccept, onCancel}: Props) {
  const isMultiMonthView = monthsCount > 1;

  return (
    <XStack flex={1} gap="$spacing-lg" alignItems="center" justifyContent={isMultiMonthView ? 'flex-end' : 'center'}>
      <Button paddingHorizontal={14} width={isMultiMonthView ? 80 : 142} hierarchy="secondary-gray" onPress={onCancel}>
        <CoreTrans i18nKey="action.cancel" />
      </Button>
      <Button paddingHorizontal={14} width={isMultiMonthView ? 80 : 142} onPress={onAccept}>
        <CoreTrans i18nKey="action.apply" />
      </Button>
    </XStack>
  );
}
