import * as solid from '@medad-sep/hugeicons-core-solid-rounded';
import * as stroke from '@medad-sep/hugeicons-core-stroke-rounded';

const HugeIconsList = [
  "FirstBracketCircleIcon",
  "FirstBracketIcon",
  "FirstBracketSquareIcon",
  "SecondBracketCircleIcon",
  "SecondBracketIcon",
  "SecondBracketSquareIcon",
  "ThreeDViewIcon",
  "ThreeDMoveIcon",
  "ThreeDRotateIcon",
  "ThreeDScaleIcon",
  "ThirdBracketCircleIcon",
  "ThirdBracketIcon",
  "ThirdBracketSquareIcon",
  "FourKIcon",
  "SevenZ01Icon",
  "SevenZIcon",
  "SevenZ02Icon",
  "AbacusIcon",
  "AbsoluteIcon",
  "AccelerationIcon",
  "AccessIcon",
  "AccidentIcon",
  "AccountSetting01Icon",
  "AccountSettingIcon",
  "AccountSetting02Icon",
  "AccountSetting03Icon",
  "Activity01Icon",
  "ActivityIcon",
  "Activity02Icon",
  "Activity03Icon",
  "Activity04Icon",
  "AcuteIcon",
  "Add01Icon",
  "AddIcon",
  "Add02Icon",
  "AddCircleHalfDotIcon",
  "AddCircleIcon",
  "AddFemaleIcon",
  "AddMaleIcon",
  "AddSquareIcon",
  "AddTeam02Icon",
  "AddTeamIcon",
  "AddToListIcon",
  "AddressBookIcon",
  "AdobeAfterEffectIcon",
  "AdobeIllustratorIcon",
  "AdobeIndesignIcon",
  "AdobePhotoshopIcon",
  "AdobePremierIcon",
  "AdobeXdIcon",
  "AdventureIcon",
  "AdvertisimentIcon",
  "AdzanIcon",
  "AffiliateIcon",
  "Agreement01Icon",
  "AgreementIcon",
  "Agreement02Icon",
  "Agreement03Icon",
  "AiAudioIcon",
  "AiBeautifyIcon",
  "AiBookIcon",
  "AiBrain01Icon",
  "AiBrainIcon",
  "AiBrain02Icon",
  "AiBrain03Icon",
  "AiBrain04Icon",
  "AiBrain05Icon",
  "AiBrowserIcon",
  "AiChat01Icon",
  "AiChatIcon",
  "AiChat02Icon",
  "AiChemistry01Icon",
  "AiChemistryIcon",
  "AiChemistry02Icon",
  "AiChemistry03Icon",
  "AiChipIcon",
  "AiCloud01Icon",
  "AiCloud02Icon",
  "AiCloudIcon",
  "AiComputerIcon",
  "AiContentGenerator01Icon",
  "AiContentGeneratorIcon",
  "AiContentGenerator02Icon",
  "AiDnaIcon",
  "AiEditingIcon",
  "AiEraserIcon",
  "AiFileIcon",
  "AiFolder01Icon",
  "AiFolderIcon",
  "AiFolder02Icon",
  "AiGameIcon",
  "AiGenerativeIcon",
  "AiIdeaIcon",
  "AiImageIcon",
  "AiInnovation01Icon",
  "AiInnovationIcon",
  "AiInnovation02Icon",
  "AiInnovation03Icon",
  "AiLaptopIcon",
  "AiLearningIcon",
  "AiLockIcon",
  "AiMagicIcon",
  "AiMail01Icon",
  "AiMail02Icon",
  "AiMailIcon",
  "AiMicIcon",
  "AiNetworkIcon",
  "AiPhone01Icon",
  "AiPhoneIcon",
  "AiPhone02Icon",
  "AiProgrammingIcon",
  "AiScanIcon",
  "AiSchedulingIcon",
  "AiSearch02Icon",
  "AiSearchIcon",
  "AiSecurity01Icon",
  "AiSecurityIcon",
  "AiSecurity02Icon",
  "AiSecurity03Icon",
  "AiSettingIcon",
  "AiSheetsIcon",
  "AiSmartwatchIcon",
  "AiUserIcon",
  "AiVideoIcon",
  "AiViewIcon",
  "AiVisionRecognitionIcon",
  "AiVoiceGeneratorIcon",
  "AiVoiceIcon",
  "AiWebBrowsingIcon",
  "AidsIcon",
  "AirbnbIcon",
  "AircraftGameIcon",
  "AirdropIcon",
  "Airplane01Icon",
  "AirplaneIcon",
  "Airplane02Icon",
  "AirplaneLanding01Icon",
  "AirplaneLandingIcon",
  "AirplaneLanding02Icon",
  "AirplaneModeOffIcon",
  "AirplaneModeIcon",
  "AirplaneSeatIcon",
  "AirplaneTakeOff01Icon",
  "AirplaneTakeOffIcon",
  "AirplaneTakeOff02Icon",
  "AirplayLineIcon",
  "Airpod01Icon",
  "AirpodIcon",
  "Airpod02Icon",
  "Airpod03Icon",
  "AirportIcon",
  "AlAqsaMosqueIcon",
  "AlarmClockIcon",
  "Album01Icon",
  "AlbumIcon",
  "Album02Icon",
  "AlbumNotFound01Icon",
  "AlbumNotFoundIcon",
  "AlbumNotFound02Icon",
  "Alert01Icon",
  "AlertIcon",
  "Alert02Icon",
  "AlertCircleIcon",
  "AlertDiamondIcon",
  "AlertSquareIcon",
  "AlgorithmIcon",
  "Alien01Icon",
  "AlienIcon",
  "Alien02Icon",
  "AlignBottomIcon",
  "AlignBoxBottomCenterIcon",
  "AlignBoxBottomLeftIcon",
  "AlignBoxBottomRightIcon",
  "AlignBoxMiddleCenterIcon",
  "AlignBoxMiddleLeftIcon",
  "AlignBoxMiddleRightIcon",
  "AlignBoxTopCenterIcon",
  "AlignBoxTopLeftIcon",
  "AlignBoxTopRightIcon",
  "AlignHorizontalCenterIcon",
  "AlignKeyObjectIcon",
  "AlignLeftIcon",
  "AlignRightIcon",
  "AlignSelectionIcon",
  "AlignTopIcon",
  "AlignVerticalCenterIcon",
  "AllBookmarkIcon",
  "AllahIcon",
  "AlmsIcon",
  "AlphaCircleIcon",
  "AlphaIcon",
  "AlphaSquareIcon",
  "AlphabetArabicIcon",
  "AlphabetBanglaIcon",
  "AlphabetChineseIcon",
  "AlphabetGreekIcon",
  "AlphabetHebrewIcon",
  "AlphabetHindiIcon",
  "AlphabetJapaneseIcon",
  "AlphabetKoreanIcon",
  "AlphabetThaiIcon",
  "AmazonIcon",
  "AmbulanceIcon",
  "AmericanFootballIcon",
  "AmieIcon",
  "AmpouleIcon",
  "AnalysisTextLinkIcon",
  "Analytics01Icon",
  "AnalyticsIcon",
  "Analytics02Icon",
  "Analytics03Icon",
  "AnalyticsDownIcon",
  "AnalyticsUpIcon",
  "AnchorPointIcon",
  "AnchorIcon",
  "AndroidIcon",
  "AngelIcon",
  "Angle01Icon",
  "AngleIcon",
  "AngryBirdIcon",
  "AngryIcon",
  "AnonymousIcon",
  "ApiIcon",
  "AppStoreIcon",
  "Apple01Icon",
  "AppleFinderIcon",
  "AppleIntelligenceIcon",
  "AppleMusicIcon",
  "AppleNewsIcon",
  "ApplePieIcon",
  "AppleReminderIcon",
  "AppleIcon",
  "AppleStocksIcon",
  "AppleVisionProIcon",
  "Appointment01Icon",
  "AppointmentIcon",
  "Appointment02Icon",
  "ApproximatelyEqualCircleIcon",
  "ApproximatelyEqualIcon",
  "ApproximatelyEqualSquareIcon",
  "ApricotIcon",
  "ApronIcon",
  "ArcBrowserIcon",
  "ArcherIcon",
  "Archive01Icon",
  "Archive02Icon",
  "ArchiveIcon",
  "ArmoredBootIcon",
  "ArrangeByLettersAZIcon",
  "ArrangeByLettersZAIcon",
  "ArrangeByNumbers19Icon",
  "ArrangeByNumbers91Icon",
  "ArrangeIcon",
  "ArrowAllDirectionIcon",
  "ArrowDataTransferDiagonalIcon",
  "ArrowDataTransferHorizontalIcon",
  "ArrowDataTransferVerticalIcon",
  "ArrowDiagonalIcon",
  "ArrowDown01Icon",
  "ArrowDownIcon",
  "ArrowDown02Icon",
  "ArrowDown03Icon",
  "ArrowDown04Icon",
  "ArrowDown05Icon",
  "ArrowDownDoubleIcon",
  "ArrowDownLeft01Icon",
  "ArrowDownLeftIcon",
  "ArrowDownLeft02Icon",
  "ArrowDownRight01Icon",
  "ArrowDownRightIcon",
  "ArrowDownRight02Icon",
  "ArrowExpand01Icon",
  "ArrowExpand02Icon",
  "ArrowExpandDiagonal01Icon",
  "ArrowExpandDiagonalIcon",
  "ArrowExpandDiagonal02Icon",
  "ArrowExpandIcon",
  "ArrowHorizontalIcon",
  "ArrowLeft01Icon",
  "ArrowLeftIcon",
  "ArrowLeft02Icon",
  "ArrowLeft03Icon",
  "ArrowLeft04Icon",
  "ArrowLeft05Icon",
  "ArrowLeftDoubleIcon",
  "ArrowLeftRightIcon",
  "ArrowMoveDownLeftIcon",
  "ArrowMoveDownRightIcon",
  "ArrowMoveLeftDownIcon",
  "ArrowMoveRightDownIcon",
  "ArrowMoveUpLeftIcon",
  "ArrowMoveUpRightIcon",
  "ArrowReloadHorizontalIcon",
  "ArrowReloadVerticalIcon",
  "ArrowRight01Icon",
  "ArrowRightIcon",
  "ArrowRight02Icon",
  "ArrowRight03Icon",
  "ArrowRight04Icon",
  "ArrowRight05Icon",
  "ArrowRightDoubleIcon",
  "ArrowShrink01Icon",
  "ArrowShrink02Icon",
  "ArrowShrinkIcon",
  "ArrowTurnBackwardIcon",
  "ArrowTurnDownIcon",
  "ArrowTurnForwardIcon",
  "ArrowTurnUpIcon",
  "ArrowUp01Icon",
  "ArrowUpIcon",
  "ArrowUp02Icon",
  "ArrowUp03Icon",
  "ArrowUp04Icon",
  "ArrowUp05Icon",
  "ArrowUpDoubleIcon",
  "ArrowUpDownIcon",
  "ArrowUpLeft01Icon",
  "ArrowUpLeftIcon",
  "ArrowUpLeft02Icon",
  "ArrowUpRight01Icon",
  "ArrowUpRightIcon",
  "ArrowUpRight02Icon",
  "ArrowVerticalIcon",
  "ArtboardIcon",
  "ArtboardToolIcon",
  "ArtificialIntelligence01Icon",
  "ArtificialIntelligenceIcon",
  "ArtificialIntelligence02Icon",
  "ArtificialIntelligence03Icon",
  "ArtificialIntelligence04Icon",
  "ArtificialIntelligence05Icon",
  "ArtificialIntelligence06Icon",
  "ArtificialIntelligence07Icon",
  "ArtificialIntelligence08Icon",
  "AspectRatioIcon",
  "Asteroid01Icon",
  "AsteroidIcon",
  "Asteroid02Icon",
  "Astronaut01Icon",
  "AstronautIcon",
  "Astronaut02Icon",
  "AtIcon",
  "Atm01Icon",
  "AtmIcon",
  "Atm02Icon",
  "Atom01Icon",
  "AtomIcon",
  "Atom02Icon",
  "AtomicPowerIcon",
  "Attachment01Icon",
  "Attachment02Icon",
  "AttachmentCircleIcon",
  "AttachmentIcon",
  "AttachmentSquareIcon",
  "AuctionIcon",
  "AudioBook01Icon",
  "AudioBookIcon",
  "AudioBook02Icon",
  "AudioBook03Icon",
  "AudioBook04Icon",
  "AudioWave01Icon",
  "AudioWaveIcon",
  "AudioWave02Icon",
  "Audit01Icon",
  "AuditIcon",
  "Audit02Icon",
  "AugmentedRealityArIcon",
  "AuthorizedIcon",
  "AutoConversationsIcon",
  "AutomotiveBattery01Icon",
  "AutomotiveBatteryIcon",
  "AutomotiveBattery02Icon",
  "AvalancheIcon",
  "AvocadoIcon",
  "Award01Icon",
  "AwardIcon",
  "Award02Icon",
  "Award03Icon",
  "Award04Icon",
  "Award05Icon",
  "Baby01Icon",
  "BabyIcon",
  "Baby02Icon",
  "BabyBed01Icon",
  "BabyBedIcon",
  "BabyBed02Icon",
  "BabyBottleIcon",
  "BabyBoyDressIcon",
  "BabyGirlDressIcon",
  "BackMuscleBodyIcon",
  "BackgroundIcon",
  "Backpack01Icon",
  "BackpackIcon",
  "Backpack02Icon",
  "Backpack03Icon",
  "Backward01Icon",
  "BackwardIcon",
  "Backward02Icon",
  "BacteriaIcon",
  "BadmintonShuttleIcon",
  "BadmintonIcon",
  "BalanceScaleIcon",
  "BalloonsIcon",
  "BananaIcon",
  "BandageIcon",
  "BankIcon",
  "BarChartHorizontalIcon",
  "BarChartIcon",
  "BarCode01Icon",
  "BarCodeIcon",
  "BarCode02Icon",
  "BarnsIcon",
  "BaseballBatIcon",
  "BaseballHelmetIcon",
  "BaseballIcon",
  "BashIcon",
  "Basketball01Icon",
  "BasketballIcon",
  "Basketball02Icon",
  "BasketballHoopIcon",
  "Bathtub01Icon",
  "BathtubIcon",
  "Bathtub02Icon",
  "BatteriesEnergyIcon",
  "BatteryCharging01Icon",
  "BatteryChargingIcon",
  "BatteryCharging02Icon",
  "BatteryEcoChargingIcon",
  "BatteryEmptyIcon",
  "BatteryFullIcon",
  "BatteryLowIcon",
  "BatteryMedium01Icon",
  "BatteryMediumIcon",
  "BatteryMedium02Icon",
  "BbqGrillIcon",
  "Beach02Icon",
  "BeachIcon",
  "BeaterIcon",
  "BeboIcon",
  "BedBunkIcon",
  "BedDoubleIcon",
  "BedSingle01Icon",
  "BedSingleIcon",
  "BedSingle02Icon",
  "BedIcon",
  "Bedug01Icon",
  "BedugIcon",
  "Bedug02Icon",
  "Behance01Icon",
  "BehanceIcon",
  "Behance02Icon",
  "BeltIcon",
  "BendToolIcon",
  "BerlinIcon",
  "BerlinTowerIcon",
  "BetaIcon",
  "Bicycle01Icon",
  "BicycleIcon",
  "Billiard01Icon",
  "BilliardIcon",
  "Billiard02Icon",
  "BinaryCodeIcon",
  "BingIcon",
  "BinocularsIcon",
  "BioEnergyIcon",
  "BiomassEnergyIcon",
  "BiometricAccessIcon",
  "BiometricDeviceIcon",
  "BirthdayCakeIcon",
  "BiscuitIcon",
  "Bitcoin01Icon",
  "Bitcoin02Icon",
  "Bitcoin03Icon",
  "Bitcoin04Icon",
  "BitcoinBagIcon",
  "BitcoinCircleIcon",
  "BitcoinCloudIcon",
  "BitcoinCpuIcon",
  "BitcoinCreditCardIcon",
  "BitcoinDatabaseIcon",
  "BitcoinDown01Icon",
  "BitcoinDownIcon",
  "BitcoinDown02Icon",
  "BitcoinEllipseIcon",
  "BitcoinEyeIcon",
  "BitcoinFilterIcon",
  "BitcoinFlashdiskIcon",
  "BitcoinGraphIcon",
  "BitcoinInvoiceIcon",
  "BitcoinKeyIcon",
  "BitcoinLocationIcon",
  "BitcoinLockIcon",
  "BitcoinMailIcon",
  "BitcoinMindIcon",
  "BitcoinMoney01Icon",
  "BitcoinMoneyIcon",
  "BitcoinMoney02Icon",
  "BitcoinPieChartIcon",
  "BitcoinPiggyBankIcon",
  "BitcoinPresentationIcon",
  "BitcoinReceiptIcon",
  "BitcoinReceiveIcon",
  "BitcoinRectangleIcon",
  "BitcoinSafeIcon",
  "BitcoinSearchIcon",
  "BitcoinSendIcon",
  "BitcoinSettingIcon",
  "BitcoinShieldIcon",
  "BitcoinShoppingIcon",
  "BitcoinSmartphone01Icon",
  "BitcoinSmartphoneIcon",
  "BitcoinSmartphone02Icon",
  "BitcoinIcon",
  "BitcoinSquareIcon",
  "BitcoinStoreIcon",
  "BitcoinTagIcon",
  "BitcoinTargetIcon",
  "BitcoinTransactionIcon",
  "BitcoinUp01Icon",
  "BitcoinUpIcon",
  "BitcoinUp02Icon",
  "BitcoinWalletIcon",
  "BitcoinWithdrawIcon",
  "BlackHole01Icon",
  "BlackHoleIcon",
  "BlendIcon",
  "BlenderIcon",
  "BlockGameIcon",
  "Blockchain01Icon",
  "BlockchainIcon",
  "Blockchain02Icon",
  "Blockchain03Icon",
  "Blockchain04Icon",
  "Blockchain05Icon",
  "Blockchain06Icon",
  "Blockchain07Icon",
  "BlockedIcon",
  "BloggerIcon",
  "BloodBagIcon",
  "BloodBottleIcon",
  "BloodPressureIcon",
  "BloodIcon",
  "BloodTypeIcon",
  "BlueskyIcon",
  "BluetoothCircleIcon",
  "BluetoothNotConnectedIcon",
  "BluetoothSearchIcon",
  "BluetoothIcon",
  "BluetoothSquareIcon",
  "BlurIcon",
  "BlushBrush01Icon",
  "BlushBrushIcon",
  "BlushBrush02Icon",
  "BoardMathIcon",
  "BoatIcon",
  "BodyArmorIcon",
  "BodyPartLegIcon",
  "BodyPartMuscleIcon",
  "BodyPartSixPackIcon",
  "BodySoapIcon",
  "BodyWeightIcon",
  "BombIcon",
  "Bone01Icon",
  "BoneIcon",
  "Bone02Icon",
  "Book01Icon",
  "BookIcon",
  "Book02Icon",
  "Book03Icon",
  "Book04Icon",
  "BookBookmark01Icon",
  "BookBookmarkIcon",
  "BookBookmark02Icon",
  "BookDownloadIcon",
  "BookEditIcon",
  "BookOpen01Icon",
  "BookOpenIcon",
  "BookOpen02Icon",
  "BookUploadIcon",
  "Bookmark01Icon",
  "BookmarkIcon",
  "Bookmark02Icon",
  "Bookmark03Icon",
  "BookmarkAdd01Icon",
  "BookmarkAddIcon",
  "BookmarkAdd02Icon",
  "BookmarkBlock01Icon",
  "BookmarkBlockIcon",
  "BookmarkBlock02Icon",
  "BookmarkCheck01Icon",
  "BookmarkCheckIcon",
  "BookmarkCheck02Icon",
  "BookmarkMinus01Icon",
  "BookmarkMinusIcon",
  "BookmarkMinus02Icon",
  "BookmarkOff01Icon",
  "BookmarkOffIcon",
  "BookmarkOff02Icon",
  "BookmarkRemove01Icon",
  "BookmarkRemoveIcon",
  "BookmarkRemove02Icon",
  "Books01Icon",
  "BooksIcon",
  "Books02Icon",
  "Bookshelf01Icon",
  "BookshelfIcon",
  "Bookshelf02Icon",
  "Bookshelf03Icon",
  "BootstrapIcon",
  "BorderAll01Icon",
  "BorderAllIcon",
  "BorderAll02Icon",
  "BorderBottom01Icon",
  "BorderBottomIcon",
  "BorderBottom02Icon",
  "BorderFullIcon",
  "BorderHorizontalIcon",
  "BorderInnerIcon",
  "BorderLeft01Icon",
  "BorderLeftIcon",
  "BorderLeft02Icon",
  "BorderNone01Icon",
  "BorderNoneIcon",
  "BorderNone02Icon",
  "BorderRight01Icon",
  "BorderRightIcon",
  "BorderRight02Icon",
  "BorderTop01Icon",
  "BorderTopIcon",
  "BorderTop02Icon",
  "BorderVerticalIcon",
  "BorobudurIcon",
  "BotIcon",
  "BounceLeftIcon",
  "BounceRightIcon",
  "BoundingBoxIcon",
  "BowTieIcon",
  "BowlingBallIcon",
  "BowlingPinsIcon",
  "BowlingIcon",
  "BoxerIcon",
  "BoxingBagIcon",
  "BoxingGlove01Icon",
  "BoxingGloveIcon",
  "Brain01Icon",
  "Brain02Icon",
  "Brain03Icon",
  "BrainIcon",
  "BrandfetchIcon",
  "Bread01Icon",
  "BreadIcon",
  "Bread02Icon",
  "Bread03Icon",
  "Bread04Icon",
  "BreastPumpIcon",
  "BridgeIcon",
  "Briefcase01Icon",
  "BriefcaseIcon",
  "Briefcase02Icon",
  "Briefcase03Icon",
  "Briefcase04Icon",
  "Briefcase05Icon",
  "Briefcase06Icon",
  "Briefcase07Icon",
  "Briefcase08Icon",
  "Briefcase09Icon",
  "BriefcaseDollarIcon",
  "BroccoliIcon",
  "BrochureIcon",
  "BrokenBoneIcon",
  "BrowserIcon",
  "BrushIcon",
  "BubbleChatAddIcon",
  "BubbleChatBlockedIcon",
  "BubbleChatCancelIcon",
  "BubbleChatDelayIcon",
  "BubbleChatDoneIcon",
  "BubbleChatDownload01Icon",
  "BubbleChatDownloadIcon",
  "BubbleChatDownload02Icon",
  "BubbleChatEditIcon",
  "BubbleChatFavouriteIcon",
  "BubbleChatIncomeIcon",
  "BubbleChatLockIcon",
  "BubbleChatNotificationIcon",
  "BubbleChatOutcomeIcon",
  "BubbleChatPreviewIcon",
  "BubbleChatQuestionIcon",
  "BubbleChatSearchIcon",
  "BubbleChatSecureIcon",
  "BubbleChatIcon",
  "BubbleChatTranslateIcon",
  "BubbleChatUnlockIcon",
  "BubbleChatUploadIcon",
  "BubbleChatUserIcon",
  "BubbleTea01Icon",
  "BubbleTeaIcon",
  "BubbleTea02Icon",
  "Bug01Icon",
  "BugIcon",
  "Bug02Icon",
  "Building01Icon",
  "BuildingIcon",
  "Building02Icon",
  "Building03Icon",
  "Building04Icon",
  "Building05Icon",
  "Building06Icon",
  "BulbChargingIcon",
  "BulbIcon",
  "BulletproofVestIcon",
  "BurjAlArabIcon",
  "BurningCdIcon",
  "Bus01Icon",
  "BusIcon",
  "Bus02Icon",
  "Bus03Icon",
  "CIcon",
  "CProgrammingIcon",
  "Cabinet01Icon",
  "CabinetIcon",
  "Cabinet02Icon",
  "Cabinet03Icon",
  "Cabinet04Icon",
  "CableCarIcon",
  "CactusIcon",
  "CaduceusIcon",
  "CafeIcon",
  "CalculateIcon",
  "Calculator01Icon",
  "CalculatorIcon",
  "Calendar01Icon",
  "CalendarIcon",
  "Calendar02Icon",
  "Calendar03Icon",
  "Calendar04Icon",
  "CalendarAdd01Icon",
  "CalendarAddIcon",
  "CalendarAdd02Icon",
  "CalendarBlock01Icon",
  "CalendarBlockIcon",
  "CalendarBlock02Icon",
  "CalendarCheckIn01Icon",
  "CalendarCheckInIcon",
  "CalendarCheckIn02Icon",
  "CalendarCheckOut01Icon",
  "CalendarCheckOutIcon",
  "CalendarCheckOut02Icon",
  "CalendarDownload01Icon",
  "CalendarDownloadIcon",
  "CalendarDownload02Icon",
  "CalendarFavorite01Icon",
  "CalendarFavoriteIcon",
  "CalendarFavorite02Icon",
  "CalendarLock01Icon",
  "CalendarLockIcon",
  "CalendarLock02Icon",
  "CalendarLove01Icon",
  "CalendarLoveIcon",
  "CalendarLove02Icon",
  "CalendarMinus01Icon",
  "CalendarMinusIcon",
  "CalendarMinus02Icon",
  "CalendarRemove01Icon",
  "CalendarRemoveIcon",
  "CalendarRemove02Icon",
  "CalendarSetting01Icon",
  "CalendarSettingIcon",
  "CalendarSetting02Icon",
  "CalendarUpload01Icon",
  "CalendarUploadIcon",
  "CalendarUpload02Icon",
  "Call02Icon",
  "CallAdd02Icon",
  "CallAddIcon",
  "CallBlocked02Icon",
  "CallBlockedIcon",
  "CallDisabled02Icon",
  "CallDisabledIcon",
  "CallDone02Icon",
  "CallDoneIcon",
  "CallEnd01Icon",
  "CallEndIcon",
  "CallEnd02Icon",
  "CallEnd03Icon",
  "CallEnd04Icon",
  "CallIncoming01Icon",
  "CallIncomingIcon",
  "CallIncoming02Icon",
  "CallIncoming03Icon",
  "CallIncoming04Icon",
  "CallInternal02Icon",
  "CallInternalIcon",
  "CallLocked02Icon",
  "CallLockedIcon",
  "CallMinus02Icon",
  "CallMinusIcon",
  "CallMissed01Icon",
  "CallMissedIcon",
  "CallMissed02Icon",
  "CallMissed03Icon",
  "CallMissed04Icon",
  "CallOutgoing01Icon",
  "CallOutgoingIcon",
  "CallOutgoing02Icon",
  "CallOutgoing03Icon",
  "CallOutgoing04Icon",
  "CallPaused02Icon",
  "CallPausedIcon",
  "CallReceived02Icon",
  "CallReceivedIcon",
  "CallRinging01Icon",
  "CallRingingIcon",
  "CallRinging02Icon",
  "CallRinging03Icon",
  "CallRinging04Icon",
  "CallIcon",
  "CallUnlocked02Icon",
  "CallUnlockedIcon",
  "Calling02Icon",
  "CallingIcon",
  "CamelIcon",
  "Camera01Icon",
  "CameraIcon",
  "Camera02Icon",
  "CameraAdd01Icon",
  "CameraAddIcon",
  "CameraAdd02Icon",
  "CameraAutomatically01Icon",
  "CameraAutomaticallyIcon",
  "CameraAutomatically02Icon",
  "CameraLensIcon",
  "CameraMicrophone01Icon",
  "CameraMicrophoneIcon",
  "CameraMicrophone02Icon",
  "CameraNightMode01Icon",
  "CameraNightModeIcon",
  "CameraNightMode02Icon",
  "CameraOff01Icon",
  "CameraOffIcon",
  "CameraOff02Icon",
  "CameraRotated01Icon",
  "CameraRotatedIcon",
  "CameraRotated02Icon",
  "CameraSmile01Icon",
  "CameraSmileIcon",
  "CameraSmile02Icon",
  "CameraTripodIcon",
  "CameraVideoIcon",
  "CamperIcon",
  "CampfireIcon",
  "Cancel01Icon",
  "CancelIcon",
  "Cancel02Icon",
  "CancelCircleHalfDotIcon",
  "CancelCircleIcon",
  "CancelSquareIcon",
  "Candelier01Icon",
  "CandelierIcon",
  "Candelier02Icon",
  "CanvasIcon",
  "CapProjectingIcon",
  "CapRoundIcon",
  "CapIcon",
  "CapStraightIcon",
  "CapcutRectangleIcon",
  "CapcutIcon",
  "Car01Icon",
  "CarIcon",
  "Car02Icon",
  "Car03Icon",
  "Car04Icon",
  "Car05Icon",
  "CarAlertIcon",
  "CarParking01Icon",
  "CarParkingIcon",
  "CarParking02Icon",
  "CarTimeIcon",
  "CaravanIcon",
  "CardExchange01Icon",
  "CardExchangeIcon",
  "CardExchange02Icon",
  "CardiganIcon",
  "Cardiogram01Icon",
  "CardiogramIcon",
  "Cardiogram02Icon",
  "Cards01Icon",
  "CardsIcon",
  "Cards02Icon",
  "CargoShipIcon",
  "CarouselHorizontal02Icon",
  "CarouselHorizontalIcon",
  "CarouselVerticalIcon",
  "CarrotIcon",
  "Cash01Icon",
  "CashIcon",
  "Cash02Icon",
  "CashbackIcon",
  "Cashier02Icon",
  "CashierIcon",
  "Castle01Icon",
  "Castle02Icon",
  "CastleIcon",
  "CatalogueIcon",
  "CayanTowerIcon",
  "CctvCameraIcon",
  "CdIcon",
  "CellsIcon",
  "CellularNetworkOfflineIcon",
  "CellularNetworkIcon",
  "CelsiusIcon",
  "CenterFocusIcon",
  "CentralShaheedMinarIcon",
  "CentralizedIcon",
  "Certificate01Icon",
  "CertificateIcon",
  "Certificate02Icon",
  "Chair01Icon",
  "ChairIcon",
  "Chair02Icon",
  "Chair03Icon",
  "Chair04Icon",
  "Chair05Icon",
  "ChairBarberIcon",
  "ChampionIcon",
  "ChangeScreenModeIcon",
  "CharacterPhoneticIcon",
  "CharityIcon",
  "Chart01Icon",
  "Chart02Icon",
  "Chart03Icon",
  "ChartAverageIcon",
  "ChartBarLineIcon",
  "ChartBreakoutCircleIcon",
  "ChartBreakoutSquareIcon",
  "ChartBubble01Icon",
  "ChartBubbleIcon",
  "ChartBubble02Icon",
  "ChartColumnIcon",
  "ChartDecreaseIcon",
  "ChartDownIcon",
  "ChartEvaluationIcon",
  "ChartHighLowIcon",
  "ChartHistogramIcon",
  "ChartIncreaseIcon",
  "ChartLineData01Icon",
  "ChartLineDataIcon",
  "ChartLineData02Icon",
  "ChartLineData03Icon",
  "ChartMaximumIcon",
  "ChartMediumIcon",
  "ChartMinimumIcon",
  "ChartRadarIcon",
  "ChartRelationshipIcon",
  "ChartRingIcon",
  "ChartRoseIcon",
  "ChartScatterIcon",
  "ChartIcon",
  "ChartUpIcon",
  "ChatBotIcon",
  "ChatGptIcon",
  "Chatting01Icon",
  "ChattingIcon",
  "CheckListIcon",
  "CheckUnread01Icon",
  "CheckUnreadIcon",
  "CheckUnread02Icon",
  "CheckUnread03Icon",
  "CheckUnread04Icon",
  "CheckmarkBadge01Icon",
  "CheckmarkBadgeIcon",
  "CheckmarkBadge02Icon",
  "CheckmarkBadge03Icon",
  "CheckmarkBadge04Icon",
  "CheckmarkCircle01Icon",
  "CheckmarkCircleIcon",
  "CheckmarkCircle02Icon",
  "CheckmarkCircle03Icon",
  "CheckmarkCircle04Icon",
  "CheckmarkSquare01Icon",
  "CheckmarkSquareIcon",
  "CheckmarkSquare02Icon",
  "CheckmarkSquare03Icon",
  "CheckmarkSquare04Icon",
  "CheeseCake01Icon",
  "CheeseCakeIcon",
  "CheeseCake02Icon",
  "CheeseIcon",
  "ChefHatIcon",
  "ChefIcon",
  "Chemistry01Icon",
  "ChemistryIcon",
  "Chemistry02Icon",
  "Chemistry03Icon",
  "CherryIcon",
  "Chess01Icon",
  "ChessIcon",
  "Chess02Icon",
  "ChessPawnIcon",
  "ChickenThighsIcon",
  "ChildIcon",
  "ChimneyIcon",
  "ChinaTempleIcon",
  "Chip02Icon",
  "ChipIcon",
  "ChocolateIcon",
  "ChopsticksIcon",
  "ChromeIcon",
  "ChryslerIcon",
  "ChurchIcon",
  "CinnamonRollIcon",
  "CircleArrowDataTransferDiagonalIcon",
  "CircleArrowDataTransferHorizontalIcon",
  "CircleArrowDataTransferVerticalIcon",
  "CircleArrowDiagonal01Icon",
  "CircleArrowDiagonalIcon",
  "CircleArrowDiagonal02Icon",
  "CircleArrowDown01Icon",
  "CircleArrowDownIcon",
  "CircleArrowDown02Icon",
  "CircleArrowDown03Icon",
  "CircleArrowDownDoubleIcon",
  "CircleArrowDownLeftIcon",
  "CircleArrowDownRightIcon",
  "CircleArrowExpand01Icon",
  "CircleArrowExpandIcon",
  "CircleArrowExpand02Icon",
  "CircleArrowHorizontalIcon",
  "CircleArrowLeft01Icon",
  "CircleArrowLeftIcon",
  "CircleArrowLeft02Icon",
  "CircleArrowLeft03Icon",
  "CircleArrowLeftDoubleIcon",
  "CircleArrowLeftRightIcon",
  "CircleArrowMoveDownLeftIcon",
  "CircleArrowMoveDownRightIcon",
  "CircleArrowMoveLeftDownIcon",
  "CircleArrowMoveRightDownIcon",
  "CircleArrowMoveUpLeftIcon",
  "CircleArrowMoveUpRightIcon",
  "CircleArrowReload01Icon",
  "CircleArrowReloadIcon",
  "CircleArrowReload02Icon",
  "CircleArrowRight01Icon",
  "CircleArrowRightIcon",
  "CircleArrowRight02Icon",
  "CircleArrowRight03Icon",
  "CircleArrowRightDoubleIcon",
  "CircleArrowShrink01Icon",
  "CircleArrowShrinkIcon",
  "CircleArrowShrink02Icon",
  "CircleArrowUp01Icon",
  "CircleArrowUpIcon",
  "CircleArrowUp02Icon",
  "CircleArrowUp03Icon",
  "CircleArrowUpDoubleIcon",
  "CircleArrowUpDownIcon",
  "CircleArrowUpLeftIcon",
  "CircleArrowUpRightIcon",
  "CircleArrowVerticalIcon",
  "CircleLock01Icon",
  "CircleLockIcon",
  "CircleLock02Icon",
  "CircleLockAdd01Icon",
  "CircleLockAddIcon",
  "CircleLockAdd02Icon",
  "CircleLockCheck01Icon",
  "CircleLockCheckIcon",
  "CircleLockCheck02Icon",
  "CircleLockMinus01Icon",
  "CircleLockMinusIcon",
  "CircleLockMinus02Icon",
  "CircleLockRemove01Icon",
  "CircleLockRemoveIcon",
  "CircleLockRemove02Icon",
  "CirclePasswordIcon",
  "CircleIcon",
  "CircleUnlock01Icon",
  "CircleUnlockIcon",
  "CircleUnlock02Icon",
  "City01Icon",
  "CityIcon",
  "City02Icon",
  "City03Icon",
  "Clapping01Icon",
  "ClappingIcon",
  "Clapping02Icon",
  "CleanIcon",
  "CleaningBucketIcon",
  "ClinicIcon",
  "ClipIcon",
  "ClipartsIcon",
  "ClipboardIcon",
  "Clock01Icon",
  "ClockIcon",
  "Clock02Icon",
  "Clock03Icon",
  "Clock04Icon",
  "Clock05Icon",
  "ClosedCaptionAltIcon",
  "ClosedCaptionIcon",
  "ClothesIcon",
  "CloudAngledRainIcon",
  "CloudAngledRainZapIcon",
  "CloudAngledZapIcon",
  "CloudBigRainIcon",
  "CloudDownloadIcon",
  "CloudFastWindIcon",
  "CloudHailstoneIcon",
  "CloudLittleRainIcon",
  "CloudLittleSnowIcon",
  "CloudLoadingIcon",
  "CloudMidRainIcon",
  "CloudMidSnowIcon",
  "CloudSavingDone01Icon",
  "CloudSavingDoneIcon",
  "CloudSavingDone02Icon",
  "CloudServerIcon",
  "CloudSlowWindIcon",
  "CloudSnowIcon",
  "CloudIcon",
  "CloudUploadIcon",
  "Clubs01Icon",
  "ClubsIcon",
  "Clubs02Icon",
  "CoPresentIcon",
  "CodeCircleIcon",
  "CodeFolderIcon",
  "CodeIcon",
  "CodeSquareIcon",
  "CodesandboxIcon",
  "Coffee01Icon",
  "CoffeeIcon",
  "Coffee02Icon",
  "CoffeeBeansIcon",
  "CoinbaseIcon",
  "Coins01Icon",
  "CoinsIcon",
  "Coins02Icon",
  "CoinsBitcoinIcon",
  "CoinsDollarIcon",
  "CoinsEuroIcon",
  "CoinsPoundIcon",
  "CoinsSwapIcon",
  "CoinsYenIcon",
  "CollectionsBookmarkIcon",
  "ColorPickerIcon",
  "ColorsIcon",
  "ColosseumIcon",
  "ColumnDeleteIcon",
  "ColumnInsertIcon",
  "Comet02Icon",
  "ComingSoon01Icon",
  "ComingSoonIcon",
  "ComingSoon02Icon",
  "CommandLineIcon",
  "CommandIcon",
  "Comment01Icon",
  "CommentIcon",
  "Comment02Icon",
  "CommentAdd01Icon",
  "CommentAddIcon",
  "CommentAdd02Icon",
  "CommentBlock01Icon",
  "CommentBlockIcon",
  "CommentBlock02Icon",
  "CommentRemove01Icon",
  "CommentRemoveIcon",
  "CommentRemove02Icon",
  "Compass01Icon",
  "CompassIcon",
  "ComplaintIcon",
  "ComputerAddIcon",
  "ComputerCheckIcon",
  "ComputerCloudIcon",
  "ComputerDesk01Icon",
  "ComputerDeskIcon",
  "ComputerDesk02Icon",
  "ComputerDesk03Icon",
  "ComputerDollarIcon",
  "ComputerPhoneSyncIcon",
  "ComputerProgramming01Icon",
  "ComputerProgrammingIcon",
  "ComputerProgramming02Icon",
  "ComputerProtectionIcon",
  "ComputerRemoveIcon",
  "ComputerSettingsIcon",
  "ComputerIcon",
  "ComputerVideoCallIcon",
  "ComputerVideoIcon",
  "Cone01Icon",
  "ConeIcon",
  "Cone02Icon",
  "ConferenceIcon",
  "Configuration01Icon",
  "ConfigurationIcon",
  "Configuration02Icon",
  "ConfusedIcon",
  "CongruentToCircleIcon",
  "CongruentToIcon",
  "CongruentToSquareIcon",
  "ConnectIcon",
  "ConsoleIcon",
  "ConstellationIcon",
  "Contact01Icon",
  "Contact02Icon",
  "ContactBookIcon",
  "ContactIcon",
  "ContainerTruck01Icon",
  "ContainerTruck02Icon",
  "ContainerTruckIcon",
  "ContentWritingIcon",
  "ContractsIcon",
  "ConversationIcon",
  "CookBookIcon",
  "CookieIcon",
  "Coordinate01Icon",
  "CoordinateIcon",
  "Coordinate02Icon",
  "CopilotIcon",
  "Copy01Icon",
  "CopyIcon",
  "Copy02Icon",
  "CopyLinkIcon",
  "CopyrightIcon",
  "CornIcon",
  "CorporateIcon",
  "CosIcon",
  "Cosine01Icon",
  "CosineIcon",
  "Cosine02Icon",
  "CottageIcon",
  "CottonCandyIcon",
  "Coupon01Icon",
  "CouponIcon",
  "Coupon02Icon",
  "Coupon03Icon",
  "CouponPercentIcon",
  "CourseIcon",
  "CourtHouseIcon",
  "CourtLawIcon",
  "CovariateIcon",
  "CovidInfoIcon",
  "CowboyHatIcon",
  "CpuChargeIcon",
  "CpuSettingsIcon",
  "CpuIcon",
  "CrabIcon",
  "CraneIcon",
  "CrazyIcon",
  "CreativeMarketIcon",
  "CreditCardAcceptIcon",
  "CreditCardAddIcon",
  "CreditCardChangeIcon",
  "CreditCardDefrostIcon",
  "CreditCardFreezeIcon",
  "CreditCardNotAcceptIcon",
  "CreditCardNotFoundIcon",
  "CreditCardPosIcon",
  "CreditCardIcon",
  "CreditCardValidationIcon",
  "CricketBatIcon",
  "CricketHelmetIcon",
  "CropIcon",
  "CrowdfundingIcon",
  "CrownIcon",
  "CryingIcon",
  "Csv01Icon",
  "CsvIcon",
  "Csv02Icon",
  "CubeIcon",
  "Cupcake01Icon",
  "CupcakeIcon",
  "Cupcake02Icon",
  "Cupcake03Icon",
  "CurlingIcon",
  "Cursor01Icon",
  "CursorIcon",
  "Cursor02Icon",
  "CursorAddSelection01Icon",
  "CursorAddSelectionIcon",
  "CursorAddSelection02Icon",
  "CursorCircleSelection01Icon",
  "CursorCircleSelectionIcon",
  "CursorCircleSelection02Icon",
  "CursorDisabled01Icon",
  "CursorDisabledIcon",
  "CursorDisabled02Icon",
  "CursorEdit01Icon",
  "CursorEditIcon",
  "CursorEdit02Icon",
  "CursorHold01Icon",
  "CursorHoldIcon",
  "CursorHold02Icon",
  "CursorInWindowIcon",
  "CursorInfo01Icon",
  "CursorInfoIcon",
  "CursorInfo02Icon",
  "CursorLoading01Icon",
  "CursorLoadingIcon",
  "CursorLoading02Icon",
  "CursorMagicSelection01Icon",
  "CursorMagicSelectionIcon",
  "CursorMagicSelection02Icon",
  "CursorMove01Icon",
  "CursorMoveIcon",
  "CursorMove02Icon",
  "CursorPointer01Icon",
  "CursorPointerIcon",
  "CursorPointer02Icon",
  "CursorProgress01Icon",
  "CursorProgressIcon",
  "CursorProgress02Icon",
  "CursorProgress03Icon",
  "CursorProgress04Icon",
  "CursorRectangleSelection01Icon",
  "CursorRectangleSelectionIcon",
  "CursorRectangleSelection02Icon",
  "CursorRemoveSelection01Icon",
  "CursorRemoveSelectionIcon",
  "CursorRemoveSelection02Icon",
  "CursorTextIcon",
  "CurtainsIcon",
  "CurvyLeftDirectionIcon",
  "CurvyLeftRightDirectionIcon",
  "CurvyRightDirectionIcon",
  "CurvyUpDownDirectionIcon",
  "CustomFieldIcon",
  "CustomerService01Icon",
  "CustomerService02Icon",
  "CustomerServiceIcon",
  "CustomerSupportIcon",
  "CustomizeIcon",
  "Cylinder01Icon",
  "CylinderIcon",
  "Cylinder02Icon",
  "Cylinder03Icon",
  "Cylinder04Icon",
  "DangerIcon",
  "DarkModeIcon",
  "DartIcon",
  "DashboardBrowsingIcon",
  "DashboardCircleAddIcon",
  "DashboardCircleEditIcon",
  "DashboardCircleRemoveIcon",
  "DashboardCircleSettingsIcon",
  "DashboardCircleIcon",
  "DashboardSpeed01Icon",
  "DashboardSpeedIcon",
  "DashboardSpeed02Icon",
  "DashboardSquare01Icon",
  "DashboardSquareIcon",
  "DashboardSquare02Icon",
  "DashboardSquare03Icon",
  "DashboardSquareAddIcon",
  "DashboardSquareEditIcon",
  "DashboardSquareRemoveIcon",
  "DashboardSquareSettingIcon",
  "DashedLine01Icon",
  "DashedLineIcon",
  "DashedLine02Icon",
  "DashedLineCircleIcon",
  "DataRecoveryIcon",
  "Database01Icon",
  "Database02Icon",
  "DatabaseAddIcon",
  "DatabaseExportIcon",
  "DatabaseImportIcon",
  "DatabaseLockedIcon",
  "DatabaseRestoreIcon",
  "DatabaseSettingIcon",
  "DatabaseIcon",
  "DatabaseSync01Icon",
  "DatabaseSyncIcon",
  "DateTimeIcon",
  "DatesIcon",
  "DatevIcon",
  "DeadIcon",
  "Delete01Icon",
  "DeleteIcon",
  "Delete02Icon",
  "Delete03Icon",
  "Delete04Icon",
  "DeleteColumnIcon",
  "DeletePutBackIcon",
  "DeleteRowIcon",
  "DeleteThrowIcon",
  "DeliveredSentIcon",
  "DeliveryBox01Icon",
  "DeliveryBoxIcon",
  "DeliveryBox02Icon",
  "DeliveryDelay01Icon",
  "DeliveryDelayIcon",
  "DeliveryDelay02Icon",
  "DeliveryReturn01Icon",
  "DeliveryReturnIcon",
  "DeliveryReturn02Icon",
  "DeliverySecure01Icon",
  "DeliverySecureIcon",
  "DeliverySecure02Icon",
  "DeliverySent01Icon",
  "DeliverySentIcon",
  "DeliverySent02Icon",
  "DeliveryTracking01Icon",
  "DeliveryTrackingIcon",
  "DeliveryTracking02Icon",
  "DeliveryTruck01Icon",
  "DeliveryTruckIcon",
  "DeliveryTruck02Icon",
  "DeliveryView01Icon",
  "DeliveryViewIcon",
  "DeliveryView02Icon",
  "DentalBracesIcon",
  "DentalBrokenToothIcon",
  "DentalCareIcon",
  "DentalToothIcon",
  "DepartementIcon",
  "DesertIcon",
  "Desk01Icon",
  "Desk02Icon",
  "DeskIcon",
  "DeveloperIcon",
  "DeviantartIcon",
  "DeviceAccessIcon",
  "DiagonalScrollPoint01Icon",
  "DiagonalScrollPointIcon",
  "DiagonalScrollPoint02Icon",
  "DialpadCircle01Icon",
  "DialpadCircleIcon",
  "DialpadCircle02Icon",
  "DialpadSquare01Icon",
  "DialpadSquareIcon",
  "DialpadSquare02Icon",
  "DiameterIcon",
  "Diamond01Icon",
  "Diamond02Icon",
  "DiamondIcon",
  "DiaperIcon",
  "DiceFaces01Icon",
  "DiceFacesIcon",
  "DiceFaces02Icon",
  "DiceFaces03Icon",
  "DiceFaces04Icon",
  "DiceFaces05Icon",
  "DiceFaces06Icon",
  "DiceIcon",
  "DigestionIcon",
  "DiggIcon",
  "DigitalClockIcon",
  "DimSum01Icon",
  "DimSumIcon",
  "DimSum02Icon",
  "DiningTableIcon",
  "DiplomaIcon",
  "DirectionLeft01Icon",
  "DirectionLeftIcon",
  "DirectionLeft02Icon",
  "DirectionRight01Icon",
  "DirectionRightIcon",
  "DirectionRight02Icon",
  "Directions01Icon",
  "DirectionsIcon",
  "Directions02Icon",
  "DirhamIcon",
  "Disability01Icon",
  "DisabilityIcon",
  "Disability02Icon",
  "DiscordIcon",
  "Discount01Icon",
  "DiscountIcon",
  "DiscountTag01Icon",
  "DiscountTagIcon",
  "DiscountTag02Icon",
  "DiscoverCircleIcon",
  "DiscoverSquareIcon",
  "Dish01Icon",
  "DishIcon",
  "Dish02Icon",
  "DishWasherIcon",
  "DispleasedIcon",
  "DistributeHorizontalCenterIcon",
  "DistributeHorizontalLeftIcon",
  "DistributeHorizontalRightIcon",
  "DistributeVerticalBottomIcon",
  "DistributeVerticalCenterIcon",
  "DistributeVerticalTopIcon",
  "DistributionIcon",
  "DivideSignCircleIcon",
  "DivideSignIcon",
  "DivideSignSquareIcon",
  "Dna01Icon",
  "DnaIcon",
  "DoNotTouch01Icon",
  "DoNotTouchIcon",
  "DoNotTouch02Icon",
  "Doc01Icon",
  "DocIcon",
  "Doc02Icon",
  "Doctor01Icon",
  "DoctorIcon",
  "Doctor02Icon",
  "Doctor03Icon",
  "DocumentAttachmentIcon",
  "DocumentCodeIcon",
  "DocumentValidationIcon",
  "Dollar01Icon",
  "DollarIcon",
  "Dollar02Icon",
  "DollarCircleIcon",
  "DollarReceive01Icon",
  "DollarReceiveIcon",
  "DollarReceive02Icon",
  "DollarSend01Icon",
  "DollarSendIcon",
  "DollarSend02Icon",
  "DollarSquareIcon",
  "DomeIcon",
  "DominoIcon",
  "Door01Icon",
  "Door02Icon",
  "DoorLockIcon",
  "DoorIcon",
  "DoughnutIcon",
  "Download01Icon",
  "DownloadIcon",
  "Download02Icon",
  "Download03Icon",
  "Download04Icon",
  "Download05Icon",
  "DownloadCircle01Icon",
  "DownloadCircleIcon",
  "DownloadCircle02Icon",
  "DownloadSquare01Icon",
  "DownloadSquareIcon",
  "DownloadSquare02Icon",
  "Drag01Icon",
  "DragIcon",
  "Drag02Icon",
  "Drag03Icon",
  "Drag04Icon",
  "DragDropHorizontalIcon",
  "DragDropIcon",
  "DragDropVerticalIcon",
  "DragLeft01Icon",
  "DragLeftIcon",
  "DragLeft02Icon",
  "DragLeft03Icon",
  "DragLeft04Icon",
  "DragRight01Icon",
  "DragRightIcon",
  "DragRight02Icon",
  "DragRight03Icon",
  "DragRight04Icon",
  "DrawingCompassIcon",
  "DrawingModeIcon",
  "Dress01Icon",
  "DressIcon",
  "Dress02Icon",
  "Dress03Icon",
  "Dress04Icon",
  "Dress05Icon",
  "Dress06Icon",
  "Dress07Icon",
  "DressingTable01Icon",
  "DressingTableIcon",
  "DressingTable02Icon",
  "DressingTable03Icon",
  "DribbbleIcon",
  "DrinkIcon",
  "DroneIcon",
  "DroolingIcon",
  "DropboxIcon",
  "DropletIcon",
  "DropperIcon",
  "Ds3ToolIcon",
  "DuaIcon",
  "Dumbbell01Icon",
  "DumbbellIcon",
  "Dumbbell02Icon",
  "Dumbbell03Icon",
  "EarRings01Icon",
  "EarRingsIcon",
  "EarRings02Icon",
  "EarRings03Icon",
  "EarIcon",
  "EarthIcon",
  "EaseCurveControlPointsIcon",
  "EaseInControlPointIcon",
  "EaseInOutIcon",
  "EaseInIcon",
  "EaseOutControlPointIcon",
  "EaseOutIcon",
  "EcoEnergyIcon",
  "EcoLab01Icon",
  "EcoLab02Icon",
  "EcoLabIcon",
  "EcoPowerIcon",
  "EdgeStyleIcon",
  "Edit01Icon",
  "EditIcon",
  "Edit02Icon",
  "EditOffIcon",
  "EditRoadIcon",
  "EditTableIcon",
  "EditUser02Icon",
  "EggsIcon",
  "EidMubarakIcon",
  "EiffelTowerIcon",
  "ElearningExchangeIcon",
  "ElectricHome01Icon",
  "ElectricHomeIcon",
  "ElectricHome02Icon",
  "ElectricPlugsIcon",
  "ElectricTower01Icon",
  "ElectricTowerIcon",
  "ElectricTower02Icon",
  "ElectricWireIcon",
  "EllipseSelectionIcon",
  "EncryptIcon",
  "EnergyEllipseIcon",
  "EnergyRectangleIcon",
  "EnergyIcon",
  "EnteringGeoFenceIcon",
  "EntranceStairsIcon",
  "EnvatoIcon",
  "EqualSignCircleIcon",
  "EqualSignIcon",
  "EqualSignSquareIcon",
  "EquipmentBenchPressIcon",
  "EquipmentChestPressIcon",
  "EquipmentGym01Icon",
  "EquipmentGymIcon",
  "EquipmentGym02Icon",
  "EquipmentGym03Icon",
  "EquipmentWeightliftingIcon",
  "Eraser01Icon",
  "EraserAddIcon",
  "EraserAutoIcon",
  "EraserIcon",
  "Estimate01Icon",
  "EstimateIcon",
  "Estimate02Icon",
  "EthereumEllipseIcon",
  "EthereumRectangleIcon",
  "EthereumIcon",
  "EuroCircleIcon",
  "EuroReceiveIcon",
  "EuroSendIcon",
  "EuroIcon",
  "EuroSquareIcon",
  "EvChargingIcon",
  "EvilIcon",
  "Exchange01Icon",
  "ExchangeIcon",
  "Exchange02Icon",
  "Exchange03Icon",
  "ExpanderIcon",
  "ExternalDriveIcon",
  "EyeIcon",
  "FaceIdIcon",
  "Facebook01Icon",
  "FacebookIcon",
  "Facebook02Icon",
  "Factory01Icon",
  "Factory02Icon",
  "FactoryIcon",
  "FahrenheitIcon",
  "FallingStarIcon",
  "FastWindIcon",
  "FavouriteCircleIcon",
  "FavouriteIcon",
  "FavouriteSquareIcon",
  "FeatherIcon",
  "Female02Icon",
  "FemaleSymbolIcon",
  "FencingMaskIcon",
  "FencingIcon",
  "FerrisWheelIcon",
  "FerryBoatIcon",
  "FigmaIcon",
  "File01Icon",
  "FileIcon",
  "File02Icon",
  "FileAddIcon",
  "FileAttachmentIcon",
  "FileAudioIcon",
  "FileBitcoinIcon",
  "FileBlockIcon",
  "FileBookmarkIcon",
  "FileCloudIcon",
  "FileCorruptIcon",
  "FileDollarIcon",
  "FileDownloadIcon",
  "FileEditIcon",
  "FileEmpty01Icon",
  "FileEmptyIcon",
  "FileEmpty02Icon",
  "FileEuroIcon",
  "FileExportIcon",
  "FileFavouriteIcon",
  "FileImportIcon",
  "FileLinkIcon",
  "FileLockedIcon",
  "FileManagementIcon",
  "FileMinusIcon",
  "FileMusicIcon",
  "FileNotFoundIcon",
  "FilePasteIcon",
  "FilePinIcon",
  "FilePoundIcon",
  "FileRemoveIcon",
  "FileScriptIcon",
  "FileSearchIcon",
  "FileSecurityIcon",
  "FileShredderIcon",
  "FileStarIcon",
  "FileSyncIcon",
  "FileUnknownIcon",
  "FileUnlockedIcon",
  "FileUploadIcon",
  "FileValidationIcon",
  "FileVerifiedIcon",
  "FileVideoIcon",
  "FileViewIcon",
  "FileYenIcon",
  "FileZipIcon",
  "Files01Icon",
  "FilesIcon",
  "Files02Icon",
  "Film01Icon",
  "FilmIcon",
  "Film02Icon",
  "FilmRoll01Icon",
  "FilmRollIcon",
  "FilmRoll02Icon",
  "FilterAddIcon",
  "FilterEditIcon",
  "FilterHorizontalIcon",
  "FilterMailCircleIcon",
  "FilterMailSquareIcon",
  "FilterRemoveIcon",
  "FilterResetIcon",
  "FilterIcon",
  "FilterVerticalIcon",
  "FingerAccessIcon",
  "FingerPrintAddIcon",
  "FingerPrintCheckIcon",
  "FingerPrintMinusIcon",
  "FingerPrintRemoveIcon",
  "FingerPrintScanIcon",
  "FingerPrintIcon",
  "FinsIcon",
  "Fire02Icon",
  "Fire03Icon",
  "FirePitIcon",
  "FireSecurityIcon",
  "FireIcon",
  "FirewallIcon",
  "FireworksIcon",
  "FirstAidKitIcon",
  "FishFoodIcon",
  "FitToScreenIcon",
  "FiverrIcon",
  "Flag01Icon",
  "FlagIcon",
  "Flag02Icon",
  "Flag03Icon",
  "FlashOffIcon",
  "FlashIcon",
  "FlashlightIcon",
  "FlaticonIcon",
  "FlickrIcon",
  "FlimSlateIcon",
  "FlipBottomIcon",
  "FlipHorizontalIcon",
  "FlipLeftIcon",
  "FlipPhoneIcon",
  "FlipRightIcon",
  "FlipTopIcon",
  "FlipVerticalIcon",
  "FloorPlanIcon",
  "FloppyDiskIcon",
  "FlowCircleIcon",
  "FlowConnectionIcon",
  "FlowIcon",
  "FlowSquareIcon",
  "Flowchart01Icon",
  "FlowchartIcon",
  "Flowchart02Icon",
  "FlowerPotIcon",
  "FlowerIcon",
  "FlushedIcon",
  "FlyingHumanIcon",
  "FocusPointIcon",
  "Folder01Icon",
  "FolderIcon",
  "Folder02Icon",
  "Folder03Icon",
  "FolderAddIcon",
  "FolderAttachmentIcon",
  "FolderAudioIcon",
  "FolderBlockIcon",
  "FolderCheckIcon",
  "FolderCloudIcon",
  "FolderDetailsReferenceIcon",
  "FolderDetailsIcon",
  "FolderDownloadIcon",
  "FolderEditIcon",
  "FolderExportIcon",
  "FolderFavouriteIcon",
  "FolderFileStorageIcon",
  "FolderImportIcon",
  "FolderLibraryIcon",
  "FolderLinksIcon",
  "FolderLockedIcon",
  "FolderManagementIcon",
  "FolderMinusIcon",
  "FolderMusicIcon",
  "FolderOffIcon",
  "FolderOpenIcon",
  "FolderPinIcon",
  "FolderRemoveIcon",
  "FolderSearchIcon",
  "FolderSecurityIcon",
  "FolderShared01Icon",
  "FolderSharedIcon",
  "FolderShared02Icon",
  "FolderShared03Icon",
  "FolderSyncIcon",
  "FolderTransferIcon",
  "FolderUnknownIcon",
  "FolderUnlockedIcon",
  "FolderUploadIcon",
  "FolderVideoIcon",
  "FolderViewIcon",
  "FolderZipIcon",
  "FoldersIcon",
  "FootballPitchIcon",
  "FootballIcon",
  "ForgotPasswordIcon",
  "ForkIcon",
  "ForrstIcon",
  "FortressIcon",
  "Forward01Icon",
  "ForwardIcon",
  "Forward02Icon",
  "FourFinger02Icon",
  "FourFinger03Icon",
  "FoursquareIcon",
  "FramerIcon",
  "FrenchFries01Icon",
  "FrenchFriesIcon",
  "FrenchFries02Icon",
  "FridgeIcon",
  "FryIcon",
  "Fuel01Icon",
  "FuelIcon",
  "Fuel02Icon",
  "FuelStationIcon",
  "FullScreenIcon",
  "FullSignalIcon",
  "FunctionCircleIcon",
  "FunctionOfXIcon",
  "FunctionIcon",
  "FunctionSquareIcon",
  "GalaxyIcon",
  "GameController01Icon",
  "GameControllerIcon",
  "GameController02Icon",
  "GameController03Icon",
  "GameIcon",
  "GameboyIcon",
  "GarageIcon",
  "GarbageTruckIcon",
  "GarlandsIcon",
  "GasPipeIcon",
  "GasStoveIcon",
  "GearsIcon",
  "GemIcon",
  "GeologyCrustIcon",
  "GeometricShapes01Icon",
  "GeometricShapesIcon",
  "GeometricShapes02Icon",
  "GibbousMoonIcon",
  "Gif01Icon",
  "GifIcon",
  "Gif02Icon",
  "GiftCard02Icon",
  "GiftCardIcon",
  "GiftIcon",
  "GitBranchIcon",
  "GitCommitIcon",
  "GitCompareIcon",
  "GitForkIcon",
  "GitMergeIcon",
  "GitPullRequestClosedIcon",
  "GitPullRequestDraftIcon",
  "GitPullRequestIcon",
  "GitbookIcon",
  "Github01Icon",
  "GithubIcon",
  "GitlabIcon",
  "GiveBloodIcon",
  "GivePillIcon",
  "GlassesIcon",
  "GlobalEditingIcon",
  "GlobalEducationIcon",
  "GlobalRefreshIcon",
  "GlobalSearchIcon",
  "GlobalIcon",
  "Globe02Icon",
  "GlobeIcon",
  "GloveIcon",
  "GoBackward10SecIcon",
  "GoBackward15SecIcon",
  "GoBackward30SecIcon",
  "GoBackward5SecIcon",
  "GoBackward60SecIcon",
  "GoForward10SecIcon",
  "GoForward15SecIcon",
  "GoForward30SecIcon",
  "GoForward5SecIcon",
  "GoForward60SecIcon",
  "GoldIngotsIcon",
  "GoldIcon",
  "GolfBallIcon",
  "GolfBatIcon",
  "GolfCartIcon",
  "GolfHoleIcon",
  "GoogleDocIcon",
  "GoogleDriveIcon",
  "GoogleGeminiIcon",
  "GoogleHomeIcon",
  "GoogleLensIcon",
  "GoogleMapsIcon",
  "GooglePhotosIcon",
  "GoogleSheetIcon",
  "GoogleIcon",
  "Gps01Icon",
  "GpsIcon",
  "Gps02Icon",
  "GpsDisconnectedIcon",
  "GpsOff01Icon",
  "GpsOffIcon",
  "GpsOff02Icon",
  "GpsSignal01Icon",
  "GpsSignalIcon",
  "GpsSignal02Icon",
  "GpuIcon",
  "GraduateFemaleIcon",
  "GraduateMaleIcon",
  "GraduationScrollIcon",
  "GrapesIcon",
  "GravityIcon",
  "GreaterThanCircleIcon",
  "GreaterThanIcon",
  "GreaterThanSquareIcon",
  "GreekHelmetIcon",
  "GreenHouseIcon",
  "Grid02Icon",
  "GridOffIcon",
  "GridIcon",
  "GridTableIcon",
  "GridViewIcon",
  "GrimacingIcon",
  "GrinningIcon",
  "GrokIcon",
  "Group01Icon",
  "GroupIcon",
  "GroupItemsIcon",
  "GroupLayersIcon",
  "GuestHouseIcon",
  "GunIcon",
  "GymnasticRingsIcon",
  "GymnasticIcon",
  "HackerrankIcon",
  "HairClipsIcon",
  "HairDryerIcon",
  "HajiIcon",
  "HalalLabIcon",
  "HalalIcon",
  "Hamburger01Icon",
  "HamburgerIcon",
  "Hamburger02Icon",
  "HandBag01Icon",
  "HandBagIcon",
  "HandBag02Icon",
  "HandBeaterIcon",
  "HandGripIcon",
  "HandPointingDown01Icon",
  "HandPointingDownIcon",
  "HandPointingDown02Icon",
  "HandPointingDown03Icon",
  "HandPointingDown04Icon",
  "HandPointingLeft01Icon",
  "HandPointingLeftIcon",
  "HandPointingLeft02Icon",
  "HandPointingLeft03Icon",
  "HandPointingLeft04Icon",
  "HandPointingRight01Icon",
  "HandPointingRightIcon",
  "HandPointingRight02Icon",
  "HandPointingRight03Icon",
  "HandPointingRight04Icon",
  "HandPrayerIcon",
  "HandSanitizerIcon",
  "HandcuffsIcon",
  "HangerIcon",
  "HangingClockIcon",
  "HangoutIcon",
  "HappyIcon",
  "HardDriveIcon",
  "HatIcon",
  "HddIcon",
  "Hdr01Icon",
  "HdrIcon",
  "Hdr02Icon",
  "Heading01Icon",
  "Heading02Icon",
  "Heading03Icon",
  "Heading04Icon",
  "Heading05Icon",
  "Heading06Icon",
  "HeadingIcon",
  "HeadphoneMuteIcon",
  "HeadphonesIcon",
  "HeadsetConnectedIcon",
  "HeadsetOffIcon",
  "HeadsetIcon",
  "HealtcareIcon",
  "HealthIcon",
  "HeartAddIcon",
  "HeartCheckIcon",
  "HeartRemoveIcon",
  "HeartbreakIcon",
  "HelicopterIcon",
  "HelpCircleIcon",
  "HelpSquareIcon",
  "Hexagon01Icon",
  "HexagonIcon",
  "HierarchyCircle01Icon",
  "HierarchyCircleIcon",
  "HierarchyCircle02Icon",
  "HierarchyCircle03Icon",
  "HierarchyFilesIcon",
  "HierarchyIcon",
  "HierarchySquare01Icon",
  "HierarchySquareIcon",
  "HierarchySquare02Icon",
  "HierarchySquare03Icon",
  "HierarchySquare04Icon",
  "HierarchySquare05Icon",
  "HierarchySquare06Icon",
  "HierarchySquare07Icon",
  "HierarchySquare08Icon",
  "HierarchySquare10Icon",
  "HighHeels01Icon",
  "HighHeelsIcon",
  "HighHeels02Icon",
  "HighlighterIcon",
  "HijabIcon",
  "HockeyIcon",
  "Hold01Icon",
  "HoldIcon",
  "Hold02Icon",
  "Hold03Icon",
  "Hold04Icon",
  "Hold05Icon",
  "HoldLocked01Icon",
  "HoldLockedIcon",
  "HoldLocked02Icon",
  "HoldPhoneIcon",
  "HologramIcon",
  "Home01Icon",
  "HomeIcon",
  "Home02Icon",
  "Home03Icon",
  "Home04Icon",
  "Home05Icon",
  "Home06Icon",
  "Home07Icon",
  "Home08Icon",
  "Home09Icon",
  "Home10Icon",
  "Home11Icon",
  "Home12Icon",
  "Home13Icon",
  "HomeWifiIcon",
  "Honey01Icon",
  "HoneyIcon",
  "Honey02Icon",
  "HonorIcon",
  "HonourStarIcon",
  "HoodieIcon",
  "HorizonalScrollPointIcon",
  "HorizontalResizeIcon",
  "HorseHeadIcon",
  "HorseSaddleIcon",
  "HorseIcon",
  "Hospital01Icon",
  "HospitalIcon",
  "Hospital02Icon",
  "HospitalBed01Icon",
  "HospitalBedIcon",
  "HospitalBed02Icon",
  "HospitalLocationIcon",
  "HotAirBalloonIcon",
  "HotPriceIcon",
  "HotTubeIcon",
  "HotdogIcon",
  "Hotel01Icon",
  "HotelIcon",
  "Hotel02Icon",
  "HotelBellIcon",
  "HotspotOfflineIcon",
  "HotspotIcon",
  "HourglassOffIcon",
  "HourglassIcon",
  "House01Icon",
  "HouseIcon",
  "House02Icon",
  "House03Icon",
  "House04Icon",
  "House05Icon",
  "HouseSolarPanelIcon",
  "Html5Icon",
  "HugeiconsIcon",
  "HumidityIcon",
  "HutIcon",
  "HydroPowerIcon",
  "HyperboleIcon",
  "IceCream01Icon",
  "IceCreamIcon",
  "IceCream02Icon",
  "IceCream03Icon",
  "IceCream04Icon",
  "IceCubesIcon",
  "IceHockeyIcon",
  "IceSkatingIcon",
  "IcoIcon",
  "IconjarIcon",
  "IdNotVerifiedIcon",
  "IdIcon",
  "IdVerifiedIcon",
  "Idea01Icon",
  "IdeaIcon",
  "IdentificationIcon",
  "IdentityCardIcon",
  "Image01Icon",
  "ImageIcon",
  "Image02Icon",
  "Image03Icon",
  "ImageActualSizeIcon",
  "ImageAdd01Icon",
  "ImageAddIcon",
  "ImageAdd02Icon",
  "ImageCompositionOvalIcon",
  "ImageCompositionIcon",
  "ImageCounterClockwiseIcon",
  "ImageCropIcon",
  "ImageDelete01Icon",
  "ImageDeleteIcon",
  "ImageDelete02Icon",
  "ImageDone01Icon",
  "ImageDoneIcon",
  "ImageDone02Icon",
  "ImageDownload02Icon",
  "ImageDownloadIcon",
  "ImageFlipHorizontalIcon",
  "ImageFlipVerticalIcon",
  "ImageNotFound01Icon",
  "ImageNotFoundIcon",
  "ImageNotFound02Icon",
  "ImageRemove01Icon",
  "ImageRemoveIcon",
  "ImageRemove02Icon",
  "ImageRotationClockwiseIcon",
  "ImageUpload01Icon",
  "ImageUploadIcon",
  "ImoIcon",
  "ImportantBookIcon",
  "InLoveIcon",
  "InboxCheckIcon",
  "InboxDownloadIcon",
  "InboxIcon",
  "InboxUnreadIcon",
  "InboxUploadIcon",
  "IncognitoIcon",
  "IndiaGateIcon",
  "Inequality01Icon",
  "InequalityIcon",
  "Inequality02Icon",
  "InequalityCircle01Icon",
  "InequalityCircleIcon",
  "InequalityCircle02Icon",
  "InequalitySquare01Icon",
  "InequalitySquareIcon",
  "InequalitySquare02Icon",
  "InfantIcon",
  "Infinity01Icon",
  "InfinityIcon",
  "Infinity02Icon",
  "InfinityCircleIcon",
  "InfinitySquareIcon",
  "InformationCircleIcon",
  "InformationDiamondIcon",
  "InformationSquareIcon",
  "InjectionIcon",
  "InsertBottomImageIcon",
  "InsertCenterImageIcon",
  "InsertColumnLeftIcon",
  "InsertColumnIcon",
  "InsertPiIcon",
  "InsertRowDownIcon",
  "InsertRowIcon",
  "InsertRowUpIcon",
  "InsertTopImageIcon",
  "InspectCodeIcon",
  "InstagramIcon",
  "InstallingUpdates01Icon",
  "InstallingUpdatesIcon",
  "InstallingUpdates02Icon",
  "InternetAntenna01Icon",
  "InternetAntennaIcon",
  "InternetAntenna02Icon",
  "InternetAntenna03Icon",
  "InternetAntenna04Icon",
  "InternetIcon",
  "InvestigationIcon",
  "Invoice01Icon",
  "Invoice02Icon",
  "Invoice03Icon",
  "Invoice04Icon",
  "InvoiceIcon",
  "IpodIcon",
  "IrisScanIcon",
  "IslandIcon",
  "JarIcon",
  "JavaScriptIcon",
  "JavaIcon",
  "JobLinkIcon",
  "JobSearchIcon",
  "JobShareIcon",
  "JoggerPantsIcon",
  "JoinBevelIcon",
  "JoinRoundIcon",
  "JoinStraightIcon",
  "JokerIcon",
  "Joystick01Icon",
  "JoystickIcon",
  "Joystick02Icon",
  "Joystick03Icon",
  "Joystick04Icon",
  "Joystick05Icon",
  "Jpg01Icon",
  "JpgIcon",
  "Jpg02Icon",
  "Jsx01Icon",
  "JsxIcon",
  "Jsx02Icon",
  "Jsx03Icon",
  "JudgeIcon",
  "JumpersIcon",
  "JupiterIcon",
  "JusticeScale01Icon",
  "JusticeScaleIcon",
  "JusticeScale02Icon",
  "Kaaba01Icon",
  "KaabaIcon",
  "Kaaba02Icon",
  "KanbanIcon",
  "KayakIcon",
  "KeffiyehIcon",
  "Kettle01Icon",
  "KettleIcon",
  "KettlebellIcon",
  "KetupatIcon",
  "Key01Icon",
  "KeyIcon",
  "Key02Icon",
  "KeyGeneratorFobIcon",
  "KeyboardIcon",
  "KeyframeAddIcon",
  "KeyframeAlignCenterIcon",
  "KeyframeAlignHorizontalIcon",
  "KeyframeAlignVerticalIcon",
  "KeyframeBottomIcon",
  "KeyframeLeftIcon",
  "KeyframeRemoveIcon",
  "KeyframeRightIcon",
  "KeyframeIcon",
  "KeyframeTopIcon",
  "KeyframesDoubleAddIcon",
  "KeyframesDoubleRemoveIcon",
  "KeyframesDoubleIcon",
  "KeyframesMultipleAddIcon",
  "KeyframesMultipleRemoveIcon",
  "KeyframesMultipleIcon",
  "Kickstarter01Icon",
  "KickstarterIcon",
  "Kickstarter02Icon",
  "KidIcon",
  "KidneysIcon",
  "KissingIcon",
  "KitchenUtensilsIcon",
  "KiteIcon",
  "KlarnaIcon",
  "Knife01Icon",
  "KnifeIcon",
  "Knife02Icon",
  "KnifeBreadIcon",
  "KnightShieldIcon",
  "KnivesIcon",
  "Knowledge01Icon",
  "KnowledgeIcon",
  "Knowledge02Icon",
  "KoFiIcon",
  "Kurta01Icon",
  "KurtaIcon",
  "LabelImportantIcon",
  "LabelIcon",
  "LaborIcon",
  "LabsIcon",
  "LakeIcon",
  "Lamp01Icon",
  "Lamp02Icon",
  "Lamp03Icon",
  "Lamp04Icon",
  "Lamp05Icon",
  "LampIcon",
  "LanguageCircleIcon",
  "LanguageSkillIcon",
  "LanguageSquareIcon",
  "LanternIcon",
  "LaptopAddIcon",
  "LaptopChargingIcon",
  "LaptopCheckIcon",
  "LaptopCloudIcon",
  "LaptopIssueIcon",
  "LaptopPerformanceIcon",
  "LaptopPhoneSyncIcon",
  "LaptopProgrammingIcon",
  "LaptopRemoveIcon",
  "LaptopSettingsIcon",
  "LaptopIcon",
  "LaptopVideoIcon",
  "LassoTool01Icon",
  "LassoToolIcon",
  "LassoTool02Icon",
  "LastFmIcon",
  "LatitudeIcon",
  "LaughingIcon",
  "LaurelWreath01Icon",
  "LaurelWreathIcon",
  "LaurelWreath02Icon",
  "LaurelWreathFirst01Icon",
  "LaurelWreathFirstIcon",
  "LaurelWreathFirst02Icon",
  "LaurelWreathLeft01Icon",
  "LaurelWreathLeftIcon",
  "LaurelWreathLeft02Icon",
  "LaurelWreathLeft03Icon",
  "LaurelWreathRight01Icon",
  "LaurelWreathRightIcon",
  "LaurelWreathRight02Icon",
  "LaurelWreathRight03Icon",
  "LayerAddIcon",
  "LayerMask01Icon",
  "LayerMaskIcon",
  "LayerMask02Icon",
  "Layers01Icon",
  "LayersIcon",
  "Layers02Icon",
  "LayersLogoIcon",
  "Layout01Icon",
  "LayoutIcon",
  "Layout02Icon",
  "Layout03Icon",
  "Layout04Icon",
  "Layout05Icon",
  "Layout06Icon",
  "Layout07Icon",
  "Layout2ColumnIcon",
  "Layout2RowIcon",
  "Layout3ColumnIcon",
  "Layout3RowIcon",
  "LayoutBottomIcon",
  "LayoutGridIcon",
  "LayoutLeftIcon",
  "LayoutRightIcon",
  "LayoutTable01Icon",
  "LayoutTableIcon",
  "LayoutTable02Icon",
  "LayoutTopIcon",
  "Leaf01Icon",
  "LeafIcon",
  "Leaf02Icon",
  "Leaf03Icon",
  "Leaf04Icon",
  "LeavingGeoFenceIcon",
  "LeetcodeIcon",
  "LeftAngleIcon",
  "LeftToRightBlockQuoteIcon",
  "LeftToRightListBulletIcon",
  "LeftToRightListDashIcon",
  "LeftToRightListNumberIcon",
  "LeftToRightListStar01Icon",
  "LeftToRightListStarIcon",
  "LeftToRightListTriangleIcon",
  "LeftTriangleIcon",
  "Legal01Icon",
  "LegalIcon",
  "Legal02Icon",
  "LegalDocument01Icon",
  "LegalDocumentIcon",
  "LegalDocument02Icon",
  "LegalHammerIcon",
  "LessThanCircleIcon",
  "LessThanIcon",
  "LessThanSquareIcon",
  "LetterSpacingIcon",
  "LibraryIcon",
  "LicenseDraftIcon",
  "LicenseMaintenanceIcon",
  "LicenseNoIcon",
  "LicensePinIcon",
  "LicenseIcon",
  "LicenseThirdPartyIcon",
  "LiftTruckIcon",
  "LighthouseIcon",
  "LimitOrderIcon",
  "LimitationIcon",
  "LineIcon",
  "LinerIcon",
  "Link01Icon",
  "LinkIcon",
  "Link02Icon",
  "Link03Icon",
  "Link04Icon",
  "Link05Icon",
  "Link06Icon",
  "LinkBackwardIcon",
  "LinkCircle02Icon",
  "LinkCircleIcon",
  "LinkForwardIcon",
  "LinkSquare01Icon",
  "LinkSquareIcon",
  "LinkSquare02Icon",
  "Linkedin01Icon",
  "LinkedinIcon",
  "Linkedin02Icon",
  "ListSettingIcon",
  "ListViewIcon",
  "LitecoinIcon",
  "LiveStreaming01Icon",
  "LiveStreamingIcon",
  "LiveStreaming02Icon",
  "LiveStreaming03Icon",
  "LiverIcon",
  "Loading01Icon",
  "LoadingIcon",
  "Loading02Icon",
  "Loading03Icon",
  "Loading04Icon",
  "Location01Icon",
  "LocationIcon",
  "Location02Icon",
  "Location03Icon",
  "Location04Icon",
  "Location05Icon",
  "Location06Icon",
  "Location07Icon",
  "Location08Icon",
  "Location09Icon",
  "Location10Icon",
  "LocationAdd01Icon",
  "LocationAddIcon",
  "LocationAdd02Icon",
  "LocationCheck01Icon",
  "LocationCheckIcon",
  "LocationCheck02Icon",
  "LocationFavourite01Icon",
  "LocationFavouriteIcon",
  "LocationFavourite02Icon",
  "LocationOffline01Icon",
  "LocationOfflineIcon",
  "LocationOffline02Icon",
  "LocationOffline03Icon",
  "LocationOffline04Icon",
  "LocationRemove01Icon",
  "LocationRemoveIcon",
  "LocationRemove02Icon",
  "LocationShare01Icon",
  "LocationShareIcon",
  "LocationShare02Icon",
  "LocationStar01Icon",
  "LocationStarIcon",
  "LocationStar02Icon",
  "LocationUpdate01Icon",
  "LocationUpdateIcon",
  "LocationUpdate02Icon",
  "LocationUser01Icon",
  "LocationUserIcon",
  "LocationUser02Icon",
  "LocationUser03Icon",
  "LocationUser04Icon",
  "LockComputerIcon",
  "LockKeyIcon",
  "LockPasswordIcon",
  "LockIcon",
  "LockSync01Icon",
  "LockSyncIcon",
  "LockSync02Icon",
  "LockedIcon",
  "Locker01Icon",
  "LockerIcon",
  "Login01Icon",
  "LoginIcon",
  "Login02Icon",
  "Login03Icon",
  "LoginCircle01Icon",
  "LoginCircleIcon",
  "LoginCircle02Icon",
  "LoginMethodIcon",
  "LoginSquare01Icon",
  "LoginSquareIcon",
  "LoginSquare02Icon",
  "Logout01Icon",
  "LogoutIcon",
  "Logout02Icon",
  "Logout03Icon",
  "Logout04Icon",
  "Logout05Icon",
  "LogoutCircle01Icon",
  "LogoutCircleIcon",
  "LogoutCircle02Icon",
  "LogoutSquare01Icon",
  "LogoutSquareIcon",
  "LogoutSquare02Icon",
  "LollipopIcon",
  "LongSleeveShirtIcon",
  "LongitudeIcon",
  "LookBottomIcon",
  "LookLeftIcon",
  "LookRightIcon",
  "LookTopIcon",
  "LoomIcon",
  "LottiefilesIcon",
  "LoveKoreanFingerIcon",
  "LowSignalIcon",
  "LoyaltyCardIcon",
  "Luggage01Icon",
  "LuggageIcon",
  "Luggage02Icon",
  "LungsIcon",
  "MachineRobotIcon",
  "MagicWand01Icon",
  "MagicWandIcon",
  "MagicWand02Icon",
  "MagicWand03Icon",
  "MagicWand04Icon",
  "Magnet01Icon",
  "Magnet02Icon",
  "MagnetIcon",
  "Mail01Icon",
  "MailIcon",
  "Mail02Icon",
  "MailAccount01Icon",
  "MailAccountIcon",
  "MailAccount02Icon",
  "MailAdd01Icon",
  "MailAddIcon",
  "MailAdd02Icon",
  "MailAtSign01Icon",
  "MailAtSignIcon",
  "MailAtSign02Icon",
  "MailBlock01Icon",
  "MailBlockIcon",
  "MailBlock02Icon",
  "MailDownload02Icon",
  "MailEdit01Icon",
  "MailEditIcon",
  "MailEdit02Icon",
  "MailLock01Icon",
  "MailLockIcon",
  "MailLock02Icon",
  "MailLove01Icon",
  "MailLoveIcon",
  "MailLove02Icon",
  "MailMinus02Icon",
  "MailOpen02Icon",
  "MailReceive02Icon",
  "MailRemove02Icon",
  "MailReply02Icon",
  "MailReplyAll02Icon",
  "MailSearch01Icon",
  "MailSearchIcon",
  "MailSearch02Icon",
  "MailSecure01Icon",
  "MailSecureIcon",
  "MailSecure02Icon",
  "MailSend02Icon",
  "MailSetting01Icon",
  "MailSettingIcon",
  "MailSetting02Icon",
  "MailUnlock01Icon",
  "MailUnlockIcon",
  "MailUnlock02Icon",
  "MailUpload02Icon",
  "MailValidation01Icon",
  "MailValidationIcon",
  "MailValidation02Icon",
  "MailVoice01Icon",
  "MailVoiceIcon",
  "MailVoice02Icon",
  "Mailbox01Icon",
  "MailboxIcon",
  "Male02Icon",
  "MaleSymbolIcon",
  "ManIcon",
  "ManWomanIcon",
  "ManagerIcon",
  "MapPinIcon",
  "MapPinpoint01Icon",
  "MapPinpointIcon",
  "MapPinpoint02Icon",
  "MapingIcon",
  "MapsCircle01Icon",
  "MapsCircleIcon",
  "MapsCircle02Icon",
  "MapsEditingIcon",
  "MapsGlobal01Icon",
  "MapsGlobalIcon",
  "MapsGlobal02Icon",
  "MapsLocation01Icon",
  "MapsLocationIcon",
  "MapsLocation02Icon",
  "MapsOffIcon",
  "MapsRefreshIcon",
  "MapsSearchIcon",
  "MapsIcon",
  "MapsSquare01Icon",
  "MapsSquareIcon",
  "MapsSquare02Icon",
  "MarketAnalysisIcon",
  "MarketOrderIcon",
  "MarketingIcon",
  "MaskLoveIcon",
  "MaskIcon",
  "MasterCardIcon",
  "MastodonIcon",
  "MatchesIcon",
  "MaterialAndTextureIcon",
  "MathIcon",
  "MatrixIcon",
  "Maximize01Icon",
  "MaximizeIcon",
  "Maximize02Icon",
  "Maximize03Icon",
  "Maximize04Icon",
  "MaximizeScreenIcon",
  "MayanPyramidIcon",
  "MazeIcon",
  "Medal01Icon",
  "MedalIcon",
  "Medal02Icon",
  "Medal03Icon",
  "Medal04Icon",
  "Medal05Icon",
  "Medal06Icon",
  "Medal07Icon",
  "MedalFirstPlaceIcon",
  "MedalSecondPlaceIcon",
  "MedalThirdPlaceIcon",
  "MedicalFileIcon",
  "MedicalMaskIcon",
  "Medicine01Icon",
  "MedicineIcon",
  "Medicine02Icon",
  "MedicineBottle01Icon",
  "MedicineBottleIcon",
  "MedicineBottle02Icon",
  "MedicineSyrupIcon",
  "MediumSignalIcon",
  "MediumIcon",
  "MediumSquareIcon",
  "MeetingRoomIcon",
  "Megaphone01Icon",
  "MegaphoneIcon",
  "Megaphone02Icon",
  "Megaphone03Icon",
  "MehIcon",
  "MentorIcon",
  "MentoringIcon",
  "Menu01Icon",
  "MenuIcon",
  "Menu02Icon",
  "Menu03Icon",
  "Menu04Icon",
  "Menu05Icon",
  "Menu06Icon",
  "Menu07Icon",
  "Menu08Icon",
  "Menu09Icon",
  "Menu10Icon",
  "Menu11Icon",
  "MenuCircleIcon",
  "MenuCollapseIcon",
  "MenuRestaurantIcon",
  "MenuSquareIcon",
  "MenuTwoLineIcon",
  "Message01Icon",
  "MessageIcon",
  "Message02Icon",
  "MessageAdd02Icon",
  "MessageBlockedIcon",
  "MessageCancel01Icon",
  "MessageCancelIcon",
  "MessageCancel02Icon",
  "MessageDelay01Icon",
  "MessageDelayIcon",
  "MessageDelay02Icon",
  "MessageDone01Icon",
  "MessageDoneIcon",
  "MessageDone02Icon",
  "MessageDownload01Icon",
  "MessageDownloadIcon",
  "MessageDownload02Icon",
  "MessageEdit01Icon",
  "MessageEditIcon",
  "MessageEdit02Icon",
  "MessageFavourite01Icon",
  "MessageFavouriteIcon",
  "MessageFavourite02Icon",
  "MessageIncoming01Icon",
  "MessageIncomingIcon",
  "MessageIncoming02Icon",
  "MessageLock01Icon",
  "MessageLockIcon",
  "MessageLock02Icon",
  "MessageMultiple01Icon",
  "MessageMultipleIcon",
  "MessageMultiple02Icon",
  "MessageNotification01Icon",
  "MessageNotificationIcon",
  "MessageNotification02Icon",
  "MessageOutgoing01Icon",
  "MessageOutgoingIcon",
  "MessageOutgoing02Icon",
  "MessagePreview01Icon",
  "MessagePreviewIcon",
  "MessagePreview02Icon",
  "MessageProgrammingIcon",
  "MessageQuestionIcon",
  "MessageSearch02Icon",
  "MessageSecure01Icon",
  "MessageSecureIcon",
  "MessageSecure02Icon",
  "MessageTranslateIcon",
  "MessageUnlock01Icon",
  "MessageUnlockIcon",
  "MessageUnlock02Icon",
  "MessageUpload01Icon",
  "MessageUploadIcon",
  "MessageUpload02Icon",
  "MessageUser01Icon",
  "MessageUserIcon",
  "MessageUser02Icon",
  "MessengerIcon",
  "MetaIcon",
  "MetroIcon",
  "Mic01Icon",
  "MicIcon",
  "Mic02Icon",
  "MicOff01Icon",
  "MicOffIcon",
  "MicOff02Icon",
  "MicroscopeIcon",
  "MicrosoftAdminIcon",
  "MicrosoftIcon",
  "MicrowaveIcon",
  "MilkBottleIcon",
  "MilkCartonIcon",
  "MilkCoconutIcon",
  "MilkOatIcon",
  "Minimize01Icon",
  "MinimizeIcon",
  "Minimize02Icon",
  "Minimize03Icon",
  "Minimize04Icon",
  "MinimizeScreenIcon",
  "Mining01Icon",
  "MiningIcon",
  "Mining02Icon",
  "Mining03Icon",
  "MinusPlus01Icon",
  "MinusPlusIcon",
  "MinusPlus02Icon",
  "MinusPlusCircle01Icon",
  "MinusPlusCircleIcon",
  "MinusPlusCircle02Icon",
  "MinusPlusSquare01Icon",
  "MinusPlusSquareIcon",
  "MinusPlusSquare02Icon",
  "MinusSignCircleIcon",
  "MinusSignIcon",
  "MinusSignSquareIcon",
  "MirrorIcon",
  "MirroringScreenIcon",
  "MixerIcon",
  "MobileNavigator01Icon",
  "MobileNavigatorIcon",
  "MobileNavigator02Icon",
  "MobileProgramming01Icon",
  "MobileProgrammingIcon",
  "MobileProgramming02Icon",
  "MobileProtectionIcon",
  "MobileSecurityIcon",
  "MochiIcon",
  "ModernTvFourKIcon",
  "ModernTvIssueIcon",
  "ModernTvIcon",
  "MoleculesIcon",
  "MollieIcon",
  "MonasIcon",
  "Money01Icon",
  "MoneyIcon",
  "Money02Icon",
  "Money03Icon",
  "Money04Icon",
  "MoneyAdd01Icon",
  "MoneyAddIcon",
  "MoneyAdd02Icon",
  "MoneyBag01Icon",
  "MoneyBagIcon",
  "MoneyBag02Icon",
  "MoneyExchange01Icon",
  "MoneyExchangeIcon",
  "MoneyExchange02Icon",
  "MoneyExchange03Icon",
  "MoneyNotFound01Icon",
  "MoneyNotFoundIcon",
  "MoneyNotFound02Icon",
  "MoneyNotFound03Icon",
  "MoneyNotFound04Icon",
  "MoneyReceive01Icon",
  "MoneyReceiveIcon",
  "MoneyReceive02Icon",
  "MoneyReceiveCircleIcon",
  "MoneyReceiveFlow01Icon",
  "MoneyReceiveFlowIcon",
  "MoneyReceiveFlow02Icon",
  "MoneyReceiveSquareIcon",
  "MoneyRemove01Icon",
  "MoneyRemoveIcon",
  "MoneyRemove02Icon",
  "MoneySafeIcon",
  "MoneySavingJarIcon",
  "MoneySecurityIcon",
  "MoneySend01Icon",
  "MoneySendIcon",
  "MoneySend02Icon",
  "MoneySendCircleIcon",
  "MoneySendFlow01Icon",
  "MoneySendFlowIcon",
  "MoneySendFlow02Icon",
  "MoneySendSquareIcon",
  "Monocle01Icon",
  "MonocleIcon",
  "MonsterIcon",
  "Moon01Icon",
  "Moon02Icon",
  "MoonAngledRainZapIcon",
  "MoonCloudAngledRainIcon",
  "MoonCloudAngledZapIcon",
  "MoonCloudBigRainIcon",
  "MoonCloudFastWindIcon",
  "MoonCloudHailstoneIcon",
  "MoonCloudLittleRainIcon",
  "MoonCloudLittleSnowIcon",
  "MoonCloudMidRainIcon",
  "MoonCloudMidSnowIcon",
  "MoonCloudSlowWindIcon",
  "MoonCloudSnowIcon",
  "MoonCloudIcon",
  "MoonEclipseIcon",
  "MoonFastWindIcon",
  "MoonLandingIcon",
  "MoonSlowWindIcon",
  "MoonIcon",
  "MoonsetIcon",
  "More01Icon",
  "More02Icon",
  "More03Icon",
  "MoreHorizontalCircle01Icon",
  "MoreHorizontalCircleIcon",
  "MoreHorizontalCircle02Icon",
  "MoreHorizontalIcon",
  "MoreHorizontalSquare01Icon",
  "MoreHorizontalSquareIcon",
  "MoreHorizontalSquare02Icon",
  "MoreOrLessCircleIcon",
  "MoreOrLessIcon",
  "MoreOrLessSquareIcon",
  "MoreIcon",
  "MoreVerticalCircle01Icon",
  "MoreVerticalCircleIcon",
  "MoreVerticalCircle02Icon",
  "MoreVerticalIcon",
  "MoreVerticalSquare01Icon",
  "MoreVerticalSquareIcon",
  "MoreVerticalSquare02Icon",
  "MortarIcon",
  "Mortarboard01Icon",
  "MortarboardIcon",
  "Mortarboard02Icon",
  "Mosque01Icon",
  "MosqueIcon",
  "Mosque02Icon",
  "Mosque03Icon",
  "Mosque04Icon",
  "Mosque05Icon",
  "MosqueLocationIcon",
  "Motion01Icon",
  "MotionIcon",
  "Motion02Icon",
  "Motorbike01Icon",
  "MotorbikeIcon",
  "Motorbike02Icon",
  "MountainIcon",
  "Mouse01Icon",
  "MouseIcon",
  "Mouse02Icon",
  "Mouse03Icon",
  "Mouse04Icon",
  "Mouse05Icon",
  "Mouse06Icon",
  "Mouse07Icon",
  "Mouse08Icon",
  "Mouse09Icon",
  "Mouse10Icon",
  "Mouse11Icon",
  "Mouse12Icon",
  "Mouse13Icon",
  "Mouse14Icon",
  "Mouse15Icon",
  "Mouse16Icon",
  "Mouse17Icon",
  "Mouse18Icon",
  "Mouse19Icon",
  "Mouse20Icon",
  "Mouse21Icon",
  "Mouse22Icon",
  "Mouse23Icon",
  "MouseLeftClick01Icon",
  "MouseLeftClickIcon",
  "MouseLeftClick02Icon",
  "MouseLeftClick03Icon",
  "MouseLeftClick04Icon",
  "MouseLeftClick05Icon",
  "MouseLeftClick06Icon",
  "MouseRightClick01Icon",
  "MouseRightClickIcon",
  "MouseRightClick02Icon",
  "MouseRightClick03Icon",
  "MouseRightClick04Icon",
  "MouseRightClick05Icon",
  "MouseRightClick06Icon",
  "MouseScroll01Icon",
  "MouseScrollIcon",
  "MouseScroll02Icon",
  "Move01Icon",
  "Move02Icon",
  "MoveBottomIcon",
  "MoveLeftIcon",
  "MoveRightIcon",
  "MoveIcon",
  "MoveToIcon",
  "MoveTopIcon",
  "Mp301Icon",
  "Mp3Icon",
  "Mp302Icon",
  "Mp401Icon",
  "Mp4Icon",
  "Mp402Icon",
  "MuhammadIcon",
  "MultiplicationSignCircleIcon",
  "MultiplicationSignIcon",
  "MultiplicationSignSquareIcon",
  "Mushroom01Icon",
  "MushroomIcon",
  "MusicNote01Icon",
  "MusicNoteIcon",
  "MusicNote02Icon",
  "MusicNote03Icon",
  "MusicNote04Icon",
  "MusicNoteSquare01Icon",
  "MusicNoteSquareIcon",
  "MusicNoteSquare02Icon",
  "MuslimIcon",
  "MuteIcon",
  "MymindIcon",
  "NThRootCircleIcon",
  "NThRootIcon",
  "NThRootSquareIcon",
  "NanoTechnologyIcon",
  "Napkins01Icon",
  "NapkinsIcon",
  "Napkins02Icon",
  "NaturalFoodIcon",
  "Navigation01Icon",
  "NavigationIcon",
  "Navigation02Icon",
  "Navigation03Icon",
  "Navigation04Icon",
  "Navigation05Icon",
  "Navigator01Icon",
  "NavigatorIcon",
  "Navigator02Icon",
  "NecklaceIcon",
  "NerdIcon",
  "NeuralNetworkIcon",
  "NeutralIcon",
  "NewJobIcon",
  "NewOfficeIcon",
  "NewReleasesIcon",
  "NewTwitterEllipseIcon",
  "NewTwitterRectangleIcon",
  "NewTwitterIcon",
  "News01Icon",
  "NewsIcon",
  "NextIcon",
  "NikeIcon",
  "NintendoIcon",
  "NintendoSwitchIcon",
  "NiqabIcon",
  "NoInternetIcon",
  "NoMeetingRoomIcon",
  "NoSignalIcon",
  "NodeAddIcon",
  "NodeEditIcon",
  "NodeMoveDownIcon",
  "NodeMoveUpIcon",
  "NodeRemoveIcon",
  "NoodlesIcon",
  "NoseIcon",
  "NotEqualSignCircleIcon",
  "NotEqualSignIcon",
  "NotEqualSignSquareIcon",
  "Note01Icon",
  "Note02Icon",
  "Note03Icon",
  "Note04Icon",
  "Note05Icon",
  "NoteAddIcon",
  "NoteDoneIcon",
  "NoteEditIcon",
  "NoteRemoveIcon",
  "NoteIcon",
  "Notebook01Icon",
  "Notebook02Icon",
  "NotebookIcon",
  "Notification01Icon",
  "NotificationIcon",
  "Notification02Icon",
  "Notification03Icon",
  "NotificationBlock01Icon",
  "NotificationBlockIcon",
  "NotificationBlock02Icon",
  "NotificationBlock03Icon",
  "NotificationBubbleIcon",
  "NotificationCircleIcon",
  "NotificationOff01Icon",
  "NotificationOffIcon",
  "NotificationOff02Icon",
  "NotificationOff03Icon",
  "NotificationSnooze01Icon",
  "NotificationSnoozeIcon",
  "NotificationSnooze02Icon",
  "NotificationSnooze03Icon",
  "NotificationSquareIcon",
  "Notion01Icon",
  "NotionIcon",
  "Notion02Icon",
  "NpmIcon",
  "NuclearPowerIcon",
  "NutIcon",
  "ObtuseIcon",
  "OctagonIcon",
  "OctopusIcon",
  "Office365Icon",
  "OfficeChairIcon",
  "OfficeIcon",
  "OilBarrelIcon",
  "OkFingerIcon",
  "OlympicTorchIcon",
  "OnlineLearning01Icon",
  "OnlineLearningIcon",
  "OnlineLearning02Icon",
  "OnlineLearning03Icon",
  "OnlineLearning04Icon",
  "OpenCaptionIcon",
  "OptionIcon",
  "OrangeIcon",
  "Orbit01Icon",
  "OrbitIcon",
  "Orbit02Icon",
  "OrganicFoodIcon",
  "OrthogonalEdgeIcon",
  "OvalIcon",
  "OvenIcon",
  "Package02Icon",
  "Package03Icon",
  "PackageAddIcon",
  "PackageDeliveredIcon",
  "PackageDimensions01Icon",
  "PackageDimensionsIcon",
  "PackageDimensions02Icon",
  "PackageMovingIcon",
  "PackageOpenIcon",
  "PackageOutOfStockIcon",
  "PackageProcessIcon",
  "PackageReceiveIcon",
  "PackageRemoveIcon",
  "PackageSearchIcon",
  "PackageSentIcon",
  "PackageIcon",
  "PackagingIcon",
  "Pacman01Icon",
  "PacmanIcon",
  "Pacman02Icon",
  "PaellaIcon",
  "PaintBoardIcon",
  "PaintBrush01Icon",
  "PaintBrushIcon",
  "PaintBrush02Icon",
  "PaintBrush03Icon",
  "PaintBrush04Icon",
  "PaintBucketIcon",
  "Pan01Icon",
  "PanIcon",
  "Pan02Icon",
  "Pan03Icon",
  "Parabola01Icon",
  "ParabolaIcon",
  "Parabola02Icon",
  "Parabola03Icon",
  "ParaglidingIcon",
  "ParagraphBulletsPoint01Icon",
  "ParagraphBulletsPointIcon",
  "ParagraphBulletsPoint02Icon",
  "ParagraphIcon",
  "ParagraphSpacingIcon",
  "ParallelogramIcon",
  "ParkingAreaCircleIcon",
  "ParkingAreaSquareIcon",
  "PartyIcon",
  "Passport01Icon",
  "PassportExpiredIcon",
  "PassportIcon",
  "PassportValidIcon",
  "PasswordValidationIcon",
  "PathIcon",
  "PathfinderCropIcon",
  "PathfinderDivideIcon",
  "PathfinderExcludeIcon",
  "PathfinderIntersectIcon",
  "PathfinderMergeIcon",
  "PathfinderMinusBackIcon",
  "PathfinderMinusFrontIcon",
  "PathfinderOutlineIcon",
  "PathfinderTrimIcon",
  "PathfinderUniteIcon",
  "PatientIcon",
  "PatioIcon",
  "PauseCircleIcon",
  "PauseIcon",
  "PavilonIcon",
  "PayByCheckIcon",
  "Payment01Icon",
  "PaymentIcon",
  "Payment02Icon",
  "PaymentSuccess01Icon",
  "PaymentSuccessIcon",
  "PaymentSuccess02Icon",
  "PayoneerIcon",
  "PaypalIcon",
  "Pdf01Icon",
  "PdfIcon",
  "Pdf02Icon",
  "PeerToPeer01Icon",
  "PeerToPeerIcon",
  "PeerToPeer02Icon",
  "PeerToPeer03Icon",
  "Pen01Icon",
  "PenIcon",
  "Pen02Icon",
  "PenConnectBluetoothIcon",
  "PenConnectUsbIcon",
  "PenConnectWifiIcon",
  "PenTool01Icon",
  "PenToolIcon",
  "PenTool02Icon",
  "PenTool03Icon",
  "PenToolAddIcon",
  "PenToolMinusIcon",
  "PencilEdit01Icon",
  "PencilEditIcon",
  "PencilEdit02Icon",
  "PencilIcon",
  "PendulumIcon",
  "PensiveIcon",
  "Pentagon01Icon",
  "PentagonIcon",
  "PercentCircleIcon",
  "PercentIcon",
  "PercentSquareIcon",
  "PerfumeIcon",
  "PeriscopeIcon",
  "PermanentJobIcon",
  "PerplexityAiIcon",
  "PerspectiveIcon",
  "PetrolPumpIcon",
  "PexelsIcon",
  "PhoneOff01Icon",
  "PhoneOffIcon",
  "PhoneOff02Icon",
  "PhpIcon",
  "PhysicsIcon",
  "PiCircleIcon",
  "PiIcon",
  "PiSquareIcon",
  "PicasaIcon",
  "Pickup01Icon",
  "PickupIcon",
  "Pickup02Icon",
  "PictureInPictureExitIcon",
  "PictureInPictureOnIcon",
  "PieChart01Icon",
  "PieChart02Icon",
  "PieChart03Icon",
  "PieChart04Icon",
  "PieChart05Icon",
  "PieChart06Icon",
  "PieChart07Icon",
  "PieChart08Icon",
  "PieChart09Icon",
  "PieChartIcon",
  "PieChartSquareIcon",
  "PieIcon",
  "PiggyBankIcon",
  "Pin02Icon",
  "PinCodeIcon",
  "PinLocation01Icon",
  "PinLocationIcon",
  "PinLocation02Icon",
  "PinLocation03Icon",
  "PinOffIcon",
  "PinIcon",
  "PineTreeIcon",
  "PinterestIcon",
  "PipelineIcon",
  "PisaTowerIcon",
  "PivotIcon",
  "Pizza01Icon",
  "PizzaIcon",
  "Pizza02Icon",
  "Pizza03Icon",
  "Pizza04Icon",
  "PizzaCutterIcon",
  "PlaneIcon",
  "Plant01Icon",
  "PlantIcon",
  "Plant02Icon",
  "Plant03Icon",
  "Plant04Icon",
  "PlateIcon",
  "PlaxoIcon",
  "PlayCircle02Icon",
  "PlayCircleIcon",
  "PlayListAddIcon",
  "PlayListFavourite01Icon",
  "PlayListFavouriteIcon",
  "PlayListFavourite02Icon",
  "PlayListMinusIcon",
  "PlayListRemoveIcon",
  "PlayListIcon",
  "PlayIcon",
  "PlaySquareIcon",
  "PlayStoreIcon",
  "Playlist01Icon",
  "PlaylistIcon",
  "Playlist02Icon",
  "Playlist03Icon",
  "PlazaIcon",
  "Plug01Icon",
  "PlugIcon",
  "Plug02Icon",
  "PlugSocketIcon",
  "PlusMinus01Icon",
  "PlusMinus02Icon",
  "PlusMinusCircle01Icon",
  "PlusMinusCircleIcon",
  "PlusMinusCircle02Icon",
  "PlusMinusIcon",
  "PlusMinusSquare01Icon",
  "PlusMinusSquareIcon",
  "PlusMinusSquare02Icon",
  "PlusSignCircleIcon",
  "PlusSignIcon",
  "PlusSignSquareIcon",
  "Png01Icon",
  "PngIcon",
  "Png02Icon",
  "PodcastIcon",
  "PodiumIcon",
  "PointingLeft01Icon",
  "PointingLeftIcon",
  "PointingLeft02Icon",
  "PointingLeft03Icon",
  "PointingLeft04Icon",
  "PointingLeft05Icon",
  "PointingLeft06Icon",
  "PointingLeft07Icon",
  "PointingLeft08Icon",
  "PointingRight01Icon",
  "PointingRightIcon",
  "PointingRight02Icon",
  "PointingRight03Icon",
  "PointingRight04Icon",
  "PointingRight05Icon",
  "PointingRight06Icon",
  "PointingRight07Icon",
  "PointingRight08Icon",
  "PokeballIcon",
  "PokemonIcon",
  "PoliceBadgeIcon",
  "PoliceCapIcon",
  "PoliceCarIcon",
  "PoliceStationIcon",
  "PolicyIcon",
  "PolyTankIcon",
  "PolygonIcon",
  "PoolIcon",
  "PoolTableIcon",
  "PoopIcon",
  "PopcornIcon",
  "Pot01Icon",
  "PotIcon",
  "Pot02Icon",
  "PotionIcon",
  "PoundCircleIcon",
  "PoundReceiveIcon",
  "PoundSendIcon",
  "PoundIcon",
  "PoundSquareIcon",
  "PowerServiceIcon",
  "PowerSocket01Icon",
  "PowerSocketIcon",
  "PowerSocket02Icon",
  "Ppt01Icon",
  "PptIcon",
  "Ppt02Icon",
  "PrawnIcon",
  "PrayerRug01Icon",
  "PrayerRugIcon",
  "PrayerRug02Icon",
  "PreferenceHorizontalIcon",
  "PreferenceVerticalIcon",
  "PrescriptionIcon",
  "Presentation01Icon",
  "PresentationIcon",
  "Presentation02Icon",
  "Presentation03Icon",
  "Presentation04Icon",
  "Presentation05Icon",
  "Presentation06Icon",
  "Presentation07Icon",
  "PresentationBarChart01Icon",
  "PresentationBarChartIcon",
  "PresentationBarChart02Icon",
  "PresentationLineChart01Icon",
  "PresentationLineChartIcon",
  "PresentationLineChart02Icon",
  "PresentationOnlineIcon",
  "PresentationPodiumIcon",
  "PreviousIcon",
  "PrinterOffIcon",
  "PrinterIcon",
  "Prism01Icon",
  "PrismIcon",
  "PrisonGuardIcon",
  "PrisonIcon",
  "PrisonerIcon",
  "ProductLoadingIcon",
  "Profile02Icon",
  "ProfileIcon",
  "ProfitIcon",
  "ProgrammingFlagIcon",
  "Progress01Icon",
  "Progress02Icon",
  "Progress03Icon",
  "Progress04Icon",
  "ProgressIcon",
  "Projector01Icon",
  "ProjectorIcon",
  "PromotionIcon",
  "PropertyAddIcon",
  "PropertyDeleteIcon",
  "PropertyEditIcon",
  "PropertyNewIcon",
  "PropertySearchIcon",
  "PropertyViewIcon",
  "ProtectionMaskIcon",
  "PulleyIcon",
  "Pulse01Icon",
  "PulseIcon",
  "Pulse02Icon",
  "PulseRectangle01Icon",
  "PulseRectangleIcon",
  "PulseRectangle02Icon",
  "PumpkinIcon",
  "PunchIcon",
  "PunchingBall01Icon",
  "PunchingBallIcon",
  "PunchingBall02Icon",
  "Purse01Icon",
  "PurseIcon",
  "PushUpBarIcon",
  "PuzzleIcon",
  "PyramidMaslowoIcon",
  "PyramidIcon",
  "PyramidStructure01Icon",
  "PyramidStructureIcon",
  "PyramidStructure02Icon",
  "PythonIcon",
  "QqPlotIcon",
  "QrCode01Icon",
  "QrCodeIcon",
  "QuestionIcon",
  "Queue01Icon",
  "QueueIcon",
  "Queue02Icon",
  "QuillWrite01Icon",
  "QuillWriteIcon",
  "QuillWrite02Icon",
  "Quiz01Icon",
  "QuizIcon",
  "Quiz02Icon",
  "Quiz03Icon",
  "Quiz04Icon",
  "Quiz05Icon",
  "QuoraIcon",
  "QuoteDownCircleIcon",
  "QuoteDownIcon",
  "QuoteDownSquareIcon",
  "QuoteUpCircleIcon",
  "QuoteUpIcon",
  "QuoteUpSquareIcon",
  "QuotesIcon",
  "Quran01Icon",
  "QuranIcon",
  "Quran02Icon",
  "Quran03Icon",
  "RacingFlagIcon",
  "Radar01Icon",
  "RadarIcon",
  "Radar02Icon",
  "RadialIcon",
  "Radio01Icon",
  "Radio02Icon",
  "RadioButtonIcon",
  "RadioIcon",
  "RadioactiveAlertIcon",
  "RadiusIcon",
  "RainDoubleDropIcon",
  "RainDropIcon",
  "RainIcon",
  "RainbowIcon",
  "Ramadhan01Icon",
  "RamadhanIcon",
  "Ramadhan02Icon",
  "RamadhanMonthIcon",
  "RankingIcon",
  "Rar01Icon",
  "RarIcon",
  "Rar02Icon",
  "Raw01Icon",
  "RawIcon",
  "Raw02Icon",
  "ReIcon",
  "ReactIcon",
  "RealEstate01Icon",
  "RealEstateIcon",
  "RealEstate02Icon",
  "ReceiptDollarIcon",
  "RecordIcon",
  "RecoveryMailIcon",
  "Rectangular01Icon",
  "RectangularIcon",
  "Recycle01Icon",
  "RecycleIcon",
  "Recycle02Icon",
  "Recycle03Icon",
  "RedditIcon",
  "Redo02Icon",
  "Redo03Icon",
  "RedoIcon",
  "ReflexIcon",
  "RefreshIcon",
  "RefrigeratorIcon",
  "RegisterIcon",
  "RegisteredIcon",
  "Relieved01Icon",
  "RelievedIcon",
  "Relieved02Icon",
  "ReloadIcon",
  "ReminderIcon",
  "RemoteControlIcon",
  "Remove01Icon",
  "RemoveIcon",
  "Remove02Icon",
  "RemoveCircleHalfDotIcon",
  "RemoveCircleIcon",
  "RemoveFemaleIcon",
  "RemoveMaleIcon",
  "RemovePiIcon",
  "RemoveSquareIcon",
  "RenewableEnergy01Icon",
  "RenewableEnergyIcon",
  "RepairIcon",
  "RepeatOffIcon",
  "RepeatOne01Icon",
  "RepeatOneIcon",
  "RepeatOne02Icon",
  "RepeatIcon",
  "ReplayIcon",
  "RepositoryIcon",
  "ResetPasswordIcon",
  "Resize01Icon",
  "ResizeIcon",
  "Resize02Icon",
  "ResizeFieldRectangleIcon",
  "ResizeFieldIcon",
  "ResourcesAddIcon",
  "ResourcesRemoveIcon",
  "Restaurant01Icon",
  "RestaurantIcon",
  "Restaurant02Icon",
  "Restaurant03Icon",
  "RestaurantTableIcon",
  "RestoreBinIcon",
  "ReturnRequestIcon",
  "ReverseWithdrawal01Icon",
  "ReverseWithdrawalIcon",
  "ReverseWithdrawal02Icon",
  "Rhombus01Icon",
  "RhombusIcon",
  "RiceBowl01Icon",
  "RiceBowlIcon",
  "RiceBowl02Icon",
  "RightAngleIcon",
  "RightToLeftBlockQuoteIcon",
  "RightToLeftListBulletIcon",
  "RightToLeftListDashIcon",
  "RightToLeftListNumberIcon",
  "RightToLeftListTriangleIcon",
  "RightTriangleIcon",
  "RippleIcon",
  "RiyalRectangleIcon",
  "RiyalIcon",
  "Road01Icon",
  "Road02Icon",
  "RoadLocation01Icon",
  "RoadLocationIcon",
  "RoadLocation02Icon",
  "RoadIcon",
  "RoadWaysideIcon",
  "Robot01Icon",
  "RobotIcon",
  "Robot02Icon",
  "RoboticIcon",
  "Rocket01Icon",
  "Rocket02Icon",
  "RocketIcon",
  "RockingChairIcon",
  "RockingHorseIcon",
  "RollerSkateIcon",
  "RollingPinIcon",
  "Root01Icon",
  "RootIcon",
  "Root02Icon",
  "RootFirstBracketIcon",
  "RootSecondBracketIcon",
  "RootThirdBracketIcon",
  "RootCircleIcon",
  "Rotate01Icon",
  "RotateIcon",
  "Rotate02Icon",
  "Rotate360Icon",
  "RotateBottomLeftIcon",
  "RotateBottomRightIcon",
  "RotateClockwiseIcon",
  "RotateCropIcon",
  "RotateLeft01Icon",
  "RotateLeftIcon",
  "RotateLeft02Icon",
  "RotateLeft03Icon",
  "RotateLeft04Icon",
  "RotateLeft05Icon",
  "RotateLeft06Icon",
  "RotateRight01Icon",
  "RotateRightIcon",
  "RotateRight02Icon",
  "RotateRight03Icon",
  "RotateRight04Icon",
  "RotateRight05Icon",
  "RotateRight06Icon",
  "RotateSquareIcon",
  "RotateTopLeftIcon",
  "RotateTopRightIcon",
  "Route01Icon",
  "RouteIcon",
  "Route02Icon",
  "Route03Icon",
  "RouteBlockIcon",
  "Router01Icon",
  "Router02Icon",
  "RouterIcon",
  "RowDeleteIcon",
  "RowInsertIcon",
  "RssConnected01Icon",
  "RssConnectedIcon",
  "RssConnected02Icon",
  "RssErrorIcon",
  "RssLockedIcon",
  "RssIcon",
  "RssUnlockedIcon",
  "RubElHizbIcon",
  "RubberDuckIcon",
  "RubiksCubeIcon",
  "RukuIcon",
  "RulerIcon",
  "RunningShoesIcon",
  "Sad01Icon",
  "SadIcon",
  "Sad02Icon",
  "SadDizzyIcon",
  "SafariIcon",
  "SafeDelivery01Icon",
  "SafeDeliveryIcon",
  "SafeDelivery02Icon",
  "SafeIcon",
  "SafetyPin01Icon",
  "SafetyPinIcon",
  "SafetyPin02Icon",
  "SailboatCoastalIcon",
  "SailboatOffshoreIcon",
  "SakuraIcon",
  "SalahIcon",
  "SalahTimeIcon",
  "SaleTag01Icon",
  "SaleTagIcon",
  "SaleTag02Icon",
  "SandalsIcon",
  "Satellite01Icon",
  "Satellite02Icon",
  "Satellite03Icon",
  "SatelliteIcon",
  "Saturn01Icon",
  "Saturn02Icon",
  "SaturnIcon",
  "SausageIcon",
  "SaveEnergy01Icon",
  "SaveEnergyIcon",
  "SaveEnergy02Icon",
  "SaveMoneyDollarIcon",
  "SaveMoneyEuroIcon",
  "SaveMoneyPoundIcon",
  "SaveMoneyYenIcon",
  "SavingsIcon",
  "SchemeIcon",
  "School01Icon",
  "SchoolBell01Icon",
  "SchoolBellIcon",
  "SchoolBell02Icon",
  "SchoolBusIcon",
  "SchoolReportCardIcon",
  "SchoolIcon",
  "SchoolTieIcon",
  "Scissor01Icon",
  "ScissorRectangleIcon",
  "ScissorIcon",
  "Scooter01Icon",
  "ScooterIcon",
  "Scooter02Icon",
  "Scooter03Icon",
  "Scooter04Icon",
  "ScooterElectricIcon",
  "ScratchCardIcon",
  "ScreenAddToHomeIcon",
  "ScreenLockRotationIcon",
  "ScreenRotationIcon",
  "ScribdIcon",
  "ScrollHorizontalIcon",
  "ScrollIcon",
  "ScrollVerticalIcon",
  "SdCardIcon",
  "SealIcon",
  "Search01Icon",
  "SearchIcon",
  "Search02Icon",
  "SearchAddIcon",
  "SearchAreaIcon",
  "SearchCircleIcon",
  "SearchDollarIcon",
  "SearchFocusIcon",
  "SearchList01Icon",
  "SearchListIcon",
  "SearchList02Icon",
  "SearchMinusIcon",
  "SearchRemoveIcon",
  "SearchReplaceIcon",
  "SearchSquareIcon",
  "SearchVisualIcon",
  "SearchingIcon",
  "SeatSelectorIcon",
  "SecuredNetworkIcon",
  "SecurityBlockIcon",
  "SecurityCheckIcon",
  "SecurityKeyUsbIcon",
  "SecurityLockIcon",
  "SecurityPasswordIcon",
  "SecurityIcon",
  "SecurityValidationIcon",
  "SecurityWifiIcon",
  "SegmentIcon",
  "Select01Icon",
  "SelectIcon",
  "Select02Icon",
  "SelfTransferIcon",
  "SendToMobileIcon",
  "SendingOrderIcon",
  "SenselessIcon",
  "Sent02Icon",
  "SentIcon",
  "SeoIcon",
  "ServerStack01Icon",
  "ServerStackIcon",
  "ServerStack02Icon",
  "ServerStack03Icon",
  "ServiceIcon",
  "ServingFoodIcon",
  "Setting06Icon",
  "Setting07Icon",
  "SettingDone01Icon",
  "SettingDoneIcon",
  "SettingDone02Icon",
  "SettingDone03Icon",
  "SettingDone04Icon",
  "SettingError03Icon",
  "SettingError04Icon",
  "Settings01Icon",
  "SettingsIcon",
  "Settings02Icon",
  "Settings03Icon",
  "Settings04Icon",
  "Settings05Icon",
  "SettingsError01Icon",
  "SettingsErrorIcon",
  "SettingsError02Icon",
  "Setup01Icon",
  "SetupIcon",
  "Setup02Icon",
  "Shaka01Icon",
  "ShakaIcon",
  "Shaka02Icon",
  "Shaka03Icon",
  "Shaka04Icon",
  "ShampooIcon",
  "ShapeCollectionIcon",
  "ShapesIcon",
  "Share01Icon",
  "ShareIcon",
  "Share02Icon",
  "Share03Icon",
  "Share04Icon",
  "Share05Icon",
  "Share06Icon",
  "Share07Icon",
  "Share08Icon",
  "ShareKnowledgeIcon",
  "ShareLocation01Icon",
  "ShareLocationIcon",
  "ShareLocation02Icon",
  "SharedWifiIcon",
  "SharingIcon",
  "ShellfishIcon",
  "Sheriff01Icon",
  "SheriffIcon",
  "Sheriff02Icon",
  "Shield01Icon",
  "ShieldIcon",
  "Shield02Icon",
  "ShieldBlockchainIcon",
  "ShieldEnergyIcon",
  "ShieldKeyIcon",
  "ShieldUserIcon",
  "ShipmentTrackingIcon",
  "ShippingCenterIcon",
  "ShippingLoadingIcon",
  "ShippingTruck01Icon",
  "ShippingTruckIcon",
  "ShippingTruck02Icon",
  "Shirt01Icon",
  "ShirtIcon",
  "ShockedIcon",
  "ShopSignIcon",
  "ShopifyIcon",
  "ShoppingBag01Icon",
  "ShoppingBagIcon",
  "ShoppingBag02Icon",
  "ShoppingBag03Icon",
  "ShoppingBagAddIcon",
  "ShoppingBagCheckIcon",
  "ShoppingBagFavoriteIcon",
  "ShoppingBagRemoveIcon",
  "ShoppingBasket01Icon",
  "ShoppingBasketIcon",
  "ShoppingBasket02Icon",
  "ShoppingBasket03Icon",
  "ShoppingBasketAdd01Icon",
  "ShoppingBasketAddIcon",
  "ShoppingBasketAdd02Icon",
  "ShoppingBasketAdd03Icon",
  "ShoppingBasketCheckIn01Icon",
  "ShoppingBasketCheckInIcon",
  "ShoppingBasketCheckIn02Icon",
  "ShoppingBasketCheckIn03Icon",
  "ShoppingBasketCheckOut01Icon",
  "ShoppingBasketCheckOutIcon",
  "ShoppingBasketCheckOut02Icon",
  "ShoppingBasketCheckOut03Icon",
  "ShoppingBasketDone01Icon",
  "ShoppingBasketDoneIcon",
  "ShoppingBasketDone02Icon",
  "ShoppingBasketDone03Icon",
  "ShoppingBasketFavorite01Icon",
  "ShoppingBasketFavoriteIcon",
  "ShoppingBasketFavorite02Icon",
  "ShoppingBasketFavorite03Icon",
  "ShoppingBasketRemove01Icon",
  "ShoppingBasketRemoveIcon",
  "ShoppingBasketRemove02Icon",
  "ShoppingBasketRemove03Icon",
  "ShoppingBasketSecure01Icon",
  "ShoppingBasketSecureIcon",
  "ShoppingBasketSecure02Icon",
  "ShoppingBasketSecure03Icon",
  "ShoppingCart01Icon",
  "ShoppingCartIcon",
  "ShoppingCart02Icon",
  "ShoppingCartAdd01Icon",
  "ShoppingCartAddIcon",
  "ShoppingCartAdd02Icon",
  "ShoppingCartCheck01Icon",
  "ShoppingCartCheckIcon",
  "ShoppingCartCheck02Icon",
  "ShoppingCartCheckIn01Icon",
  "ShoppingCartCheckInIcon",
  "ShoppingCartCheckIn02Icon",
  "ShoppingCartCheckOut01Icon",
  "ShoppingCartCheckOutIcon",
  "ShoppingCartCheckOut02Icon",
  "ShoppingCartFavorite01Icon",
  "ShoppingCartFavoriteIcon",
  "ShoppingCartFavorite02Icon",
  "ShoppingCartRemove01Icon",
  "ShoppingCartRemoveIcon",
  "ShoppingCartRemove02Icon",
  "ShortsPantsIcon",
  "ShoulderIcon",
  "ShuffleIcon",
  "ShuffleSquareIcon",
  "ShutDownIcon",
  "ShutterstockIcon",
  "SidebarBottomIcon",
  "SidebarLeft01Icon",
  "SidebarLeftIcon",
  "SidebarRight01Icon",
  "SidebarRightIcon",
  "SidebarTopIcon",
  "SignLanguageCIcon",
  "SignalFull01Icon",
  "SignalFullIcon",
  "SignalFull02Icon",
  "SignalLow01Icon",
  "SignalLowIcon",
  "SignalLow02Icon",
  "SignalLowMediumIcon",
  "SignalMedium01Icon",
  "SignalMediumIcon",
  "SignalMedium02Icon",
  "SignalNo01Icon",
  "SignalNoIcon",
  "SignalNo02Icon",
  "SignalIcon",
  "SignatureIcon",
  "SilenceIcon",
  "Simcard01Icon",
  "SimcardIcon",
  "Simcard02Icon",
  "SimcardDualIcon",
  "SinIcon",
  "Sine01Icon",
  "SineIcon",
  "Sine02Icon",
  "SingLeftIcon",
  "SingRightIcon",
  "Sink01Icon",
  "SinkIcon",
  "Sink02Icon",
  "SiriNewIcon",
  "SiriIcon",
  "SketchIcon",
  "SkewIcon",
  "SkiDiceFaces01Icon",
  "SkiDiceFacesIcon",
  "SkippingRopeIcon",
  "SkoolIcon",
  "SkullIcon",
  "SkypeIcon",
  "SlackIcon",
  "SleepingIcon",
  "SleevelessIcon",
  "SlidersHorizontalIcon",
  "SlidersVerticalIcon",
  "SlideshareIcon",
  "SlowWindsIcon",
  "SmartAcIcon",
  "SmartPhone01Icon",
  "SmartPhoneIcon",
  "SmartPhone02Icon",
  "SmartPhone03Icon",
  "SmartPhone04Icon",
  "SmartPhoneLandscapeIcon",
  "SmartIcon",
  "SmartWatch01Icon",
  "SmartWatchIcon",
  "SmartWatch02Icon",
  "SmartWatch03Icon",
  "SmartWatch04Icon",
  "SmartphoneLostWifiIcon",
  "SmartphoneWifiIcon",
  "SmileDizzyIcon",
  "SmileIcon",
  "SmsCodeIcon",
  "SnailIcon",
  "SnapchatIcon",
  "SnowIcon",
  "SocksIcon",
  "SodaCanIcon",
  "Sofa01Icon",
  "SofaIcon",
  "Sofa02Icon",
  "Sofa03Icon",
  "SofaSingleIcon",
  "SoftDrink01Icon",
  "SoftDrinkIcon",
  "SoftDrink02Icon",
  "SoftwareLicenseIcon",
  "SoftwareIcon",
  "SoftwareUninstallIcon",
  "SoilMoistureFieldIcon",
  "SoilMoistureGlobalIcon",
  "SoilTemperatureFieldIcon",
  "SoilTemperatureGlobalIcon",
  "SolarEnergyIcon",
  "SolarPanel01Icon",
  "SolarPanelIcon",
  "SolarPanel02Icon",
  "SolarPanel03Icon",
  "SolarPanel04Icon",
  "SolarPanel05Icon",
  "SolarPowerIcon",
  "SolarSystem01Icon",
  "SolarSystemIcon",
  "SolidLine01Icon",
  "SolidLineIcon",
  "SolidLine02Icon",
  "SortByDown01Icon",
  "SortByDownIcon",
  "SortByDown02Icon",
  "SortByUp01Icon",
  "SortByUpIcon",
  "SortByUp02Icon",
  "Sorting01Icon",
  "SortingIcon",
  "Sorting02Icon",
  "Sorting03Icon",
  "Sorting04Icon",
  "Sorting05Icon",
  "Sorting19Icon",
  "Sorting91Icon",
  "SortingAZ01Icon",
  "SortingAZIcon",
  "SortingAZ02Icon",
  "SortingDownIcon",
  "SortingUpIcon",
  "SortingZA01Icon",
  "SortingZAIcon",
  "SoundcloudIcon",
  "SourceCodeCircleIcon",
  "SourceCodeIcon",
  "SourceCodeSquareIcon",
  "SpaceshipIcon",
  "SpadesIcon",
  "SpaghettiIcon",
  "SpamIcon",
  "SparklesIcon",
  "SpartanHelmetIcon",
  "SpatulaIcon",
  "Speaker01Icon",
  "SpeakerIcon",
  "SpeechToTextIcon",
  "SpeedTrain01Icon",
  "SpeedTrainIcon",
  "SpeedTrain02Icon",
  "SpermIcon",
  "SphereIcon",
  "SpiralsIcon",
  "SpoonAndForkIcon",
  "SpoonAndKnifeIcon",
  "SpoonIcon",
  "SpotifyIcon",
  "SqlIcon",
  "Square01Icon",
  "SquareArrowDataTransferDiagonalIcon",
  "SquareArrowDataTransferHorizontalIcon",
  "SquareArrowDataTransferVerticalIcon",
  "SquareArrowDiagonal01Icon",
  "SquareArrowDiagonalIcon",
  "SquareArrowDiagonal02Icon",
  "SquareArrowDown01Icon",
  "SquareArrowDownIcon",
  "SquareArrowDown02Icon",
  "SquareArrowDown03Icon",
  "SquareArrowDownDoubleIcon",
  "SquareArrowDownLeftIcon",
  "SquareArrowDownRightIcon",
  "SquareArrowExpand01Icon",
  "SquareArrowExpandIcon",
  "SquareArrowExpand02Icon",
  "SquareArrowHorizontalIcon",
  "SquareArrowLeft01Icon",
  "SquareArrowLeftIcon",
  "SquareArrowLeft02Icon",
  "SquareArrowLeft03Icon",
  "SquareArrowLeftDoubleIcon",
  "SquareArrowLeftRightIcon",
  "SquareArrowMoveDownLeftIcon",
  "SquareArrowMoveDownRightIcon",
  "SquareArrowMoveLeftDownIcon",
  "SquareArrowMoveLeftUpIcon",
  "SquareArrowMoveRightDownIcon",
  "SquareArrowMoveRightUpIcon",
  "SquareArrowReload01Icon",
  "SquareArrowReloadIcon",
  "SquareArrowReload02Icon",
  "SquareArrowRight01Icon",
  "SquareArrowRightIcon",
  "SquareArrowRight02Icon",
  "SquareArrowRight03Icon",
  "SquareArrowRightDoubleIcon",
  "SquareArrowShrink01Icon",
  "SquareArrowShrinkIcon",
  "SquareArrowShrink02Icon",
  "SquareArrowUp01Icon",
  "SquareArrowUpIcon",
  "SquareArrowUp02Icon",
  "SquareArrowUp03Icon",
  "SquareArrowUpDoubleIcon",
  "SquareArrowUpDownIcon",
  "SquareArrowUpLeftIcon",
  "SquareArrowUpRightIcon",
  "SquareArrowVerticalIcon",
  "SquareCircleIcon",
  "SquareLock01Icon",
  "SquareLockIcon",
  "SquareLock02Icon",
  "SquareLockAdd01Icon",
  "SquareLockAddIcon",
  "SquareLockAdd02Icon",
  "SquareLockCheck01Icon",
  "SquareLockCheckIcon",
  "SquareLockCheck02Icon",
  "SquareLockMinus01Icon",
  "SquareLockMinusIcon",
  "SquareLockMinus02Icon",
  "SquareLockPasswordIcon",
  "SquareLockRemove01Icon",
  "SquareLockRemoveIcon",
  "SquareLockRemove02Icon",
  "SquareRootSquareIcon",
  "SquareIcon",
  "SquareSquareIcon",
  "SquareUnlock01Icon",
  "SquareUnlockIcon",
  "SquareUnlock02Icon",
  "StackStarIcon",
  "StakeIcon",
  "Stamp01Icon",
  "Stamp02Icon",
  "StampIcon",
  "StarAward01Icon",
  "StarAwardIcon",
  "StarAward02Icon",
  "StarCircleIcon",
  "StarFaceIcon",
  "StarHalfIcon",
  "StarOffIcon",
  "StarIcon",
  "StarSquareIcon",
  "StarsIcon",
  "StartUp01Icon",
  "StartUpIcon",
  "StartUp02Icon",
  "StationeryIcon",
  "StatusIcon",
  "SteakIcon",
  "SteeringIcon",
  "StepIntoIcon",
  "StepOutIcon",
  "StepOverIcon",
  "Stethoscope02Icon",
  "StethoscopeIcon",
  "StickyNote01Icon",
  "StickyNoteIcon",
  "StickyNote02Icon",
  "StickyNote03Icon",
  "StopCircleIcon",
  "StopLossOrderIcon",
  "StopIcon",
  "StopWatchIcon",
  "Store01Icon",
  "StoreIcon",
  "Store02Icon",
  "Store03Icon",
  "Store04Icon",
  "StoreAdd01Icon",
  "StoreAddIcon",
  "StoreAdd02Icon",
  "StoreLocation01Icon",
  "StoreLocationIcon",
  "StoreLocation02Icon",
  "StoreManagement01Icon",
  "StoreManagementIcon",
  "StoreManagement02Icon",
  "StoreRemove01Icon",
  "StoreRemoveIcon",
  "StoreRemove02Icon",
  "StoreVerified01Icon",
  "StoreVerifiedIcon",
  "StoreVerified02Icon",
  "StraightEdgeIcon",
  "StrategyIcon",
  "StreeringWheelIcon",
  "StreetFoodIcon",
  "StripeIcon",
  "StrokeBottomIcon",
  "StrokeCenterIcon",
  "StrokeInsideIcon",
  "StrokeLeftIcon",
  "StrokeOutsideIcon",
  "StrokeRightIcon",
  "StrokeTopIcon",
  "Structure01Icon",
  "StructureIcon",
  "Structure02Icon",
  "Structure03Icon",
  "Structure04Icon",
  "Structure05Icon",
  "StructureAddIcon",
  "StructureCheckIcon",
  "StructureFailIcon",
  "StructureFolderCircleIcon",
  "StructureFolderIcon",
  "StudentCardIcon",
  "StudentIcon",
  "StudentsIcon",
  "StudyDeskIcon",
  "StudyLampIcon",
  "StumbleuponIcon",
  "SubmarineIcon",
  "SubmergeIcon",
  "SubnodeAddIcon",
  "SubnodeDeleteIcon",
  "SubpoenaIcon",
  "SubtitleIcon",
  "Suit01Icon",
  "SuitIcon",
  "Suit02Icon",
  "SujoodIcon",
  "Summation01Icon",
  "SummationIcon",
  "Summation02Icon",
  "SummationCircleIcon",
  "SummationSquareIcon",
  "Sun01Icon",
  "SunIcon",
  "Sun02Icon",
  "Sun03Icon",
  "SunCloud01Icon",
  "SunCloudIcon",
  "SunCloud02Icon",
  "SunCloudAngledRain01Icon",
  "SunCloudAngledRainIcon",
  "SunCloudAngledRain02Icon",
  "SunCloudAngledRainZap01Icon",
  "SunCloudAngledRainZapIcon",
  "SunCloudAngledRainZap02Icon",
  "SunCloudAngledZap01Icon",
  "SunCloudAngledZapIcon",
  "SunCloudAngledZap02Icon",
  "SunCloudBigRain01Icon",
  "SunCloudBigRainIcon",
  "SunCloudBigRain02Icon",
  "SunCloudFastWind01Icon",
  "SunCloudFastWindIcon",
  "SunCloudFastWind02Icon",
  "SunCloudHailstone01Icon",
  "SunCloudHailstoneIcon",
  "SunCloudHailstone02Icon",
  "SunCloudLittleRain01Icon",
  "SunCloudLittleRainIcon",
  "SunCloudLittleRain02Icon",
  "SunCloudLittleSnow01Icon",
  "SunCloudLittleSnowIcon",
  "SunCloudLittleSnow02Icon",
  "SunCloudMidRain01Icon",
  "SunCloudMidRainIcon",
  "SunCloudMidRain02Icon",
  "SunCloudMidSnow01Icon",
  "SunCloudMidSnowIcon",
  "SunCloudMidSnow02Icon",
  "SunCloudSlowWind01Icon",
  "SunCloudSlowWindIcon",
  "SunCloudSlowWind02Icon",
  "SunCloudSnow01Icon",
  "SunCloudSnowIcon",
  "SunCloudSnow02Icon",
  "SunglassesIcon",
  "SunriseIcon",
  "SunsetIcon",
  "SuperMarioIcon",
  "SuperMarioToadIcon",
  "SurfboardIcon",
  "SurpriseIcon",
  "Sushi01Icon",
  "SushiIcon",
  "Sushi02Icon",
  "Sushi03Icon",
  "SuspiciousIcon",
  "SustainableEnergyIcon",
  "Svg01Icon",
  "SvgIcon",
  "Svg02Icon",
  "SwarmIcon",
  "SwatchIcon",
  "SwimmingCapIcon",
  "SwimmingIcon",
  "SwipeDown01Icon",
  "SwipeDownIcon",
  "SwipeDown02Icon",
  "SwipeDown03Icon",
  "SwipeDown04Icon",
  "SwipeDown05Icon",
  "SwipeDown06Icon",
  "SwipeDown07Icon",
  "SwipeDown08Icon",
  "SwipeLeft01Icon",
  "SwipeLeftIcon",
  "SwipeLeft02Icon",
  "SwipeLeft03Icon",
  "SwipeLeft04Icon",
  "SwipeLeft05Icon",
  "SwipeLeft06Icon",
  "SwipeLeft07Icon",
  "SwipeLeft08Icon",
  "SwipeLeft09Icon",
  "SwipeRight01Icon",
  "SwipeRightIcon",
  "SwipeRight02Icon",
  "SwipeRight03Icon",
  "SwipeRight04Icon",
  "SwipeRight05Icon",
  "SwipeRight06Icon",
  "SwipeRight07Icon",
  "SwipeRight08Icon",
  "SwipeRight09Icon",
  "SwipeUp01Icon",
  "SwipeUpIcon",
  "SwipeUp02Icon",
  "SwipeUp03Icon",
  "SwipeUp04Icon",
  "SwipeUp05Icon",
  "SwipeUp06Icon",
  "SwipeUp07Icon",
  "SwipeUp08Icon",
  "Sword01Icon",
  "SwordIcon",
  "Sword02Icon",
  "Sword03Icon",
  "SystemUpdate01Icon",
  "SystemUpdateIcon",
  "SystemUpdate02Icon",
  "TShirtIcon",
  "Table01Icon",
  "Table02Icon",
  "Table03Icon",
  "TableLamp01Icon",
  "TableLampIcon",
  "TableLamp02Icon",
  "TableRoundIcon",
  "TableIcon",
  "TableTennisBatIcon",
  "Tablet01Icon",
  "TabletIcon",
  "Tablet02Icon",
  "TabletConnectedBluetoothIcon",
  "TabletConnectedUsbIcon",
  "TabletConnectedWifiIcon",
  "TabletPenIcon",
  "Taco01Icon",
  "TacoIcon",
  "Taco02Icon",
  "Tag01Icon",
  "TagIcon",
  "Tag02Icon",
  "TagsIcon",
  "TajMahalIcon",
  "TanIcon",
  "TankTopIcon",
  "Tap01Icon",
  "TapIcon",
  "Tap02Icon",
  "Tap03Icon",
  "Tap04Icon",
  "Tap05Icon",
  "Tap06Icon",
  "Tap07Icon",
  "Tap08Icon",
  "TapeMeasureIcon",
  "Target01Icon",
  "TargetIcon",
  "Target02Icon",
  "Target03Icon",
  "TargetDollarIcon",
  "TasbihIcon",
  "Task01Icon",
  "TaskIcon",
  "Task02Icon",
  "TaskAdd01Icon",
  "TaskAddIcon",
  "TaskAdd02Icon",
  "TaskDaily01Icon",
  "TaskDailyIcon",
  "TaskDaily02Icon",
  "TaskDone01Icon",
  "TaskDoneIcon",
  "TaskDone02Icon",
  "TaskEdit01Icon",
  "TaskEditIcon",
  "TaskEdit02Icon",
  "TaskRemove01Icon",
  "TaskRemoveIcon",
  "TaskRemove02Icon",
  "TaxesIcon",
  "TaxiIcon",
  "TeaPodIcon",
  "TeaIcon",
  "TeacherIcon",
  "TeachingIcon",
  "TeamviewerIcon",
  "TelegramIcon",
  "TelephoneIcon",
  "Telescope01Icon",
  "TelescopeIcon",
  "Telescope02Icon",
  "TelevisionTableIcon",
  "TemperatureIcon",
  "TennisBallIcon",
  "TerraceIcon",
  "TestTube01Icon",
  "TestTube02Icon",
  "TestTube03Icon",
  "TestTubeIcon",
  "TetrisIcon",
  "TextAlignCenterIcon",
  "TextAlignJustifyCenterIcon",
  "TextAlignJustifyLeftIcon",
  "TextAlignJustifyRightIcon",
  "TextAlignLeft01Icon",
  "TextAlignLeftIcon",
  "TextAlignRight01Icon",
  "TextAlignRightIcon",
  "TextAllCapsIcon",
  "TextBoldIcon",
  "TextCenterlineCenterTopIcon",
  "TextCenterlineLeftIcon",
  "TextCenterlineMiddleIcon",
  "TextCenterlineRightIcon",
  "TextCheckIcon",
  "TextCircleIcon",
  "TextClearIcon",
  "TextColorIcon",
  "TextCreationIcon",
  "TextFirstlineLeftIcon",
  "TextFirstlineRightIcon",
  "TextFontIcon",
  "TextFootnoteIcon",
  "TextIndent01Icon",
  "TextIndentLessIcon",
  "TextIndentMoreIcon",
  "TextIndentIcon",
  "TextItalicSlashIcon",
  "TextItalicIcon",
  "TextKerningIcon",
  "TextNumberSignIcon",
  "TextSelectionIcon",
  "TextSmallcapsIcon",
  "TextIcon",
  "TextSquareIcon",
  "TextStrikethroughIcon",
  "TextSubscriptIcon",
  "TextSuperscriptIcon",
  "TextTrackingIcon",
  "TextUnderlineIcon",
  "TextVariableFrontIcon",
  "TextVerticalAlignmentIcon",
  "TextWrapIcon",
  "TheProphetsMosqueIcon",
  "ThermometerColdIcon",
  "ThermometerIcon",
  "ThermometerWarmIcon",
  "ThreadIcon",
  "ThreadsEllipseIcon",
  "ThreadsRectangleIcon",
  "ThreadsIcon",
  "ThreeFinger01Icon",
  "ThreeFingerIcon",
  "ThreeFinger02Icon",
  "ThreeFinger03Icon",
  "ThreeFinger04Icon",
  "ThreeFinger05Icon",
  "ThumbsDownEllipseIcon",
  "ThumbsDownRectangleIcon",
  "ThumbsDownIcon",
  "ThumbsUpDownIcon",
  "ThumbsUpEllipseIcon",
  "ThumbsUpRectangleIcon",
  "ThumbsUpIcon",
  "TicTacToeIcon",
  "Tick01Icon",
  "TickIcon",
  "Tick02Icon",
  "Tick03Icon",
  "Tick04Icon",
  "TickDouble01Icon",
  "TickDoubleIcon",
  "TickDouble02Icon",
  "TickDouble03Icon",
  "TickDouble04Icon",
  "Ticket01Icon",
  "TicketIcon",
  "Ticket02Icon",
  "Ticket03Icon",
  "TicketStarIcon",
  "TieIcon",
  "Tiff01Icon",
  "TiffIcon",
  "Tiff02Icon",
  "TiktokIcon",
  "TiltifyIcon",
  "Time01Icon",
  "TimeIcon",
  "Time02Icon",
  "Time03Icon",
  "Time04Icon",
  "TimeHalfPassIcon",
  "TimeManagementCircleIcon",
  "TimeManagementIcon",
  "TimeQuarter02Icon",
  "TimeQuarterPassIcon",
  "TimeQuarterIcon",
  "TimeScheduleIcon",
  "TimeSetting01Icon",
  "TimeSettingIcon",
  "TimeSetting02Icon",
  "TimeSetting03Icon",
  "Timer01Icon",
  "TimerIcon",
  "Timer02Icon",
  "TipsIcon",
  "TireIcon",
  "Tired01Icon",
  "TiredIcon",
  "Tired02Icon",
  "TissuePaperIcon",
  "ToggleOffIcon",
  "ToggleOnIcon",
  "Toilet01Icon",
  "ToiletIcon",
  "Toilet02Icon",
  "TokenCircleIcon",
  "TokenSquareIcon",
  "Tongue01Icon",
  "TongueIcon",
  "TongueWinkLeftIcon",
  "TongueWinkRightIcon",
  "ToolsIcon",
  "Tornado01Icon",
  "TornadoIcon",
  "Tornado02Icon",
  "TorriGateIcon",
  "Touch01Icon",
  "TouchIcon",
  "Touch02Icon",
  "Touch03Icon",
  "Touch04Icon",
  "Touch05Icon",
  "Touch06Icon",
  "Touch07Icon",
  "Touch08Icon",
  "Touch09Icon",
  "Touch10Icon",
  "TouchInteraction01Icon",
  "TouchInteractionIcon",
  "TouchInteraction02Icon",
  "TouchInteraction03Icon",
  "TouchInteraction04Icon",
  "TouchLocked01Icon",
  "TouchLockedIcon",
  "TouchLocked02Icon",
  "TouchLocked03Icon",
  "TouchLocked04Icon",
  "Touchpad01Icon",
  "TouchpadIcon",
  "Touchpad02Icon",
  "Touchpad03Icon",
  "Touchpad04Icon",
  "TowTruckIcon",
  "TowelsIcon",
  "ToyTrainIcon",
  "TractorIcon",
  "TradeDownIcon",
  "TradeMarkCircleIcon",
  "TradeUpIcon",
  "TrademarkIcon",
  "TrafficIncidentIcon",
  "TrafficJam01Icon",
  "TrafficJamIcon",
  "TrafficJam02Icon",
  "TrafficLightIcon",
  "Train01Icon",
  "TrainIcon",
  "Train02Icon",
  "TramIcon",
  "TrampolineIcon",
  "TransactionIcon",
  "TransitionBottomIcon",
  "TransitionLeftIcon",
  "TransitionRightIcon",
  "TransitionTopIcon",
  "TranslateIcon",
  "TranslationIcon",
  "TransmissionIcon",
  "TransparencyIcon",
  "TrapezoidLineHorizontalIcon",
  "TrapezoidLineVerticalIcon",
  "TravelBagIcon",
  "Treadmill01Icon",
  "TreadmillIcon",
  "Treadmill02Icon",
  "TreatmentIcon",
  "Tree01Icon",
  "TreeIcon",
  "Tree02Icon",
  "Tree03Icon",
  "Tree04Icon",
  "Tree05Icon",
  "Tree06Icon",
  "Tree07Icon",
  "TrelloIcon",
  "Triangle01Icon",
  "Triangle02Icon",
  "Triangle03Icon",
  "TriangleIcon",
  "Trolley01Icon",
  "TrolleyIcon",
  "Trolley02Icon",
  "TropicalStormIcon",
  "TropicalStormTracks01Icon",
  "TropicalStormTracksIcon",
  "TropicalStormTracks02Icon",
  "TruckDeliveryIcon",
  "TruckMonsterIcon",
  "TruckReturnIcon",
  "TruckIcon",
  "TrulliIcon",
  "TsunamiIcon",
  "TulipIcon",
  "TumblrIcon",
  "TurtleNeckIcon",
  "Tv01Icon",
  "TvIcon",
  "Tv02Icon",
  "TvFixIcon",
  "TvIssueIcon",
  "TvSmartIcon",
  "TwinTowerIcon",
  "TwitchIcon",
  "TwitterIcon",
  "TwitterSquareIcon",
  "TwoFactorAccessIcon",
  "TwoFinger01Icon",
  "TwoFingerIcon",
  "TwoFinger02Icon",
  "TwoFinger03Icon",
  "TwoFinger04Icon",
  "TwoFinger05Icon",
  "Txt01Icon",
  "TxtIcon",
  "Txt02Icon",
  "TypeCursorIcon",
  "Typescript01Icon",
  "TypescriptIcon",
  "Typescript02Icon",
  "Typescript03Icon",
  "UberIcon",
  "Ufo01Icon",
  "UfoIcon",
  "UmbrellaDollarIcon",
  "UmbrellaIcon",
  "UnamusedIcon",
  "UnavailableIcon",
  "Underpants01Icon",
  "UnderpantsIcon",
  "Underpants02Icon",
  "Underpants03Icon",
  "Undo02Icon",
  "Undo03Icon",
  "UndoIcon",
  "UnfoldLessIcon",
  "UnfoldMoreIcon",
  "UngroupItemsIcon",
  "UngroupLayersIcon",
  "UnhappyIcon",
  "UniversalAccessCircleIcon",
  "UniversalAccessIcon",
  "UniversityIcon",
  "Unlink01Icon",
  "UnlinkIcon",
  "Unlink02Icon",
  "Unlink03Icon",
  "Unlink04Icon",
  "Unlink05Icon",
  "Unlink06Icon",
  "UnsplashIcon",
  "Upload01Icon",
  "UploadIcon",
  "Upload02Icon",
  "Upload03Icon",
  "Upload04Icon",
  "Upload05Icon",
  "UploadCircle01Icon",
  "UploadCircleIcon",
  "UploadCircle02Icon",
  "UploadSquare01Icon",
  "UploadSquareIcon",
  "UploadSquare02Icon",
  "UpworkIcon",
  "UsbBugsIcon",
  "UsbConnected01Icon",
  "UsbConnectedIcon",
  "UsbConnected02Icon",
  "UsbConnected03Icon",
  "UsbErrorIcon",
  "UsbMemory01Icon",
  "UsbMemoryIcon",
  "UsbMemory02Icon",
  "UsbNotConnected01Icon",
  "UsbNotConnectedIcon",
  "UsbNotConnected02Icon",
  "UsbIcon",
  "User02Icon",
  "User03Icon",
  "UserAccountIcon",
  "UserAdd01Icon",
  "UserAddIcon",
  "UserAdd02Icon",
  "UserArrowLeftRightIcon",
  "UserBlock01Icon",
  "UserBlockIcon",
  "UserBlock02Icon",
  "UserCheck01Icon",
  "UserCheckIcon",
  "UserCheck02Icon",
  "UserCircle02Icon",
  "UserCircleIcon",
  "UserEdit01Icon",
  "UserEditIcon",
  "UserFullViewIcon",
  "UserGroup02Icon",
  "UserGroup03Icon",
  "UserGroupIcon",
  "UserIdVerificationIcon",
  "UserListIcon",
  "UserLock01Icon",
  "UserLockIcon",
  "UserLock02Icon",
  "UserLove01Icon",
  "UserLoveIcon",
  "UserLove02Icon",
  "UserMinus01Icon",
  "UserMinusIcon",
  "UserMinus02Icon",
  "UserMultiple02Icon",
  "UserMultiple03Icon",
  "UserMultipleIcon",
  "UserQuestion01Icon",
  "UserQuestionIcon",
  "UserQuestion02Icon",
  "UserRemove01Icon",
  "UserRemoveIcon",
  "UserRemove02Icon",
  "UserRoadsideIcon",
  "UserSearch01Icon",
  "UserSearchIcon",
  "UserSearch02Icon",
  "UserSettings01Icon",
  "UserSettingsIcon",
  "UserSettings02Icon",
  "UserSharingIcon",
  "UserShield01Icon",
  "UserShieldIcon",
  "UserShield02Icon",
  "UserIcon",
  "UserSquareIcon",
  "UserStar01Icon",
  "UserStarIcon",
  "UserStar02Icon",
  "UserStatusIcon",
  "UserStoryIcon",
  "UserSwitchIcon",
  "UserUnlock01Icon",
  "UserUnlockIcon",
  "UserUnlock02Icon",
  "Uv01Icon",
  "UvIcon",
  "Uv02Icon",
  "Uv03Icon",
  "VaccineIcon",
  "ValidationApprovalIcon",
  "ValidationIcon",
  "VanIcon",
  "VariableIcon",
  "VegetarianFoodIcon",
  "VersusIcon",
  "VerticalResizeIcon",
  "VerticalScrollPointIcon",
  "VestIcon",
  "VictoryFinger01Icon",
  "VictoryFingerIcon",
  "VictoryFinger02Icon",
  "VictoryFinger03Icon",
  "Video01Icon",
  "VideoIcon",
  "Video02Icon",
  "VideoConsoleIcon",
  "VideoOffIcon",
  "VideoReplayIcon",
  "ViewOffSlashIcon",
  "ViewOffIcon",
  "ViewIcon",
  "VimeoIcon",
  "VineIcon",
  "VineSquareIcon",
  "VintageClockIcon",
  "VirtualRealityVr01Icon",
  "VirtualRealityVrIcon",
  "VirtualRealityVr02Icon",
  "VisionIcon",
  "VkIcon",
  "VkSquareIcon",
  "VoiceIdIcon",
  "VoiceIcon",
  "VolleyballIcon",
  "VolumeHighIcon",
  "VolumeLowIcon",
  "VolumeMinusIcon",
  "VolumeMute01Icon",
  "VolumeMuteIcon",
  "VolumeMute02Icon",
  "VolumeOffIcon",
  "VolumeUpIcon",
  "VomitingIcon",
  "VoucherIcon",
  "VrGlassesIcon",
  "VrIcon",
  "Vynil01Icon",
  "VynilIcon",
  "Vynil02Icon",
  "Vynil03Icon",
  "WaiterIcon",
  "WaitersIcon",
  "WallLampIcon",
  "Wallet01Icon",
  "WalletIcon",
  "Wallet02Icon",
  "Wallet03Icon",
  "Wallet04Icon",
  "Wallet05Icon",
  "WalletAdd01Icon",
  "WalletAddIcon",
  "WalletAdd02Icon",
  "WalletDone01Icon",
  "WalletDoneIcon",
  "WalletDone02Icon",
  "WalletNotFound01Icon",
  "WalletNotFoundIcon",
  "WalletNotFound02Icon",
  "WalletRemove01Icon",
  "WalletRemoveIcon",
  "WalletRemove02Icon",
  "WantedIcon",
  "Wardrobe01Icon",
  "WardrobeIcon",
  "Wardrobe02Icon",
  "Wardrobe03Icon",
  "Wardrobe04Icon",
  "WarehouseIcon",
  "WashingtonMonumentIcon",
  "WasteRestoreIcon",
  "WasteIcon",
  "Watch01Icon",
  "WatchIcon",
  "Watch02Icon",
  "WaterEnergyIcon",
  "WaterPoloIcon",
  "WaterPumpIcon",
  "WaterfallDown01Icon",
  "WaterfallDownIcon",
  "WaterfallDown03Icon",
  "WaterfallUp01Icon",
  "WaterfallUpIcon",
  "WaterfallUp02Icon",
  "WatermelonIcon",
  "WattpadIcon",
  "WattpadSquareIcon",
  "Wav01Icon",
  "WavIcon",
  "Wav02Icon",
  "WavingHand01Icon",
  "WavingHandIcon",
  "WavingHand02Icon",
  "WazeIcon",
  "WebDesign01Icon",
  "WebDesignIcon",
  "WebDesign02Icon",
  "WebProgrammingIcon",
  "WebProtectionIcon",
  "WebSecurityIcon",
  "WebValidationIcon",
  "WebflowIcon",
  "WebhookIcon",
  "WechatIcon",
  "WeddingIcon",
  "WeightScale01Icon",
  "WeightScaleIcon",
  "WellnessIcon",
  "WhatsappBusinessIcon",
  "WhatsappIcon",
  "WheelchairIcon",
  "WhiskIcon",
  "WhistleIcon",
  "WhiteboardIcon",
  "Wifi01Icon",
  "WifiIcon",
  "Wifi02Icon",
  "WifiCircleIcon",
  "WifiConnected01Icon",
  "WifiConnectedIcon",
  "WifiConnected02Icon",
  "WifiConnected03Icon",
  "WifiDisconnected01Icon",
  "WifiDisconnectedIcon",
  "WifiDisconnected02Icon",
  "WifiDisconnected03Icon",
  "WifiDisconnected04Icon",
  "WifiError01Icon",
  "WifiErrorIcon",
  "WifiError02Icon",
  "WifiFullSignalIcon",
  "WifiLocationIcon",
  "WifiLockIcon",
  "WifiLowSignalIcon",
  "WifiMediumSignalIcon",
  "WifiNoSignalIcon",
  "WifiOff01Icon",
  "WifiOffIcon",
  "WifiOff02Icon",
  "WifiSquareIcon",
  "WifiUnlockIcon",
  "WikipediaIcon",
  "WindPower01Icon",
  "WindPower02Icon",
  "WindPower03Icon",
  "WindPowerIcon",
  "WindSurfIcon",
  "WindTurbineIcon",
  "WindowsNewIcon",
  "WindowsOldIcon",
  "WinkIcon",
  "WinkingIcon",
  "WirelessCloudAccessIcon",
  "WirelessIcon",
  "WiseIcon",
  "WomanIcon",
  "WordpressIcon",
  "WorkAlertIcon",
  "WorkHistoryIcon",
  "WorkIcon",
  "WorkUpdateIcon",
  "WorkflowCircle01Icon",
  "WorkflowCircleIcon",
  "WorkflowCircle02Icon",
  "WorkflowCircle03Icon",
  "WorkflowCircle04Icon",
  "WorkflowCircle05Icon",
  "WorkflowCircle06Icon",
  "WorkflowSquare01Icon",
  "WorkflowSquareIcon",
  "WorkflowSquare02Icon",
  "WorkflowSquare03Icon",
  "WorkflowSquare04Icon",
  "WorkflowSquare05Icon",
  "WorkflowSquare06Icon",
  "WorkflowSquare07Icon",
  "WorkflowSquare08Icon",
  "WorkflowSquare09Icon",
  "WorkflowSquare10Icon",
  "WorkoutBattleRopesIcon",
  "WorkoutGymnasticsIcon",
  "WorkoutKickingIcon",
  "WorkoutRunIcon",
  "WorkoutSportIcon",
  "WorkoutSquatsIcon",
  "WorkoutStretchingIcon",
  "WorkoutWarmUpIcon",
  "WorryIcon",
  "WpsOfficeRectangleIcon",
  "WpsOfficeIcon",
  "Wrench01Icon",
  "WrenchIcon",
  "Wrench02Icon",
  "WuduIcon",
  "XRayIcon",
  "XVariableCircleIcon",
  "XVariableIcon",
  "XVariableSquareIcon",
  "XingIcon",
  "Xls01Icon",
  "XlsIcon",
  "Xls02Icon",
  "Xml01Icon",
  "XmlIcon",
  "Xml02Icon",
  "Xsl01Icon",
  "XslIcon",
  "Xsl02Icon",
  "YelpIcon",
  "YenCircleIcon",
  "YenReceiveIcon",
  "YenSendIcon",
  "YenIcon",
  "YenSquareIcon",
  "Yoga01Icon",
  "YogaIcon",
  "Yoga02Icon",
  "Yoga03Icon",
  "YogaBallIcon",
  "YogaMatIcon",
  "YogurtIcon",
  "YoutubeIcon",
  "YurtIcon",
  "ZakatIcon",
  "ZapIcon",
  "ZeppelinIcon",
  "Zip01Icon",
  "ZipIcon",
  "Zip02Icon",
  "ZoomCircleIcon",
  "ZoomInAreaIcon",
  "ZoomOutAreaIcon",
  "ZoomIcon",
  "ZoomSquareIcon",
  "ZshIcon",
  "ZzzIcon"
];

const HugeIcons = {
  solid,
  stroke
};
const HugeIconsDefault = stroke;

export { HugeIcons, HugeIconsDefault, HugeIconsList };
