import React, {useState} from 'react';

import {Platform} from 'react-native';

import {Popover} from '../Popover';
import {Select} from '../Select';
import {YStack} from '../YStack';

import {BadgesContainer} from './components/BadgesContainer';
import {OptionList} from './components/OptionList';
import {Trigger} from './components/Trigger';
import {useSelectValues} from './hooks/useSelectedValues';
import {MultiSelectProps} from './types';

export function MultiSelect({
  options,
  onChange,
  values,
  placeholder,
  label,
  prefix,
  subtext,
  value,
  width,
  badgesMode,
  triggerInputStyles,
  triggerProps,
}: MultiSelectProps) {
  const [showSelectList, setShowSelectList] = useState<boolean>(false);
  const [triggerWidth, setTriggerWidth] = useState<number | null>(null);

  const {selectedValues, isSelected, onChangeSelection, onReset} = useSelectValues(values, onChange);

  if (Platform.OS === 'web') {
    return (
      <Popover
        hideArrow
        triggerProps={{width: '100%', ...triggerProps}}
        closable
        contentProps={{marginVertical: 5, padding: 0, borderRadius: '$rounded-lg', overflow: 'hidden'}}
        trigger={
          <Trigger
            showSelectList={showSelectList}
            selectedValues={selectedValues}
            width={width}
            placeholder={placeholder}
            value={value}
            prefix={prefix}
            subtext={subtext}
            onChangeSelection={onChangeSelection}
            setShowSelectList={setShowSelectList}
            label={label}
            badgesMode={badgesMode}
            onReset={onReset}
            setTriggerWidth={setTriggerWidth}
            triggerInputStyles={triggerInputStyles}
          />
        }>
        <YStack
          borderRadius="$rounded-lg"
          padding={10}
          width={triggerWidth || width}
          maxHeight={300}
          cursor="pointer"
          overflow="scroll">
          <OptionList options={options} onChangeSelection={onChangeSelection} isSelected={isSelected} />
        </YStack>
      </Popover>
    );
  }

  return (
    <Select
      style={triggerInputStyles}
      placeholder={placeholder}
      triggerProps={{width: width || '100%', ...triggerProps}}
      trigger={
        badgesMode && (
          <BadgesContainer
            width={width}
            label={label}
            selectedValues={selectedValues}
            onChangeSelection={onChangeSelection}
            onReset={onReset}
          />
        )
      }>
      <OptionList options={options} onChangeSelection={onChangeSelection} isSelected={isSelected} />
    </Select>
  );
}
