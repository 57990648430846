import {ProgressBarProps} from '../..';

export const getContainerStyle = (props: ProgressBarProps) => {
  if (props.showBadge) {
    return {
      flexDirection: props.badgePosition === 'bottom' ? 'column-reverse' : 'column',
      justifyContent: 'flex-start',
      gap: '$2',
      alignItems: 'flex-start',
    };
  }
  return {
    flexDirection: props.textValuePosition === 'bottom' ? 'column' : 'row',
    justifyContent: 'flex-end',
    gap: '$3',
    alignItems: props.textValuePosition === 'bottom' ? 'flex-end' : 'center',
  };
};
