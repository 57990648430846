import {StepsType} from '../model';

export const isPreviousStep = (currentStep: number, index: number) => {
  return currentStep > index + 1;
};

export const isNextStep = (currentStep: number, index: number) => {
  return currentStep < index + 1;
};

export const isCurrentStep = (currentStep: number, index: number) => {
  return currentStep === index + 1;
};
export const getLabelStyle = ({
  index,
  isHorizontal,
  type,
  gap,
  stepWidth,
}: {
  index: number;
  isHorizontal: boolean;
  type: typeof StepsType[keyof typeof StepsType];
  gap: number;
  stepWidth: number;
}) => {
  return {
    marginLeft: !!index && isHorizontal ? ([StepsType.FeaturedIcons, StepsType.Icons].includes(type) ? gap : 0) : 0,
    marginTop: !!index && !isHorizontal ? ([StepsType.FeaturedIcons, StepsType.Icons].includes(type) ? gap : 0) : 0,
    width: isHorizontal ? stepWidth : 'auto',
    height: !isHorizontal ? stepWidth : 'auto',
  };
};
