import React, {cloneElement, isValidElement, useMemo} from 'react';

import {Typography} from '../Typography';
import {View} from '../View';
import {XStack} from '../XStack';

import {BadgeProps} from './types';
import {getBadgeStyles} from './utils/badgeStyles';

export * from './types';

export const Badge = ({
  label,
  prefix,
  suffix,
  type = 'pill-color',
  size = 'sm',
  color = 'brand',
  ...props
}: BadgeProps) => {
  const badgeStyles = useMemo(() => {
    return getBadgeStyles(type, color, size);
  }, [size, color, type]);

  const renderIcon = (icon: BadgeProps['prefix']) => {
    if (!!icon && isValidElement(icon)) {
      const Icon: React.ReactElement = icon;

      return cloneElement(Icon, {
        color: badgeStyles.icon.color,
        size: 12,
        ...Icon.props,
      });
    }

    return null;
  };

  return (
    <XStack {...badgeStyles.container} {...props}>
      {prefix && <View>{renderIcon(prefix)}</View>}
      <Typography {...badgeStyles.text}>{label}</Typography>
      {suffix && <View>{renderIcon(suffix)}</View>}
    </XStack>
  );
};
