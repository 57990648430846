import React from 'react';

import {View} from '../View';

import {InputProps} from './types';

type Props = {
  isFocused?: boolean;
  triggerFoucs?: () => void;
  children?: React.ReactNode;
  styles?: InputProps;
  hasAffix?: boolean;
} & InputProps;

export const AffixInput: React.FC<Props> = ({
  isFocused = false,
  hasAffix = false,
  triggerFoucs,
  prefix,
  suffix,
  styles,
  children,
}) => {
  if (!hasAffix) return children;

  return (
    <View
      flex={1}
      gap="$spacing-md"
      flexDirection="row"
      alignItems="center"
      {...(styles as any)}
      {...(isFocused ? styles?.focusStyle : {})}
      onPress={() => {
        triggerFoucs?.();
      }}>
      {prefix && <View>{prefix}</View>}
      {children}
      {suffix && <View>{suffix}</View>}
    </View>
  );
};
