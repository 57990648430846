import React, {useState} from 'react';

import {STATE_SYNTAX} from '../../../../../../config';
import {State} from '../../../../../../types';
import {determineType} from '../../../../../../utils';
import {Badge} from '../../../../../Badge';
import {Button} from '../../../../../Button';
import {IconView} from '../../../../../IconView';
import {CoreTrans} from '../../../../../ScopedTrans';
import {useStateContext} from '../../../../../StateProvider';
import {Typography} from '../../../../../Typography';
import {XStack} from '../../../../../XStack';
import {YStack} from '../../../../../YStack';
import {StateAwareInput} from '../../../../components/StateAwareInput';
import {OperatorEditor} from '../components/OperatorEditor';
import {Condition, ConditionData, Operators, OperatorsByType} from '../types';
import {parseValues} from '../utils/parseValues';

type ConditionProps = {
  condition: Condition;
  index: number;
  onUpdate: (condition: Condition) => void;
  onDelete: () => void;
};

export const ConditionItem = ({condition, index, onUpdate, onDelete}: ConditionProps) => {
  const [leftValue, setLeftValue] = useState(condition.data.leftInput);
  const [leftInputType, setLeftInputType] = useState<State['type']>(determineType(parseValues(leftValue)));
  const [rightValue, setRightValue] = useState(condition.data.rightInput);
  const [operator, setOperator] = useState<ConditionData['operator']>(condition.data.operator);

  const context = useStateContext();

  const handleOperatorChange = (newOperator: keyof typeof Operators) => {
    setOperator(newOperator);
  };

  const handleLeftInputChange = (value: string) => {
    setLeftValue(value);
    const firstTwoChars = value.slice(0, 2);
    const lastTwoChars = value.slice(-2);
    if (firstTwoChars === STATE_SYNTAX.PREFIX && lastTwoChars === STATE_SYNTAX.SUFFIX) return;
    const parsedValue = parseValues(value);
    const type = determineType(parsedValue);
    setLeftInputType(type);
    setOperator(OperatorsByType[type][0]);
  };

  const handleStateSelected = (value: {scopeId: string; stateId: string}) => {
    const state = context?.state[value.scopeId]?.find((state) => state.id === value.stateId);
    if (state && state.type) {
      setOperator(OperatorsByType[state.type.toLocaleLowerCase()][0]);
      setLeftInputType(state.type);
    }
  };

  const handleUpdateCondition = () => {
    const newCondition: Condition = {
      ...condition,
      data: {
        leftInput: leftValue,
        operator: operator,
        rightInput: rightValue,
      },
    };

    onUpdate(newCondition);
  };

  return (
    <YStack
      key={condition.id}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      borderRadius="$8"
      borderColor="$border"
      padding="$2"
      flex={1}>
      <XStack alignItems="center" justifyContent="space-between" flex={1} width="100%">
        <Badge
          label={
            <Typography fontSize="$14" fontWeight="700">
              <CoreTrans i18nKey="blocks.ConditionElement" />
            </Typography>
          }
          color="warning"
        />

        <XStack gap="$1" alignItems="center" justifyContent="space-between">
          {index !== 0 && (
            <Button
              prefix={<IconView color="$error-400" size={18} icon="Delete01Icon" />}
              hierarchy="link-color"
              onPress={onDelete}
            />
          )}
          <Button
            prefix={<IconView size={18} icon="FloppyDiskIcon" color="$brand-700" />}
            hierarchy="link-color"
            onPress={handleUpdateCondition}
          />
        </XStack>
      </XStack>
      <StateAwareInput
        onStateSelected={handleStateSelected}
        value={leftValue}
        onChangeText={handleLeftInputChange}
        width="100%"
        types={['array', 'number', 'string', 'boolean']}
      />
      <OperatorEditor value={operator} values={OperatorsByType[leftInputType]} onChange={handleOperatorChange} />

      <StateAwareInput
        value={rightValue}
        onChangeText={setRightValue}
        width="100%"
        types={['array', 'number', 'string', 'boolean']}
      />
    </YStack>
  );
};
