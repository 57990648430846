import React, {useEffect, useId, useRef, useState} from 'react';

import {Platform} from 'react-native';
import {XStack} from 'tamagui';

import {Option} from '../../types';
import {Button} from '../Button';
import {Dialog} from '../Dialog';
import {Input} from '../Input';
import {Popover} from '../Popover';

import {AutocompleteInputContent} from './components/AutocompleteInputContent';
import {componentClassName} from './config';
import {AutocompleteProps} from './types';

export const Autocomplete = ({
  options,
  value,
  onChange,
  onChangeText,
  error,
  width,
  allowNewHook,
  allowNew,
  multiple = false,
  ...props
}: AutocompleteProps) => {
  const id = useId();
  const [inputCurrentValue, setInputCurrentValue] = useState<string>(() => {
    return value?.map((option) => option?.label).join('-') ?? '';
  });
  const [showSelectList, setShowSelectList] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number | undefined>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);
  const [opts, setOpts] = useState<Option[]>([]);

  const [selection, setSelection] = useState<Option[]>(value || []);
  const isSelected = (item: Option) => !!selection?.some((i) => i.value === item.value);

  useEffect(() => {
    setOpts(options);
  }, [options]);

  useEffect(() => {
    if (inputRef.current) {
      const width = inputRef.current.offsetWidth;
      setInputWidth(width);
    }
  }, [inputRef]);

  const onChangeSelection = (item: Option) => {
    let newVal: Option[] = [];

    if (multiple) {
      const has = isSelected(item);
      newVal = has
        ? (selection as Option[])?.filter((i) => i.value !== item.value) ?? []
        : [...((selection as Option[]) ?? []), item];
    } else {
      newVal = isSelected(item) ? (selection as Option[])?.filter((i) => i.value !== item.value) : [item];
    }
    setSelection(newVal);
    setInputCurrentValue(newVal.map((option) => option?.label).join('-'));

    if (typeof onChange === 'function') {
      onChange(multiple ? newVal : newVal[0] || {});
    }

    if (!multiple) {
      setShowSelectList(false);
    }
  };

  const handelTextChange = (value: string) => {
    if (!value) {
      setSelection([]);
      onChange?.([]);
    }
    setInputCurrentValue(value);
    onChangeText?.(value);
  };

  const handelAddNewValue = (newVal: string) => {
    if (newVal) {
      const newItem =
        typeof allowNewHook === 'function'
          ? allowNewHook(newVal)
          : {
              value: newVal,
              label: newVal,
            };
      setOpts((oldVal) => [newItem, ...oldVal]);
    }
  };

  if (Platform.OS === 'web') {
    return (
      <Popover
        hideArrow
        closable
        contentProps={{padding: 0}}
        trigger={
          <Button hierarchy="link-gray" unstyled onPress={() => setShowSelectList(!showSelectList)}>
            <Input
              ref={inputRef}
              containerProps={{
                width: width,
              }}
              flex={1}
              value={inputCurrentValue}
              onChangeText={handelTextChange}
              className={componentClassName}
              id={id}
              error={error}
              {...props}
            />
          </Button>
        }>
        <AutocompleteInputContent
          options={opts}
          isSelected={isSelected}
          onChangeSelection={onChangeSelection}
          searchTerm={inputCurrentValue}
          showSelectList={showSelectList}
          onPress={() => setShowSelectList(!showSelectList)}
          width={inputWidth}
          allowNew={allowNew}
          onAddNew={handelAddNewValue}
        />
      </Popover>
    );
  }

  return (
    <XStack alignItems="center">
      <Input
        ref={inputRef}
        containerProps={{
          width: width,
        }}
        height="100%"
        flex={1}
        onTouchStart={() => setOpenModal(true)}
        value={inputCurrentValue}
        onChangeText={(value) => {
          onChangeText?.(value);
        }}
        id={id}
        error={error}
        {...props}
      />

      <Dialog
        open={openModal}
        onChange={setOpenModal}
        hideFooter
        content={
          <AutocompleteInputContent
            options={opts}
            isSelected={isSelected}
            handelTextChange={handelTextChange}
            onChangeSelection={onChangeSelection}
            searchTerm={inputCurrentValue}
            allowNew={allowNew}
            onAddNew={handelAddNewValue}
          />
        }
      />
    </XStack>
  );
};
