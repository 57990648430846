import {useState, useEffect} from 'react';

type CheckboxState = 'unchecked' | 'checked' | 'indeterminate';

export type StateTransition = 'checkedUnchecked' | 'indeterminateUnchecked';

export const useCheckboxState = (checked: boolean, indeterminate: boolean, stateTransition: StateTransition) => {
  const [state, setState] = useState<CheckboxState>(() => {
    if (checked) return 'checked';
    if (indeterminate) return 'indeterminate';
    return 'unchecked';
  });

  useEffect(() => {
    if (checked) {
      setState('checked');
    } else if (indeterminate) {
      setState('indeterminate');
    } else {
      setState('unchecked');
    }
  }, [checked, indeterminate]);

  const handlePress = (): CheckboxState => {
    let newState: CheckboxState;

    switch (stateTransition) {
      case 'checkedUnchecked':
        newState = state === 'unchecked' ? 'checked' : 'unchecked';
        break;
      case 'indeterminateUnchecked':
        newState = state === 'unchecked' ? 'indeterminate' : 'unchecked';
        break;
      default:
        newState = 'unchecked';
    }

    setState(newState);
    return newState;
  };

  return {state, handlePress};
};
