import React, {forwardRef, useMemo, useRef, useState} from 'react';

import {Input as BaseInput} from 'tamagui';

import {useAppDirection, useTokenColor} from '../../utils';
import {View} from '../View';

import {AddOnBeforeAfter} from './AddOnBeforeAfter';
import {AffixInput} from './AffixInput';
import {Label} from './Label';
import {
  inputDefaultStyles,
  getInputDisabledStyles,
  inputErrorStyles,
  getInputStylesBaedONSize,
  getInputStylesBasedOnAddOnAfter,
  getInputStylesBasedOnAddOnBefore,
  resetInputStyles,
} from './styles/inputStyles';
import {Subtext} from './Subtext';
import {InputProps} from './types';

export const _Input = (
  {
    label,
    value,
    placeholder,
    prefix,
    suffix,
    disabled,
    error = false,
    subtext,
    selectTextOnFocus = true,
    editable = true,
    size = 'sm',
    textAlign,
    required = false,
    addOnBefore,
    onBlur,
    onFocus,
    addOnAfter,
    unstyled,
    containerProps,
    ...props
  }: InputProps,
  ref,
) => {
  const inputRef = useRef<any>();
  const {direction, isRTL} = useAppDirection();
  const [focused, setFocused] = useState(false);
  const inputTextAlign = (isRTL && 'right') || undefined;
  const placeholderColor = useTokenColor('$text-placeholder');

  const inputStyles = useMemo(() => {
    const _disabledStyles = disabled ? getInputDisabledStyles() : {};
    const _errorStyles = error ? inputErrorStyles : {};
    const _stylesBasedOnAddOnBefore = addOnBefore ? getInputStylesBasedOnAddOnBefore() : {};
    const _stylesBasedOnAddOnAfter = addOnAfter ? getInputStylesBasedOnAddOnAfter() : {};

    return {
      ...inputDefaultStyles,
      ...getInputStylesBaedONSize(size),
      ..._disabledStyles,
      ..._errorStyles,
      ..._stylesBasedOnAddOnBefore,
      ..._stylesBasedOnAddOnAfter,
    };
  }, [disabled, size, prefix, suffix, error, addOnBefore, addOnAfter]);

  const hasAffix = useMemo(() => {
    return !!prefix || !!suffix;
  }, [prefix, suffix]);

  const triggerFoucs = () => {
    inputRef?.current?.focus();
  };

  const _onFocus = (e) => {
    onFocus?.(e);
    setFocused(true);
  };

  const _onBlur = (e) => {
    onBlur?.(e);
    setFocused(false);
  };

  return (
    <View>
      <Label label={label} required={required} />
      <View display="flex" flexDirection="row" gap={0} {...containerProps}>
        <AddOnBeforeAfter element={addOnBefore} position="before" isRTL={isRTL} size={size} />
        <AffixInput
          styles={!unstyled ? inputStyles : {}}
          prefix={prefix}
          suffix={suffix}
          hasAffix={hasAffix}
          isFocused={focused}
          triggerFoucs={triggerFoucs}>
          <BaseInput
            direction={direction}
            editable={editable && !disabled}
            selectTextOnFocus={selectTextOnFocus && !disabled}
            disabled={disabled}
            textAlign={textAlign || inputTextAlign}
            placeholder={placeholder}
            value={value}
            {...(hasAffix || unstyled ? resetInputStyles() : inputStyles)}
            placeholderTextColor={placeholderColor as any}
            {...props}
            ref={(element) => {
              inputRef.current = element;
              if (ref) ref.current = element;
            }}
            onFocus={_onFocus}
            onBlur={_onBlur}
          />
        </AffixInput>
        <AddOnBeforeAfter element={addOnAfter} position="after" isRTL={isRTL} size={size} />
      </View>
      <Subtext subtext={subtext} error={error} />
    </View>
  );
};

export const Input = forwardRef(_Input);
