import React from 'react';

import {Tabs as BaseTabs} from 'tamagui';

import {IconView} from '../../IconView';
import {Typography} from '../../Typography';
import {genContainerStyles, genTabStyles, genTextStyles} from '../styles';
import {TabsProps, TabsState, TabsTabProps} from '../types';

type Props = {
  tabs: TabsProps['tabs'];
  appDirection: 'ltr' | 'rtl';
  currentTab: number;
  handleOnInteraction: TabsTabProps['handleOnInteraction'];
  activeAt: TabsState['activeAt'];
  type: TabsProps['type'];
  orientation: TabsProps['orientation'];
};

export const BaseTabsList = ({
  tabs,
  appDirection,
  currentTab,
  orientation,
  type,
  handleOnInteraction,
  ...props
}: Props) => {
  const containerStyles = genContainerStyles(orientation, type);

  return (
    <BaseTabs.List disablePassBorderRadius loop={false} direction={appDirection} {...containerStyles} {...props}>
      {tabs?.map((tab, index) => {
        const isActiveTab = currentTab === index;
        const tabStyles = genTabStyles(isActiveTab, orientation, type);
        const textStyles = genTextStyles(isActiveTab, type);

        return (
          <BaseTabs.Tab
            key={index}
            direction={appDirection}
            onInteraction={handleOnInteraction}
            unstyled
            value={'' + index}
            overflow="hidden"
            opacity={1}
            scale={1}
            {...tabStyles}>
            {tab.icon && <IconView size={20} icon={tab.icon} color="$text-primary" />}
            <Typography {...textStyles}>{tab.label}</Typography>
            {tab.suffix}
          </BaseTabs.Tab>
        );
      })}
    </BaseTabs.List>
  );
};
