export const getColor = (
  isSelected: boolean,
  isSelectedStartOrEnd: boolean,
  isWithinHoverRange: boolean,
  isDisabled: boolean,
) => {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }: {
    selectedFirstOrLastColor: string;
    normalColor: string;
    selectedColor: string;
    rangeHoverColor: string;
    disabledColor: string;
  }) => {
    if (isSelectedStartOrEnd) {
      return {background: selectedFirstOrLastColor, foreground: '$inverseTextColor'};
    } else if (isSelected) {
      return {background: selectedColor, foreground: '$text-secondary'};
    } else if (isWithinHoverRange) {
      return {background: rangeHoverColor, foreground: '$text-secondary'};
    } else if (isDisabled) {
      return {background: disabledColor, foreground: '$gray-200'};
    } else {
      return {background: normalColor, foreground: '$text-secondary'};
    }
  };
};
