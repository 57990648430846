import React from 'react';

import {XStack} from 'tamagui';

import {BidirectionalIcon} from '../BidirectionalIcon';
import {Button} from '../Button';
import {CoreTrans} from '../ScopedTrans';

export interface StepperActionsProps {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  stepsLength: number;
  onFinish: () => void;
  isProcessing: boolean;
  isNextDisabled?: boolean;
  isSaveDisabled?: boolean;
}

export const StepperActions = ({
  currentStep,
  setCurrentStep,
  stepsLength,
  onFinish,
  isProcessing,
  isNextDisabled = false,
  isSaveDisabled = false,
}: StepperActionsProps) => {
  return (
    <XStack space="$2" marginTop="$1">
      {currentStep > 0 && (
        <Button
          hierarchy="secondary-color"
          onPress={() => setCurrentStep(currentStep - 1)}
          prefix={<BidirectionalIcon icon="ArrowLeft01Icon" />}>
          <CoreTrans i18nKey="action.previous" />
        </Button>
      )}
      {currentStep < stepsLength - 1 && (
        <Button
          onPress={() => setCurrentStep(currentStep + 1)}
          suffix={<BidirectionalIcon icon="ArrowRight01Icon" />}
          disabled={isNextDisabled}>
          <CoreTrans i18nKey="action.next" />
        </Button>
      )}
      {currentStep == stepsLength - 1 && (
        <Button
          onPress={onFinish}
          loading={isProcessing}
          suffix={<BidirectionalIcon icon="Tick02Icon" />}
          disabled={isSaveDisabled}>
          <CoreTrans i18nKey="action.save" />
        </Button>
      )}
    </XStack>
  );
};
