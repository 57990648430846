import React from 'react';

import {Stack, styled} from 'tamagui';

import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Text} from '../Text';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {Ripples} from './components/Ripples';

const IconContainer = styled(Stack, {
  width: 48,
  height: 48,
  padding: '$3',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 10,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: '$icon-modern-border',
  background: '$bg-primary',
  marginBottom: '$3',
  shadowColor: 'rgba(10, 13, 18, 0.18)',
  shadowOffset: {width: 0, height: 2},
  shadowOpacity: 0.2,
  shadowRadius: '$2',
  position: 'relative',
});

const SearchMessage = styled(Text, {
  fontSize: '$text-sm',
  lineHeight: 20,
  textAlign: 'center',
  maxWidth: 350,
  color: '$text-tertiary',
});

interface Props {
  hasNoData?: boolean;
  hideLabel?: boolean;
  hideRipples?: boolean;
  searchQuery?: string;
  children?: React.ReactNode;
}

export const NoDataMessage: React.FC<Props> = ({hasNoData, hideLabel, hideRipples, searchQuery, children}) => {
  if (!hasNoData) {
    return null;
  }

  return (
    <YStack
      padding="$10"
      paddingTop={hideRipples ? undefined : 100}
      alignItems="center"
      justifyContent="center"
      gap="$2">
      <IconContainer>
        {!hideRipples && <Ripples />}
        <IconView icon="Search01Icon" size={24} color="$fg-secondary" strokeWidth={2} />
      </IconContainer>

      {!hideLabel && (
        <YStack space="$2" alignItems="center">
          <Text fontSize={16} lineHeight={24} color="$text-primary" fontFamily="$heading">
            <CoreTrans i18nKey="noData" />
          </Text>

          {searchQuery && (
            <SearchMessage>
              <CoreTrans i18nKey="searchNoMatch" values={{searchQuery}} />
            </SearchMessage>
          )}
        </YStack>
      )}

      {children && (
        <XStack marginTop="$3" space="$3">
          {children}
        </XStack>
      )}
    </YStack>
  );
};
