export const en = {
  login: 'Login',
  action: {
    save: 'Save',
    reset: 'Reset',
    close: 'Close',
    cancel: 'Cancel',
    next: 'Next',
    previous: 'Previous',
    seeMore: 'See More',
    accept: 'Accept',
    apply: 'Apply',
    ok: 'Ok',
    confirm: 'Confirm',
  },
  actions: 'Actions',
  settings: {
    title: 'Settings',
    general: {
      _name: 'General',
      connectors: 'Connectors',
      connector: {
        enable: 'Enable',
        url: 'URL',
        username: 'Username',
        password: 'Password',
        tenant: 'Tenant',
        token: 'Token',
      },
    },
  },
  calendar: {
    title: 'Calendar',
    titleKey: 'title',
    time: 'Time',
    noEvents: 'No events',
    from: 'From',
    to: 'to',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    filters: 'Filters',
    today: 'Today',
    m: 'M',
    h: 'H',
    selectaDate: "Select a date",
    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
    days: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
    },
    dateRanges: {
      today: 'Today',
      yesterday: 'Yesterday',
      lastWeek: 'Last Week',
      thisWeek: 'This Week',
      lastMonth: 'Last Month',
      thisMonth: 'This Month',
      lastYear: 'Last Year',
      thisYear: 'This Year',
      allTime: 'All Time',
    },
  },
  validation: {
    required: 'Required',
  },
  note: 'Note',
  success: 'Success',
  error: 'Error',
  generalErrorMsg: 'Sorry, something went wrong.',
  resourceErrorMsg: 'API name - {{resourceName}} - failed to fetch',
  successMsg: 'Done, Successfully',
  connectorMustBeDefined: 'Please configure the connector first',
  connectorIsDisabled: 'Please note that this connector is disabled',
  sortBy: 'Sort By',
  ago: '{{value}} ago',
  pagination: {
    rowsPerPage: 'Rows per page:',
    page: 'Page',
    of: 'Of',
    perPage: 'Per Page',
  },
  hidjriMonths: {
    Muharram: 'Muharram',
    Safar: 'Safar',
    RabiAlAwwal: "Rabi' al-Awwal",
    RabiAlThani: "Rabi' al-Thani",
    JumadaAlUla: 'Jumada al-Ula',
    JumadaAlThaniyah: 'Jumada al-Thaniyah',
    Rajab: 'Rajab',
    Shaaban: "Sha'ban",
    Ramadan: 'Ramadan',
    Shawwal: 'Shawwal',
    DhuAlQidah: "Dhu al-Qi'dah",
    DhuAlHijjah: 'Dhu al-Hijjah',
  },
  builder: {
    loop: {
      name: 'Loop Name',
      dataSource: 'Data Source',
      rule: 'Loop Component must have only one data Source from the state so you can choose it by using {{ , only array data types will shows here',
    },
    state: {
      noStateMsg: 'No State available for this component',
    },
    condition: {
      conditionQuery: 'Condition Query',
      group: 'Group',
      if: 'If',
      equals: 'equals',
      'strict equals': 'strict equals',
      'not equals': 'not equals',
      'strict not equals': 'strict not equals',
      'greater than': 'greater than',
      'greater than or equal': 'greater than or equal',
      'less than': 'less than',
      'less than or equal': 'less than or equal',
      contains: 'includes',
      AND: 'AND',
      OR: 'OR',
      addCondition: 'Add Condition',
      addGroupCondition: 'Add Condition Group',
    },
  },
  blocks: {
    common: 'Common',
    ContainerElement: 'Container',
    TextElement: 'Text',
    LinkElement: 'Link',
    ButtonElement: 'Button',
    ImageElement: 'Image',
    VideoElement: 'Video',
    WebViewElement: 'Web View',
    RichTextElement: 'Rich Text',
    YoutubePlayerElement: 'Youtube',
    AccordionElement: 'Accordion',
    ColumnElement: 'Column',
    RowElement: 'Row',
    PageElement: 'Page',
    TableElement: 'Table',
    StateElement: 'State',
    InputElement: 'Input',
    ProgressElement: 'Progress',
    LoopElement: 'Loop',
    ConditionElement: 'Condition',
    CardElement: 'Card',
  },
  table: {
    searchPlaceholder: 'Search...',
    displaySearch: 'Display search',
    displayPagination: 'Display pagination',
    pageSize: 'Page size',
    footer: 'Footer',
    title: 'Title',
    row: 'Row',
    addRowError: 'You have to add column first',
    columnLabel: 'Name',
    columns: 'Columns',
    rows: 'Rows',
    sorting: 'Sorting',
    searchIn: 'Search In',
    sortWith: 'Sort With',
  },
  progress: {
    value: 'Value',
  },
  card: {
    header: 'Header',
    content: 'Content',
    footer: 'Footer',
  },
  NODE: 'Basic',
  LAYOUT: 'Layout',
  GENERAL: 'General',
  META: 'Meta',
  showAllColumns: 'Show All Columns',
  columns: 'Columns',
  scopes: 'Scopes',
  selectAllScopes: 'Select All Scopes',
  showOnly: 'Show only',
  showDetails: 'Show details',
  testConnection: 'Test Connection',
  connectionFailed: 'Connection Failed',
  connectionSuccess: 'Connection Succeeded',
  languageChangeMsg: 'Some changes will only take effect after app restart',
  noData: 'No Data Found',
  fileProgress: {
    dragAndDropOrClickHere: 'Drag and drop files or click here',
    dropItHere: 'Drop it here',
    uploading: 'Uploading',
    fileUploadedSuccessfully: 'File uploaded successfully',
    MB: 'MB',
    clickToUpload: 'Click to upload',
    or: ' or drag and drop',
  },
  steps: {
    stepLabel: 'Step {{index}} of {{total}}',
  },
  searchNoMatch: 'Your search “{{searchQuery}}” did not match any projects. Please try again.',
};
