import React from 'react';

import {Dimensions, Platform} from 'react-native';
import {Adapt, Dialog as BaseDialog, Sheet} from 'tamagui';

import {AppletContext, useAppletContext} from '../../context/AppletContext';
import {useAppDirection} from '../../utils/useAppDirection';

import {SlideoutMenuContent} from './components/SlideoutMenuContent';
import {SlideoutMenuProps} from './types';

const screenWidth = Dimensions.get('window').width;
export const SlideoutMenu = ({
  trigger,
  title,
  header,
  footer,
  content,
  hideFooter,
  width = '30%',
  height = 'full',
  contentProps,
  onChange,
  onAccept,
  onCancel,
  onClose,
  backdrop = false,
  confirmText,
  cancelText,
  ...props
}: SlideoutMenuProps) => {
  const appletContext = useAppletContext();
  const {isRTL} = useAppDirection();
  const isDesktop = Platform.OS === 'web';
  const menuWidth = isDesktop ? width : '90%';
  const menuInPixels = (screenWidth * parseFloat(menuWidth)) / 100;
  const enterStyles = isRTL ? {x: -menuInPixels, opacity: 0} : {x: menuInPixels, opacity: 0};

  const slideoutMenuContent = (
    <SlideoutMenuContent
      header={header}
      footer={footer}
      title={title}
      contentProps={contentProps}
      content={content}
      hideFooter={hideFooter}
      onCancel={onCancel}
      onAccept={onAccept}
      onClose={onClose}
      confirmText={confirmText}
      cancelText={cancelText}
    />
  );

  return (
    <BaseDialog modal onOpenChange={(open) => (onChange ? onChange(open) : onClose?.())} {...props}>
      <BaseDialog.Trigger asChild>{trigger}</BaseDialog.Trigger>

      <Adapt when="sm" platform="touch">
        <Sheet snapPoints={[parseFloat(height === 'full' ? '95%' : height), 90]} zIndex={5} modal dismissOnSnapToBottom>
          <Sheet.Frame padding={0} space height="50%">
            <AppletContext.Provider value={appletContext}>
              <Adapt.Contents />
            </AppletContext.Provider>
          </Sheet.Frame>
          <Sheet.Overlay zIndex={5} position="absolute" />
        </Sheet>
      </Adapt>

      <BaseDialog.Portal>
        <AppletContext.Provider value={appletContext}>
          <BaseDialog.Overlay
            key="overlay"
            animation="quick"
            position="relative"
            width="100%"
            height="100%"
            opacity={backdrop ? 0.8 : 1}
            enterStyle={{opacity: 0}}
            exitStyle={{opacity: 0}}
            backgroundColor={backdrop ? '$alpha-black-100' : 'transparent'}
          />

          <BaseDialog.Content
            position="absolute"
            style={{[isRTL ? 'left' : 'right']: 0}}
            top={0}
            borderRadius={0}
            animation="medium"
            padding={0}
            width={menuInPixels}
            height="100%"
            key="content"
            backgroundColor="$background"
            shadowColor="$alpha-black-10"
            shadowOffset={{width: 0, height: 0}}
            shadowOpacity={0.1}
            shadowRadius={10}
            enterStyle={enterStyles}
            exitStyle={enterStyles}>
            {slideoutMenuContent}
          </BaseDialog.Content>
        </AppletContext.Provider>
      </BaseDialog.Portal>
    </BaseDialog>
  );
};
