import React from 'react';

import {View, ViewProps} from 'tamagui';

interface Props {}

const sharedProps: ViewProps = {
  borderRadius: 99999999,
  borderWidth: 0.1,
  borderColor: '$border-secondary',
  position: 'absolute',
  top: 0,
  left: 0,
  width: 48,
  height: 48,
};

export const Ripples: React.FC<Props> = () => {
  return (
    <>
      <View {...sharedProps} opacity={0.1} scale={5} />
      <View {...sharedProps} opacity={0.2} scale={4} />
      <View {...sharedProps} opacity={0.4} scale={3} />
      <View {...sharedProps} opacity={0.6} scale={2} />
    </>
  );
};
