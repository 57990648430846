import React from 'react';

import {useDropzone} from 'react-dropzone';

import {FileProgress} from '../FileProgress';
import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {FileUploadProps, UnifiedFile} from './types';

export * from './types';

export const FileUpload = ({
  onFilesSelected,
  dropzoneOptions,
  text,
  trackedFiles,
  progressType = 'percentage',
  deleteFile,
}: FileUploadProps) => {
  const onDrop = async (acceptedFiles: File[]) => {
    const unifiedFiles: UnifiedFile[] = acceptedFiles.map((file) => ({
      uri: file,
      name: file.name,
      type: file.type,
      size: file.size,
    }));
    onFilesSelected?.(unifiedFiles);
  };
  const {getRootProps = () => {}, getInputProps = () => {}} = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  return (
    <>
      <View
        flexDirection="column"
        borderRadius="$rounded-lg"
        borderColor="$border-secondary"
        borderWidth={1}
        overflow="hidden">
        <div
          {...getRootProps()}
          style={{
            flex: 1,
            backgroundColor: '$gray-50',
            cursor: 'pointer',
          }}>
          <input {...getInputProps()} />
          <View
            alignItems="center"
            justifyContent="center"
            paddingVertical="$4"
            paddingHorizontal="$6"
            gap="$spacing-lg">
            <View
              alignItems="center"
              justifyContent="center"
              padding="$3"
              borderRadius="$rounded-lg"
              borderColor="$border-secondary"
              borderWidth={1}>
              <IconView icon="CloudUploadIcon" size={20} color="$alpha-black-100" />
            </View>

            <YStack gap="$1">
              <XStack gap="$0.25">
                <Typography variant="body2" color="$text-brand-secondary" fontWeight="600">
                  <CoreTrans i18nKey="fileProgress.clickToUpload" />
                </Typography>
                <Typography variant="body2" color="$text-tertiary" fontWeight="400">
                  <CoreTrans i18nKey="fileProgress.or" />
                </Typography>
              </XStack>
              <Typography variant="body2" color="$text-secondary">
                {text}
              </Typography>
            </YStack>
          </View>
        </div>
      </View>
      {Object.values(trackedFiles).map((trackedFile) => (
        <FileProgress
          key={trackedFile.fileName}
          fileSize={trackedFile.fileSize}
          fileName={trackedFile.fileName}
          progress={trackedFile.progress}
          error={trackedFile.error}
          progressType={progressType}
          onDelete={deleteFile}
        />
      ))}
    </>
  );
};
