import React from 'react';

import {BidirectionalIcon} from '../BidirectionalIcon';
import {Button} from '../Button';

interface Props {
  onBack?: () => void;
}

export const NavigateBackButton: React.FC<Props> = ({onBack}) => {
  return (
    <Button
      onPress={onBack}
      prefix={<BidirectionalIcon color="$fg-secondary" icon="ArrowLeft01Icon" size={24} />}
      borderColor="$border-secondary"
      hierarchy="secondary-color"
      paddingHorizontal="$2"
    />
  );
};
