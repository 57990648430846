import React, {ReactNode} from 'react';
import {Text} from '../Text';
import {YStack} from '../YStack';
import {XStack} from '../XStack';
import {Separator} from '../Separator';

const CONSTANTS = {
  VERTICAL_MARGIN: '$8',
  DEFAULT_PADDING: '$2',
  DEFAULT_TEXT: 'Welcome To Medad',
  DEFAULT_LINE_WIDTH: 1,
  DEFAULT_LINE_COLOR: '$border-secondary',
  BACKGROUND_COLOR: '$bg-secondary',
  BORDER_RADIUS: '$8',
} as const;

type DividerProps = {
  text?: string;
  children?: ReactNode;
  lineWidth?: number;
  lineColor?: string;
  dualLine?: boolean;
  spacing?: number;
  numberOfLines?: number;
  backgroundColor?: string;
  padding?: number;
  backgroundFill?: boolean;
};

export const Divider: React.FC<DividerProps> = ({
  text = CONSTANTS.DEFAULT_TEXT,
  children,
  lineWidth = CONSTANTS.DEFAULT_LINE_WIDTH,
  lineColor = CONSTANTS.DEFAULT_LINE_COLOR,
  dualLine = false,
  spacing = 8,
  numberOfLines = 1,
  backgroundColor,
  padding = CONSTANTS.DEFAULT_PADDING,
  backgroundFill = false,
}) => {
  const renderTextContent = () => (
    <Text color="$text-primary" fontSize="$16" numberOfLines={numberOfLines}>
      {text}
    </Text>
  );

  const renderContent = () => {
    const content = text && !children ? renderTextContent() : children;

    if (!backgroundFill) {
      return (
        <YStack padding={padding} borderRadius={CONSTANTS.BORDER_RADIUS}>
          {content}
        </YStack>
      );
    }

    return content;
  };

  if (backgroundFill) {
    return (
      <YStack
        width="100%"
        marginVertical={CONSTANTS.VERTICAL_MARGIN}
        backgroundColor={CONSTANTS.BACKGROUND_COLOR}
        padding={padding}
        borderRadius={CONSTANTS.BORDER_RADIUS}
        alignItems="center"
        justifyContent="center">
        {renderContent()}
      </YStack>
    );
  }

  const containerProps = {
    width: '100%' as const,
    marginVertical: CONSTANTS.VERTICAL_MARGIN,
    backgroundColor,
    padding: backgroundColor ? padding : undefined,
    borderRadius: backgroundColor ? CONSTANTS.BORDER_RADIUS : undefined,
  };

  if (dualLine) {
    return (
      <YStack {...containerProps}>
        <Separator borderColor={lineColor} borderWidth={lineWidth} />
        <YStack alignItems="center" justifyContent="center" marginVertical={spacing}>
          {renderContent()}
        </YStack>
        <Separator borderColor={lineColor} borderWidth={lineWidth} />
      </YStack>
    );
  }

  return (
    <XStack {...containerProps} alignItems="center">
      <Separator flex={1} borderColor={lineColor} borderWidth={lineWidth} />
      <YStack marginHorizontal={spacing}>{renderContent()}</YStack>
      <Separator flex={1} borderColor={lineColor} borderWidth={lineWidth} />
    </XStack>
  );
};
