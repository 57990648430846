import React, {forwardRef, useMemo} from 'react';

import {Button as BaseButton} from 'tamagui';

import {Spinner} from '../Spinner';
import {Typography} from '../Typography';
import {View} from '../View';

import {ButtonProps} from './types';
import {
  getDestructiveColorsStylesBasedOnHierarchy,
  getColorsStyleBasedOnHierarchy,
  getStyleBasedOnSize,
  getStyleWithNoChildrenBasedOnSize,
} from './utils/buttonStyles';

export * from './types';

const _Button = (
  {
    hierarchy = 'primary',
    size = 'md',
    destructive = false,
    loading,
    disabled,
    prefix,
    suffix,
    unstyled,
    children,
    ...rest
  }: ButtonProps,
  ref,
) => {
  const suffixItem = loading ? (iconProps) => <Spinner {...iconProps} /> : suffix;
  const fontWeight = rest.fontWeight || '600';
  const buttonStyles = useMemo(() => {
    let _sizeStyles = getStyleBasedOnSize(size);
    const _sizeStylesWithNoChildren = !children ? getStyleWithNoChildrenBasedOnSize(size) : {};

    _sizeStyles = {
      ..._sizeStyles,
      ..._sizeStylesWithNoChildren,
    };

    if (hierarchy.startsWith('link') || unstyled) {
      _sizeStyles.paddingHorizontal = 0;
      _sizeStyles.paddingVertical = 0;
      _sizeStyles.height = 'auto	';
    }

    let _colorsStyles = getColorsStyleBasedOnHierarchy(hierarchy);

    if (destructive) {
      _colorsStyles = getDestructiveColorsStylesBasedOnHierarchy(hierarchy);
    }
    const _focusStyle = {
      ..._colorsStyles['default'],
      outlineColor: '$border-brand',
      outlineStyle: 'solid',
      outlineWidth: 1,
    };

    if (unstyled) {
      return {
        height: 'auto',
        ..._sizeStyles,
        backgroundColor: 'transparent',
        hoverStyle: {
          backgroundColor: 'transparent',
        },
        focusStyle: {
          backgroundColor: 'transparent',
          outlineWidth: 0,
          borderWidth: 0,
          outlineColor: 'transparent',
        },
        pressStyle: {
          backgroundColor: 'transparent',
          outlineWidth: 0,
          borderWidth: 0,
          outlineColor: 'transparent',
        },
      };
    }

    return {
      ..._colorsStyles['default'],
      hoverStyle: {
        ..._colorsStyles['hover'],
      },
      pressStyle: {
        ..._focusStyle,
      },
      focusStyle: {
        ..._focusStyle,
      },
      ...(disabled && _colorsStyles['disabled']),
      ..._sizeStyles,
    };
  }, [hierarchy, disabled, destructive, size, unstyled]);

  return (
    <View>
      <BaseButton
        ref={ref}
        disabled={disabled}
        iconAfter={suffixItem}
        icon={prefix}
        width="max-content"
        {...buttonStyles}
        fontWeight={fontWeight}
        {...rest}>
        {!!children && (
          <Typography
            fontWeight={fontWeight}
            fontSize={rest.fontSize || buttonStyles.fontSize}
            textTransform="capitalize"
            color={buttonStyles.color}>
            {children}
          </Typography>
        )}
      </BaseButton>
    </View>
  );
};

export const Button = forwardRef(_Button);
