import React from 'react';

import {IconListType} from '../../../lib';
import {IconView} from '../../IconView';
import {View} from '../../View';
import {XStack} from '../../XStack';

type FeaturedIconStepProps = {
  isHorizontal: boolean;
  size: number;
  isMinimal: boolean;
  finishedLine: boolean;
  lineWidth: number;
  icon?: IconListType;
};

export const FeaturedIconStep = ({
  isHorizontal,
  size,
  isMinimal,
  lineWidth,
  finishedLine,
  icon = 'Cancel01Icon',
}: FeaturedIconStepProps) => {
  return (
    <>
      <XStack alignItems="center" flexDirection={isHorizontal ? 'row' : 'column'}>
        <View padding="$1" borderRadius="$4" borderWidth={1} borderColor="$gray-200">
          <IconView icon={icon} size={size} color="$alpha-black-100" />
        </View>
      </XStack>
      {!isMinimal && (
        <View
          height={isHorizontal ? 2 : lineWidth}
          backgroundColor={finishedLine ? '$alpha-black-100' : '$bg-quaternary'}
          width={isHorizontal ? lineWidth : 2}
        />
      )}
    </>
  );
};
