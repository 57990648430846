export function parseValues<T>(input: T): T {
  if (typeof input === 'string') {
    if (input.toLowerCase() === 'true') {
      return true as T;
    } else if (input.toLowerCase() === 'false') {
      return false as T;
    }

    if (!isNaN(Number(input))) {
      return Number(input) as T;
    }

    try {
      const parsed = JSON.parse(input);
      return parsed as T;
    } catch {
      return input;
    }
  } else if (Array.isArray(input)) {
    return input.map(parseValues) as T;
  } else if (typeof input === 'object' && input !== null) {
    const parsedObject: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(input)) {
      parsedObject[key] = parseValues(value);
    }
    return parsedObject as T;
  }

  return input;
}
