import {IconType} from './IconType';
import {LocalizedTextType} from './LocalizedTextType';
import {Block, BlockDefinition, State} from './PageDesign';
import {PredefinedPath} from './PageResource';

export enum AppPageType {
  SERVICE,
  CONTENT,
}

export type ContentElementsResolver = Record<string, BlockDefinition>;

export interface AppPageCommon {
  id: string;
  type?: AppPageType;
}

export type AppServicePage = AppPageCommon & {
  appletId: string;
  serviceId: string;
};

export type PageResource = {id: string | number; name: string; predefinedPaths?: PredefinedPath[]};

export type AppContentPage = AppPageCommon &
  Block & {
    version: number;
    specVersion: number;
    title: LocalizedTextType;
    state: Record<string, State[]>;
    resource?: Record<string, PageResource[]>;
    icon?: IconType;
    public?: boolean;
  };

export type AppPagesGroup<T = any> = {
  id: string;
  title?: LocalizedTextType;
  pages: T[];
};

export type AppPage = AppServicePage | AppContentPage;

export class LauncherOptions {
  pageTop?: AppContentPage;
  pageBottom?: AppContentPage;
}

export class AppStructure {
  launcher = new LauncherOptions();
  pages: AppPage[] = [];
  auxiliaryPages: AppPagesGroup[] = [];
}

// Type Guards
export function isAppServicePage(page: AppPageCommon): page is AppServicePage {
  return page.type === AppPageType.SERVICE;
}

export function isAppContentPage(page: AppPageCommon): page is AppContentPage {
  return page.type === AppPageType.CONTENT;
}
