export const STATE_PATTERN = /\{\{(?:([a-zA-Z0-9_]+)\.)?([^}]+)\}\}/g;
export const LIST_KEY_PATH_PATTERN = /\{\{\$\.(.*?)\}\}|\{\{$\}\}/g;

export const GLOBAL_SCOPE_KEY = 'global';
export const GEN_PATH_ROOT_KEY = '$';

export const STATE_SYNTAX = {
  PREFIX: '{{',
  SUFFIX: '}}',
};

export const API_BUILDER_REQUESTS_URL = 'api-builder/requests';
