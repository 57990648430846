import React from 'react';

import {XStackProps} from 'tamagui';

import {TextProps} from '../../Text';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {StepsType} from '../model';

type LabelProps = {
  containerProps: XStackProps;
  fontSize: TextProps['fontSize'];
  label: string;
  subtitle: string;
  isCurrentStep: boolean;
  type: typeof StepsType[keyof typeof StepsType];
};

export const Label = ({containerProps, fontSize, label, subtitle, isCurrentStep, type}: LabelProps) => {
  return (
    <XStack flexDirection="column" alignItems="flex-start" {...containerProps}>
      <Typography
        fontSize={fontSize}
        fontWeight="600"
        variant="body2"
        color={
          type === StepsType.FeaturedIcons
            ? '$text-secondary'
            : isCurrentStep
            ? '$text-brand-secondary'
            : '$text-secondary'
        }>
        {label}
      </Typography>
      <Typography
        fontSize={fontSize}
        fontWeight="400"
        variant="body2"
        color={
          type === StepsType.FeaturedIcons
            ? '$text-tertiary'
            : isCurrentStep
            ? '$text-secondary-on-brand'
            : '$text-secondary-hover'
        }>
        {subtitle}
      </Typography>
    </XStack>
  );
};
