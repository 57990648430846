import React from 'react';

import {Badge} from '../../Badge';
import {IconView} from '../../IconView';
import {LocalizedText} from '../../LocalizedText';
import {Pressable} from '../../Pressable';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {YStack} from '../../YStack';

import {TriggerProps} from './Trigger';

type Props = Pick<TriggerProps, 'width' | 'selectedValues' | 'onChangeSelection' | 'label' | 'triggerInputStyles'> & {
  onReset: () => void;
  setTriggerWidth?: React.Dispatch<React.SetStateAction<number | null>>;
};

export const BadgesContainer = ({
  label,
  onReset,
  width,
  setTriggerWidth,
  selectedValues,
  triggerInputStyles,
  onChangeSelection,
}: Props) => {
  return (
    <YStack
      theme="translucent"
      width={width || '100%'}
      gap="$2"
      onLayout={(e) => setTriggerWidth?.(e.nativeEvent.layout.width)}>
      <XStack marginBottom="$0.5" flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight="600" variant="body2">
          <LocalizedText value={label} />
        </Typography>

        <Pressable onPress={() => onReset()}>
          <Typography fontWeight="600" variant="body2" color="$brand-700">
            <CoreTrans i18nKey="action.reset" />
          </Typography>
        </Pressable>
      </XStack>
      <XStack
        minHeight={40}
        alignItems="center"
        flex={1}
        borderRadius="$rounded-lg"
        borderWidth={1}
        borderColor="$border-primary"
        flexWrap="wrap"
        paddingVertical="$spacing-md"
        paddingHorizontal="$spacing-lg"
        focusStyle={{borderColor: '$border-brand'}}
        hoverStyle={{borderColor: '$border-brand'}}
        gap="$2"
        style={triggerInputStyles}>
        {selectedValues.map((option) => {
          return (
            <Badge
              alignSelf="center"
              key={option.value}
              label={option.label}
              gap="$1.5"
              suffix={
                <Pressable onPress={() => onChangeSelection(option)}>
                  <IconView icon="Cancel01Icon" />
                </Pressable>
              }
            />
          );
        })}
      </XStack>
    </YStack>
  );
};
