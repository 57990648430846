export const ar = {
  login: 'تسجيل الدخول',
  action: {
    save: 'حفظ',
    reset: 'إعادة ضبط',
    close: 'إغلاق',
    cancel: 'إلغاء',
    next: 'التالي',
    previous: 'السابق',
    seeMore: 'شاهد المزيد',
    accept: 'قبول',
    apply: 'تطبيق',
    ok: 'قبول',
    confirm: 'تأكيد',
  },
  actions: 'أجراءات',
  settings: {
    title: 'الإعدادات',
    general: {
      _name: 'عام',
      connectors: 'التكاملات',
      connector: {
        enable: 'تفعيل',
        url: 'الرابط',
        username: 'اسم المستخدم',
        password: 'كلمة المرور',
        tenant: 'العميل',
        token: 'رمز المصادقة',
      },
    },
  },
  calendar: {
    title: 'التقويم',
    titleKey: 'العنوان',
    time: 'الوقت',
    noEvents: 'لا توجد أحداث',
    from: 'من',
    to: 'الى',
    day: 'يوم',
    week: 'أسبوع',
    month: 'شهر',
    filters: 'فلتر',
    today: 'اليوم',
    m: 'د',
    h: 'سا',
    selectaDate: "اختر وقت",
    months: {
      January: 'يناير',
      February: 'فبراير',
      March: 'مارس',
      April: 'أبريل',
      May: 'مايو',
      June: 'يونيو',
      July: 'يوليو',
      August: 'أغسطس',
      September: 'سبتمبر',
      October: 'أكتوبر',
      November: 'نوفمبر',
      December: 'ديسمبر',
    },
    days: {
      Sunday: 'الأحد',
      Monday: 'الاثنين',
      Tuesday: 'الثلاثاء',
      Wednesday: 'الأربعاء',
      Thursday: 'الخميس',
      Friday: 'الجمعة',
      Saturday: 'السبت',
    },
    dateRanges: {
      today: 'اليوم',
      yesterday: 'أمس',
      lastWeek: 'الأسبوع الماضي',
      thisWeek: 'هذا الأسبوع',
      lastMonth: 'الشهر الماضي',
      thisMonth: 'هذا الشهر',
      lastYear: 'السنة الماضية',
      thisYear: 'هذه السنة',
      allTime: 'كل الوقت',
    },
  },
  validation: {
    required: 'مطلوب',
  },
  note: 'ملاحظة',
  table: {
    searchPlaceholder: 'بحث...',
    displaySearch: 'عرض البحث',
    displayPagination: 'عرض الترقيم الصفحي',
    pageSize: 'حجم الصفحة',
    footer: 'تذييل الصفحة',
    title: 'عنوان',
    row: 'صف',
    addRowError: 'يجب عليك إضافة عمود أولاً',
    columnLabel: 'الاسم',
    columns: 'العماويد',
    rows: 'الصفوف',
    sorting: 'ترتيب',
    searchIn: 'البحث في',
    sortWith: 'الترتيب ب',
  },
  progress: {
    value: 'القيمة',
  },
  card: {
    header: 'عنوان',
    content: 'محتوى',
    footer: 'الجزء السفلي',
  },
  success: 'نجاح',
  error: 'خطأ',
  generalErrorMsg: 'عذرا، هناك خطأ ما.',
  resourceErrorMsg: 'فشل تحميل واجهة برمجة التطبيقات - {{resourceName}} - ',
  successMsg: 'تم, بنجاح',
  connectorMustBeDefined: 'الرجاء تهيئة الموصل أولا',
  connectorIsDisabled: 'برجاء العلم ان هذا الموصل غير مفعل',
  sortBy: 'رتب حسب',
  ago: 'منذ {{value}}',
  pagination: {
    rowsPerPage: 'الصفوف في الصفحة:',
    page: 'صفحة',
    of: 'من',
    perPage: 'كل صفحة',
  },
  hidjriMonths: {
    Muharram: 'مُحَرَّم',
    Safar: 'صفر',
    RabiAlAwwal: 'ربيع الأوّل',
    RabiAlThani: 'ربيع الثاني',
    JumadaAlUla: 'جمادى الأولى',
    JumadaAlThaniyah: 'جمادى الثانية',
    Rajab: 'رجب',
    Shaaban: 'شعبان',
    Ramadan: 'رمضان',
    Shawwal: 'شوّال',
    DhuAlQidah: 'ذو القعدة',
    DhuAlHijjah: 'ذو الحجة',
  },
  builder: {
    loop: {
      name: 'اسم التكرار',
      dataSource: 'مصدر البيانات',
      rule: 'يجب أن يحتوي مكون التكرار على مصدر بيانات واحد فقط من حالة الصفحة. يمكنك اختياره باستخدام {{، وسيتم عرض أنواع البيانات التي تكون من النوع المصفوفة فقط هنا.',
    },
    state: {
      noStateMsg: 'لا توجد متغيرات متاحة لهذا المكون',
    },
    condition: {
      conditionQuery: 'استعلام الشرط',
      group: 'مجموعة',
      if: 'إذا',
      equals: 'يساوي',
      'strict equals': 'يساوي تماماً',
      'not equals': 'لا يساوي',
      'strict not equals': 'لا يساوي تماماً',
      'greater than': 'أكبر من',
      'greater than or equal': 'أكبر من أو يساوي',
      'less than': 'أصغر من',
      'less than or equal': 'أصغر من أو يساوي',
      contains: 'يتضمن',
      AND: 'و',
      OR: 'أو',
      addCondition: 'اضافة شرط',
      addGroupCondition: 'اضافة مجموعة شرط',
    },
  },
  blocks: {
    common: 'الأساسية',
    ContainerElement: 'وعاء',
    TextElement: 'نص',
    ButtonElement: 'زر',
    LinkElement: 'رابط',
    ImageElement: 'صورة',
    VideoElement: 'فيديو',
    WebViewElement: 'نافذة ويب',
    RichTextElement: 'نص غنى',
    YoutubePlayerElement: 'يوتيوب',
    AccordionElement: 'الأكورديون',
    ColumnElement: 'عمود',
    RowElement: 'صف',
    PageElement: 'صفحة',
    TableElement: 'جدول',
    StateElement: 'المتغيرات',
    InputElement: 'مدخل',
    ProgressElement: 'التقدم',
    LoopElement: 'تكرار',
    ConditionElement: 'الشرط',
    CardElement: 'الكارت',
  },
  NODE: 'أساسي',
  LAYOUT: 'تَخطِيط',
  GENERAL: 'عام',
  META: 'ميتا',
  showAllColumns: 'اظهار كل الأعمدة',
  columns: 'الأعمدة',
  scopes: 'المجالات',
  selectAllScopes: 'اظهر كل المجالات',
  showOnly: 'أظهر فقط',
  showDetails: 'التفاصيل',
  testConnection: 'اختبار الاتصال',
  connectionFailed: 'فشل الاتصال',
  connectionSuccess: 'نجاح الاتصال',
  languageChangeMsg: 'لن تسري بعض التغييرات إلا بعد إعادة تشغيل التطبيق',
  noData: 'لاتوجد بيانات',
  fileProgress: {
    dragAndDropOrClickHere: 'قم بسحب وإفلات الملفات أو انقر هنا',
    dropItHere: 'أسقطها هنا',
    uploading: 'جاري التحميل',
    fileUploadedSuccessfully: 'تم تحميل الملف بنجاح',
    MB: 'ميغابايت',
    clickToUpload: 'انقر للتحميل',
    or: 'أو سحب وإفلات الملفات ',
  },
  steps: {
    stepLabel: 'الخطوة {{index}} من {{total}}',
  },
  searchNoMatch: 'لم يتم العثور على نتائج للبحث “{{searchQuery}}”. يرجى المحاولة مرة اخرى.',
};
