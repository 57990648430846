import React, {forwardRef, useEffect, useRef} from 'react';

import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native';

import {Input, InputProps} from '../Input';

export interface BaeInputProps
  extends Omit<InputProps, 'onChange' | 'label' | 'placeholder' | 'suffix' | 'prefix' | 'addOnBefore' | 'addOnAfter'> {
  index: number;
  isActive: boolean;
  onChange: (index: number, value: string) => void;
  onActiveChange: (nextIndex: number) => void;
  forwardRef?: (refElement: any) => void;
}

export const BaseInput = (props: BaeInputProps) => {
  const {value, onChange, onActiveChange, isActive, index, forwardRef, ...restProps} = props;
  const inputRef = useRef<any>(null);

  const _onChangeText = (value: string) => {
    onChange(index, value);
  };

  useEffect(() => {
    if (inputRef) {
      forwardRef?.(inputRef.current);
    }
  }, [inputRef]);

  const _onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    const {key} = event.nativeEvent;

    if (key === 'ArrowLeft') {
      onActiveChange(index - 1);
    } else if (key === 'ArrowRight') {
      onActiveChange(index + 1);
    } else if (key === 'Backspace') {
      setTimeout(() => {
        onActiveChange(index - 1);
      });
    }

    syncSelection();
  };

  const syncSelection = () => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setNativeProps({selection: {start: 0, end: 100}});
    }
  };

  const resetInputProps: InputProps = {
    label: null,
    subtext: '',
    addOnAfter: null,
    addOnBefore: null,
    prefix: null,
    suffix: null,
  };

  return (
    <Input
      ref={inputRef}
      {...restProps}
      maxLength={1}
      containerProps={{
        width: 'max-content',
      }}
      placeholder=""
      value={value}
      onChangeText={_onChangeText}
      onKeyPress={_onKeyPress}
      onPressIn={syncSelection}
      onPressOut={syncSelection}
      {...resetInputProps}
    />
  );
};
