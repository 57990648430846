import {GEN_PATH_ROOT_KEY} from '../../../../config';
import {State} from '../../../../types';
import {determineType} from '../../../../utils';

import {generatePathsFromList} from './generatePathsFromList';
import {generatePathsFromObject} from './generatePathsFromObject';

export function generatePathsFromData(input: State['value']) {
  if (Array.isArray(input)) {
    const isPrimitiveArray = input.every((item) => typeof item !== 'object' || item === null);

    if (isPrimitiveArray) {
      return generatePathForPrimitiveArray(input);
    } else {
      return generatePathsFromList(input);
    }
  } else if (typeof input === 'object') {
    return generatePathsFromObject(input);
  }
}

function generatePathForPrimitiveArray(array: unknown[]) {
  return [
    {
      jsonPath: `${GEN_PATH_ROOT_KEY}.(index)`,
      name: `${GEN_PATH_ROOT_KEY}.(index)`,
      type: determineType(array[0]),
    },
  ];
}
