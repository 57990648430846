export function extractPath(data, path) {
  const getValueByPath = (obj, path) => {
    if (path.startsWith('$')) {
      path = path.slice(2);
    }

    const keys = path.split('.');
    let value = obj;

    for (const key of keys) {
      if (value && key in value) {
        value = value[key];
      } else {
        return undefined;
      }
    }
    return value;
  };
  if (Array.isArray(data)) {
    return data.map((item) => getValueByPath(item, path)).filter((val) => val !== undefined);
  }

  if (typeof data === 'object' && data !== null) {
    const value = getValueByPath(data, path);
    return value !== undefined ? value : [];
  }

  return [];
}
