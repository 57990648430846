import React from 'react';

import {Slider} from 'antd';
import {Trans} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../../utils';
import {ColorInput} from '../../../../ColorInput';
import {ProgressBarProps} from '../../../../ProgressBar';
import {CoreTrans} from '../../../../ScopedTrans';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';

export const _ProgressBarBuilder: PropsBuilder<ProgressBarProps> = ({value: propsValue, onChange, onReset}) => {
  const {color, backgroundColor, value} = usePropsByViewPortSize(propsValue);

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };

  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<CoreTrans i18nKey="progress.value" />} onReset={() => onResetProps(['value'])}>
        <Slider value={value as number} defaultValue={30} onChange={(value) => onUpdateProps('value', value)} />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.style.color" />} onReset={() => onResetProps(['color'])}>
        <ColorInput value={color} onChange={(value) => onUpdateProps('color', value)} />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.style.backgroundColor" />}
        onReset={() => onResetProps(['backgroundColor'])}>
        <ColorInput value={backgroundColor || 'black'} onChange={(value) => onUpdateProps('backgroundColor', value)} />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const ProgressBarBuilder: BuilderPropsGroup<ProgressBarProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.ProgressElement'),
      Content: _ProgressBarBuilder,
    },
  ],
};
