import React from 'react';

import {Trans} from 'react-i18next';

import {PropsBuilder} from '../../../../../types';
import {ColorInput} from '../../../../ColorInput';
import {ViewProps} from '../../../../View';
import {EditSectionElement} from '../../EditSectionElement';
import {SliderInputValuePicker} from '../../SliderInputValuePicker';

import {Container} from './components/Container';

export const Borders: PropsBuilder<ViewProps> = ({value, onChange, onReset}) => {
  const {borderColor, borderRadius, borderWidth} = value;

  return (
    <Container>
      <EditSectionElement
        title={<Trans i18nKey="settings.borders.borderWidth" />}
        onReset={() => onReset(['borderWidth'])}>
        <SliderInputValuePicker max={100} value={borderWidth as any} propName="borderWidth" onUpdate={onChange} />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.borders.borderRadius" />}
        onReset={() => onReset(['borderRadius'])}>
        <SliderInputValuePicker max={200} value={borderRadius as any} propName="borderRadius" onUpdate={onChange} />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.borders.borderColor" />}
        onReset={() => onReset(['borderColor'])}>
        <ColorInput value={borderColor || 'black'} onChange={(value) => onChange('borderColor', value)} />
      </EditSectionElement>
    </Container>
  );
};
