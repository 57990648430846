import React from 'react';

import {useContentElementsResolver} from '../../../../context';
import {ViewportSizes, AppContentPage} from '../../../../types';
import {ErrorBoundary} from '../../../ErrorBoundary';
import {ResourceProvider} from '../../../ResourceProvider';
import {useResourceContext} from '../../../ResourceProvider/Provider';
import {PageContainer} from '../../../ScreenPage';
import {Spinner} from '../../../Spinner';
import {StateProvider} from '../../../StateProvider';
import {View, ViewProps} from '../../../View';
import {useBuilderRenderer} from '../../hooks/useBuilderRenderer';
import {useStateResolver} from '../../hooks/useStateResolver';

type Props = ViewProps & {
  page: AppContentPage | null;
  elements?: Record<string, any>;
  viewportSize?: ViewportSizes;
};

export const PagePreview = ({
  page,
  viewportSize = ViewportSizes.MOBILE,
  elements,
  ...props
}: Props): JSX.Element | null => {
  return (
    <StateProvider state={page?.state}>
      <ResourceProvider resource={page?.resource}>
        <ContentPageUI viewportSize={viewportSize} page={page} elements={elements} {...props} />
      </ResourceProvider>
    </StateProvider>
  );
};

export const ContentPageUI = ({page, viewportSize = ViewportSizes.MOBILE, elements, ...props}: Props) => {
  const builderContentElements = elements || useContentElementsResolver();
  const {resolve} = useStateResolver();
  const {renderUI} = useBuilderRenderer(builderContentElements);
  const {isLoading: isResourceLoading} = useResourceContext();

  const pageProps = resolve(page?.props);
  const resolvedProps = typeof pageProps === 'object' ? pageProps : {};

  if (!page?.children || !Object.keys(builderContentElements).length) return null;

  const renderContent = () => (
    <ErrorBoundary>
      <View {...props} {...resolvedProps}>
        {isResourceLoading ? (
          <Spinner flex={1} justifyContent="center" alignItems="center" />
        ) : (
          renderUI(page?.children)
        )}
      </View>
    </ErrorBoundary>
  );

  if (viewportSize === ViewportSizes.MOBILE) {
    return (
      <PageContainer {...props} {...resolvedProps}>
        {renderContent()}
      </PageContainer>
    );
  }

  return renderContent();
};
