import React, {forwardRef} from 'react';

import {useTranslation} from 'react-i18next';

import {BlockCategory, BlockDefinition, LocalizedTextType} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Input, InputProps} from '../../../../Input';
import {getLocalizedText} from '../../../../LocalizedText';
import {LayoutPropsBuilder} from '../../../components';

import {InputPropsBuilder} from './PropsBuilder';

export type InputElementProps = InputProps & {
  placeholder: LocalizedTextType;
};

const defaultProps: InputElementProps = {
  placeholder: 'Write Here!',
};

export const Component = ({placeholder, ...props}: InputElementProps, ref) => {
  const {t, i18n} = useTranslation();
  const localizedPlaceholder = getLocalizedText({value: placeholder, t, i18n});
  delete props['children'];

  return <Input placeholder={localizedPlaceholder} {...props} ref={ref} />;
};

export const InputComponent = forwardRef(Component);

export const InputElement: BlockDefinition<InputElementProps> = {
  name: coreScopedKey('blocks.InputElement'),
  version: 1,
  id: 'InputElement',
  props: defaultProps,
  Component: InputComponent,
  category: BlockCategory.NODE,
  Icon: 'KeyboardIcon',
  propsBuilder: [InputPropsBuilder, LayoutPropsBuilder],
};
