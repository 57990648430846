import React, {useState} from 'react';

import {uploadFile} from '../../utils/uploadFile';
import {IconView} from '../IconView';
import {Pressable} from '../Pressable';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {FilePickerProps} from './types';

export function FilePicker({pickerOptions, label, onChange, ...props}: FilePickerProps) {
  const [fileName, setFileName] = useState<string>('');
  const pickFile = async () => {
    const res = await uploadFile(pickerOptions);
    if (!res.canceled) {
      setFileName(res.assets.map((it) => it.name).join(', '));
      onChange(res);
    }
  };

  return (
    <Pressable
      borderRadius="$rounded-lg"
      borderColor="$border-secondary"
      borderWidth={1}
      overflow="hidden"
      {...props}
      onPress={pickFile}>
      <View flexDirection="column" width="100%">
        <View alignItems="center" justifyContent="center" paddingVertical="$4" paddingHorizontal="$6" gap="$spacing-lg">
          <View
            alignItems="center"
            justifyContent="center"
            padding="$3"
            borderRadius="$rounded-lg"
            borderColor="$border-secondary"
            borderWidth={1}>
            <IconView icon="CloudUploadIcon" size={20} color="$alpha-black-100" />
          </View>

          <YStack gap="$1" alignItems="center">
            <XStack gap="$0.25">
              <Typography variant="body2" color="$text-brand-secondary" fontWeight="600">
                <CoreTrans i18nKey="fileProgress.clickToUpload" />
              </Typography>
              <Typography variant="body2" color="$text-tertiary" fontWeight="400">
                <CoreTrans i18nKey="fileProgress.or" />
              </Typography>
            </XStack>
            <Typography variant="body2" color="$text-secondary">
              {label}
            </Typography>
            {fileName && (
              <Typography variant="body2" color="$text-tertiary">
                {`(${fileName})`}
              </Typography>
            )}
          </YStack>
        </View>
      </View>
    </Pressable>
  );
}
