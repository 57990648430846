import React from 'react';

import {Checkbox} from '../../../Checkbox';
import {IconView} from '../../../IconView';
import {Popover} from '../../../Popover';
import {Pressable} from '../../../Pressable';
import {CoreTrans} from '../../../ScopedTrans';
import {Typography} from '../../../Typography';
import {View} from '../../../View';
import {XStack} from '../../../XStack';

import {ScopesSelector} from './hooks/useFilterScopes';

type ScopesFilterProps = {
  scopes: string[];
  scopesSelector: ScopesSelector;
};

export const ScopesFilter = ({scopes, scopesSelector}: ScopesFilterProps) => {
  return (
    <Popover
      contentProps={{padding: 0, borderRadius: '$rounded-lg', overflow: 'hidden'}}
      hideArrow
      closable
      trigger={
        <View
          borderWidth={1}
          borderColor="$border"
          borderRadius="$rounded-lg"
          paddingHorizontal="$2"
          paddingVertical="$2"
          hoverStyle={{cursor: 'pointer', borderWidth: 1, backgroundColor: '$bg-brand-primary'}}>
          <IconView icon="Layout2ColumnIcon" color="$gray-500" />
        </View>
      }>
      <XStack
        paddingHorizontal="$3"
        borderRadius="$2"
        minHeight="$4"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="$gray-200">
        <Typography marginHorizontal="$0.5">
          <CoreTrans i18nKey="scopes" />
        </Typography>
        <Pressable onPress={() => scopesSelector.selectAllScopes()}>
          <Typography color="$brand-700" marginHorizontal="$0.5">
            <CoreTrans i18nKey="selectAllScopes" />
          </Typography>
        </Pressable>
      </XStack>
      {scopes?.map((scope, index) => {
        return (
          <XStack
            key={index}
            minWidth="$18"
            width="100%"
            alignItems="center"
            gap={10}
            paddingVertical={12}
            paddingHorizontal="$3">
            <XStack alignItems="center" gap="$2" flex={1}>
              <Checkbox
                checked={scopesSelector.isScopeSelected(scope)}
                onCheckedChange={(checked) => scopesSelector.onFilterScopes(scope, checked)}
              />
              <Typography>{scope}</Typography>
            </XStack>
            <Pressable onPress={() => scopesSelector.showOneScope(scope)}>
              <Typography color="$brand-700">
                <CoreTrans i18nKey="showOnly" />
              </Typography>
            </Pressable>
          </XStack>
        );
      })}
    </Popover>
  );
};
