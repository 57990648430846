import {State} from './PageDesign';

export type SectionAPISettings = {
  collectionId: number;
  requestId: number;
};

export type APISettings = {
  requestId: number;
  predifinedPath: PredefinedPath;
  contextId?: string;
};

// TODO: add default value
export interface PredefinedPath {
  jsonPath: string;
  name: string;
  type: State['type'];
}

export interface ApiRequest {
  id: string | number;
  name: string;
  method: keyof typeof MethodType;
  url: string;
  collectionId: Collection['id'];
  queryParams?: QueryParam[];
  headers?: Header[];
  requestBody?: RequestBody;
  settings?: Setting[];
  predefinedPaths?: PredefinedPath[];
}

export interface Collection {
  id: string | number;
  name: string;
  description?: string;
  variables?: Variable[];
  requests: ApiRequest[];
}

export interface Variable {
  key: string;
  value: string;
}
export interface QueryParam {
  key: string;
  value: string;
  description?: string;
}

export interface Header {
  key: string;
  value: string;
  description?: string;
}

export interface RequestBody {
  type: RequestBodyType;
  raw: {
    content: string;
    mimeType: RequestBodyMIMETYPE;
  };
}

export interface Setting {
  designation: string;
  action: string;
}

export const MethodType = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
} as const;

export enum RequestBodyType {
  NONE = 'none',
  FORMDATA = 'form-data',
  FORMURLENCODED = 'x-www-form-urlencoded',
  RAW = 'raw',
  BINARY = 'binary',
  GRAPHQL = 'graphQL',
}

export enum RequestBodyMIMETYPE {
  JSON = 'application/json',
  PLAIN = 'text/plain',
  TEXTXML = 'text/xml',
  XML = 'application/xml',
  HTML = 'text/html',
}
