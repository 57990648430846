import React from 'react';

import * as Linking from 'expo-linking';
import {XStack, YStack, ScrollView} from 'tamagui';

import {Typography} from '../../../components/Typography';
import {dayjs} from '../../../utils/extendedDayjs';
import {Button} from '../../Button';
import {LocalizedText} from '../../LocalizedText';
import {CoreTrans} from '../../ScopedTrans';
import {CalendarEvent} from '../types';

interface Props {
  eventItem: CalendarEvent;
}

export const EventDetails = ({eventItem}: Props) => {
  const widgetSource = eventItem?.appletId?.replace('_', ' ');

  return (
    <YStack justifyContent="space-between" paddingHorizontal="$0.5" paddingVertical="$0.5">
      <ScrollView marginBottom="$1.5" flexGrow={1}>
        <XStack alignItems="center" flex={1} justifyContent="flex-end">
          {widgetSource && (
            <Typography
              fontWeight="600"
              color="$textColor"
              borderColor="$border"
              $sm={{height: '$2'}}
              paddingBottom="$1"
              borderWidth={1}
              alignItems="center"
              paddingVertical="$1"
              paddingHorizontal="$0.5"
              borderRadius="$rounded-lg">
              {widgetSource}
            </Typography>
          )}
        </XStack>
        <XStack alignItems="center">
          <Typography
            paddingHorizontal="$0.5"
            marginVertical="$0.5"
            backgroundColor="$bg-brand-primary"
            borderRadius="$5"
            marginHorizontal="$0.5"
            fontWeight="700">
            <CoreTrans i18nKey="calendar.titleKey" />
          </Typography>
          <Typography fontWeight="600" color="$textColor" marginHorizontal="$0.5">
            {eventItem.title}
          </Typography>
        </XStack>
        <XStack alignItems="center">
          <Typography
            paddingHorizontal="$0.5"
            marginVertical="$0.5"
            backgroundColor="$bg-brand-primary"
            borderRadius="$5"
            marginHorizontal="$0.5"
            fontWeight="700">
            <CoreTrans i18nKey="calendar.time" />
          </Typography>
          <Typography fontWeight="600" color="$textColor" marginHorizontal="$0.5">
            {dayjs(eventItem.start || eventItem.date).format('hh:mm A')}
            {eventItem.end && ' - ' + dayjs(eventItem.end).format('hh:mm A')}
          </Typography>
        </XStack>
        {eventItem.extraInfo?.map((item, index) => (
          <XStack alignItems="center" key={index}>
            <Typography
              paddingHorizontal="$0.5"
              marginVertical="$0.5"
              backgroundColor="$bg-brand-primary"
              borderRadius="$5"
              marginHorizontal="$0.5"
              fontWeight="700">
              <LocalizedText value={item.label} />
            </Typography>
            <Typography fontWeight="600" color="$textColor" marginHorizontal="$0.5">
              {item.value}
            </Typography>
          </XStack>
        ))}
      </ScrollView>
      {eventItem.redirectUrl && (
        <Button
          alignSelf="flex-end"
          width="$12"
          onPress={() => {
            Linking.openURL(eventItem.redirectUrl || '');
          }}
          hierarchy="secondary-color">
          <CoreTrans i18nKey="action.seeMore" />
        </Button>
      )}
    </YStack>
  );
};
