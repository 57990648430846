import React, {useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import {Adapt, Select as BaseSelect, Sheet, SizeTokens, YStack} from 'tamagui';
import {LinearGradient} from 'tamagui/linear-gradient';

import {useAppDirection} from '../../utils';
import {useResponsiveValue} from '../../utils/withResponsiveProps';
import {IconView} from '../IconView';
import {Input} from '../Input';
import {ListItem} from '../ListItem';
import {LocalizedText, getLocalizedText} from '../LocalizedText';
import {NoDataMessage} from '../NoDataMessage';
import {Typography} from '../Typography';
import {View} from '../View';

import {TriggerContainer} from './components/TriggerContainer';
import {SelectProps} from './types';

export * from './types';

export function Select({
  onChange,
  options,
  width,
  value,
  placeholder,
  unstyled,
  children,
  error,
  subtext,
  modelHeight = '30%',
  prefix,
  disabled,
  triggerProps,
  trigger,
  open,
  listItemProps,
  label,
  onOpenChange,
  ...props
}: SelectProps) {
  const itemsConfig = useResponsiveValue<{size?: SizeTokens; iconSize: number}>({
    $sm: {size: '$4', iconSize: 17},
    $md: {size: props.size, iconSize: 16},
  });

  const {t, i18n} = useTranslation();
  const {direction} = useAppDirection();
  const contentDirection = direction === 'ltr' ? 'rtl' : 'ltr';

  const selectedValue = useMemo(() => {
    if (!value) return;
    return options?.find((op) => op.value === value);
  }, [options, value]);

  return (
    <BaseSelect value={value} onValueChange={onChange} open={open} onOpenChange={onOpenChange}>
      <YStack width={width} {...props}>
        <TriggerContainer {...triggerProps}>
          {trigger || (
            <View>
              <Input
                label={label}
                placeholder={getLocalizedText({value: placeholder, t, i18n})}
                disabled={disabled}
                editable={false}
                prefix={selectedValue?.prefix || prefix}
                error={error}
                value={getLocalizedText({value: selectedValue?.label, t, i18n})}
                suffix={<IconView icon="ArrowDown01Icon" size={17} color="$fg-quaternary" />}
                focusVisibleStyle={{
                  outlineWidth: 0,
                }}
                containerProps={{
                  width: '100%',
                  marginHorizontal: 0,
                }}
                unstyled={unstyled}
              />
            </View>
          )}
        </TriggerContainer>

        {subtext && <Typography color={error ? '$text-error-primary' : ''}>{subtext}</Typography>}
      </YStack>
      <Adapt when="sm" platform="touch">
        <Sheet snapPoints={[parseFloat(modelHeight === 'full' ? '95%' : modelHeight), 90]} modal dismissOnSnapToBottom>
          <Sheet.Frame padding="$1" style={{direction: 'ltr'}}>
            <Sheet.ScrollView>
              <Adapt.Contents />
            </Sheet.ScrollView>
          </Sheet.Frame>
          <Sheet.Overlay zIndex={5} position="absolute" />
        </Sheet>
      </Adapt>
      <BaseSelect.Content zIndex={200000000}>
        <BaseSelect.ScrollUpButton
          alignItems="center"
          justifyContent="center"
          position="relative"
          width="100%"
          height="$3">
          <YStack zIndex={10}>
            <IconView icon="ArrowUp01Icon" size={20} brand />
          </YStack>

          <LinearGradient
            start={[0, 0]}
            end={[0, 1]}
            fullscreen
            colors={['$bg-primary', '$transparent']}
            borderRadius="$rounded-2xl"
          />
        </BaseSelect.ScrollUpButton>

        <BaseSelect.Viewport direction={trigger ? contentDirection : 'ltr'} borderRadius="$rounded-lg" padding={10}>
          {!options?.length && !children && <NoDataMessage hasNoData hideRipples />}

          {options?.map(({label, value: optionValue, prefix, suffix}, i) => (
            <BaseSelect.Item
              index={i}
              key={optionValue}
              value={optionValue}
              padding={0}
              hoverStyle={null}
              cursor="pointer"
              borderRadius="$rounded-lg"
              alignItems="center"
              justifyContent="center">
              <ListItem
                borderRadius="$rounded-lg"
                prefix={prefix}
                title={
                  <Typography margin={0}>
                    <LocalizedText value={label} />
                  </Typography>
                }
                suffix={optionValue === value ? <IconView icon="Tick02Icon" size={itemsConfig.iconSize} /> : suffix}
                {...listItemProps}
              />
            </BaseSelect.Item>
          ))}

          {children}
        </BaseSelect.Viewport>

        <BaseSelect.ScrollDownButton
          alignItems="center"
          justifyContent="center"
          position="relative"
          width="100%"
          height="$3">
          <YStack zIndex={10}>
            <IconView icon="ArrowDown01Icon" size={20} brand />
          </YStack>

          <LinearGradient
            start={[0, 0]}
            end={[0, 1]}
            fullscreen
            colors={['$backgroundTransparent', '$bg-primary']}
            borderRadius="$rounded-lg"
          />
        </BaseSelect.ScrollDownButton>
      </BaseSelect.Content>
    </BaseSelect>
  );
}
