import {AppContentPage} from '../../../types';

export function shouldDisplayContentPage(
  page: AppContentPage,
  showAllWidgets = false,
  isAuthenticated = false,
): boolean | undefined {
  if (page.public || showAllWidgets) return true;
  if (isAuthenticated) return true;

  return false;
}
