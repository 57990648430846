import React, {ReactNode} from 'react';

import {Switch as BaseSwitch} from 'tamagui';

import {Typography} from '../Typography';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {useSwitchState} from './useSwitchState';
import {useSwitchStyles} from './useSwitchStyles';

export type SwitchProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  text?: ReactNode;
  supportText?: ReactNode;
};

export const Switch: React.FC<SwitchProps> = ({
  checked = false,
  disabled = false,
  onChange,
  text,
  supportText,
  ...props
}) => {
  const {handleSwitch} = useSwitchState(checked);

  const styles = useSwitchStyles(checked, disabled);

  return (
    <XStack gap={3} alignItems="flex-start" cursor="pointer">
      <XStack
        focusStyle={
          disabled
            ? {}
            : {
                borderColor: '$brand-700',
                borderWidth: 2,
                padding: 4,
              }
        }
        tabIndex={0}
        borderRadius="$24"
        alignItems="center"
        padding={2}>
        <BaseSwitch
          {...props}
          direction="ltr"
          size="$2.5"
          checked={checked}
          onCheckedChange={(checked) => {
            handleSwitch(checked);
            if (onChange) {
              onChange(checked);
            }
          }}
          disabled={disabled}
          backgroundColor={styles.switchBackgroundColor}
          borderColor={styles.switchBackgroundColor}>
          <BaseSwitch.Thumb animation="quick" backgroundColor="$alpha-white-100" />
        </BaseSwitch>
      </XStack>

      <YStack>
        {text && (
          <Typography fontSize="$16" color="$text-secondary">
            {text}
          </Typography>
        )}
        {supportText && (
          <Typography fontSize="$16" color="$text-tertiary">
            {supportText}
          </Typography>
        )}
      </YStack>
    </XStack>
  );
};
