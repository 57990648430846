import React, {useMemo, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {coreScopedKey} from '../../utils';
import {dayjs} from '../../utils/extendedDayjs';
import {Datepicker} from '../DatePicker';
import {IconView} from '../IconView';
import {Input} from '../Input';
import {Popover} from '../Popover';
import {View} from '../View';

import {DateInputProps} from './models';
import {formatInpuValue} from './utils';

export const DateInput: React.FC<DateInputProps> = ({
  placeholder,
  value = '',
  isDisabled,
  disableDatePicker,
  calendarType = 'gregory',
  mode = 'single',
  format = 'MMM D, YYYY',
  minimumDate,
  maximumDate,
  excludeDates,
  allowedDates,
  onBlur,
  onChange,
  onCancel,
  filterDate,
  ...props
}) => {
  const {t} = useTranslation();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');

  const formattedValue = useMemo(() => formatInpuValue(value, format), [value, format]);
  const defaultPlaceholder = t(coreScopedKey('calendar.selectaDate'));
  const inputValue = value || formattedDate !== 'Invalid Date' ? formattedValue || formattedDate : defaultPlaceholder;

  const handleDateChange = (date) => {
    onChange?.(date);
    const formattedDate =
      mode !== 'dual'
        ? dayjs(date).format(format)
        : `${dayjs(date[0]).format(format)}  -  ${dayjs(date[1]).format(format)}`;
    setFormattedDate(formattedDate);
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    onCancel?.();
  };

  return (
    <Popover
      open={showDatePicker}
      onOpenChange={setShowDatePicker}
      closable
      contentProps={{padding: 0, borderRadius: '$rounded-lg', justifyContent: 'center', alignItems: 'center'}}
      trigger={
        <View width="100%">
          <Input
            disabled={isDisabled}
            placeholder={placeholder}
            editable={false}
            selectTextOnFocus={false}
            onBlur={onBlur}
            containerProps={{width: '100%'}}
            {...props}
            value={inputValue}
            prefix={props.prefix || <IconView icon="Calendar04Icon" />}
          />
        </View>
      }>
      <Datepicker
        onChange={handleDateChange}
        onCancel={handleCancel}
        maximumDate={maximumDate ? new Date(maximumDate) : undefined}
        minimumDate={minimumDate ? new Date(minimumDate) : undefined}
        mode={mode}
        filterDate={filterDate}
        excludeDates={excludeDates}
        allowedDates={allowedDates}
        isDisabled={disableDatePicker}
        type={calendarType}
        inline
      />
    </Popover>
  );
};
