import React, {ReactNode} from 'react';

import {Checkbox as BaseCheckbox, CheckboxProps as BaseCheckboxProps, SizeTokens, Text} from 'tamagui';

import {IconView} from '../IconView';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {StateTransition, useCheckboxState} from './useCheckboxState';

export type CheckboxProps = BaseCheckboxProps & {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;
  indeterminate?: boolean;
  size?: SizeTokens;
  stateTransition?: StateTransition;
  text?: ReactNode;
  supportText?: ReactNode;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  indeterminate = false,
  onCheckedChange,
  stateTransition = 'checkedUnchecked',
  disabled = false,
  text,
  supportText,
  ...props
}) => {
  const {state, handlePress: originalHandlePress} = useCheckboxState(checked, indeterminate, stateTransition);

  const handleCheckedChange = () => {
    originalHandlePress();
    if (onCheckedChange) {
      onCheckedChange(state === 'unchecked' ? true : false);
    }
  };
  const icon = state === 'checked' ? 'Tick02Icon' : state === 'indeterminate' ? 'MinusSignIcon' : null;
  const color = disabled ? '$gray-300' : state === 'unchecked' ? '$brand-700' : '$alpha-white-100';
  const backgroundColor = state === 'checked' || state === 'indeterminate' ? '$brand-700' : '$alpha-white-100';
  const disabledBackgroundColor = disabled ? '$gray-iron-50' : backgroundColor;

  return (
    <XStack gap={3}>
      <XStack
        focusStyle={{borderColor: '$brand-700', borderWidth: 2}}
        borderRadius={4}
        margin="$1"
        justifyContent="center"
        borderWidth={0}
        borderStyle="unset">
        <BaseCheckbox
          {...props}
          backgroundColor={disabled ? disabledBackgroundColor : backgroundColor}
          size="$3.5"
          style={{
            borderRadius: '$4',
          }}
          disabled={disabled}
          onCheckedChange={() => handleCheckedChange()}>
          {icon && <IconView color={color} icon={icon} size={17} />}
        </BaseCheckbox>
      </XStack>

      {(text || supportText) && (
        <YStack>
          {text && (
            <Text fontSize="$16" color="$text-secondary">
              {text}
            </Text>
          )}
          {supportText && (
            <Text fontSize="$16" color="$text-tertiary">
              {supportText}
            </Text>
          )}
        </YStack>
      )}
    </XStack>
  );
};
