import React from 'react';

import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

import {FeaturedIconStep} from './components/FeaturedIconStep';
import {IconStep} from './components/IconStep';
import {Label} from './components/Label';
import {LineStep} from './components/LineStep';
import sizes from './config';
import {StepsProps, StepsType} from './model';
import {getLabelStyle, isCurrentStep} from './utils';

export const ProgressSteps = ({
  steps,
  currentStep = 1,
  type = 'Icons',
  showLabels = false,
  orientation = 'horizontal',
  stepSize = 'medium',
  showCurrentStepLabel = true,
  isMinimal = false,
  size = 600,
}: StepsProps) => {
  const singleStepSize = sizes[stepSize].stepSize;
  const gap = sizes[stepSize].gap;
  const isHorizontal = orientation === 'horizontal';
  const stepWidth = size / steps.length;
  const minimalSize = isMinimal && type !== StepsType.Lines;
  return (
    <XStack gap={gap}>
      {showCurrentStepLabel && (
        <Typography fontWeight="500" variant="body1" color="$text-secondary">
          <CoreTrans i18nKey="steps.stepLabel" values={{index: currentStep, total: steps.length}} />
        </Typography>
      )}
      <XStack flexDirection={isHorizontal ? 'column' : 'row'} gap={gap} width={minimalSize ? 'auto' : size}>
        <XStack alignItems="center" gap={minimalSize ? gap : 0} flexDirection={isHorizontal ? 'row' : 'column'}>
          {steps.map((step, index) => {
            return (
              <>
                {type === StepsType.Icons && (
                  <IconStep
                    isHorizontal={isHorizontal}
                    size={singleStepSize}
                    isMinimal={minimalSize || index === steps.length - 1}
                    lineWidth={size / steps.length - 1}
                    finishedLine={index + 1 < currentStep}
                    key={index}
                    currentStep={currentStep}
                    index={index}
                  />
                )}
                {type === StepsType.Lines && (
                  <LineStep
                    isHorizontal={isHorizontal}
                    stepWidth={stepWidth}
                    index={index}
                    currentStep={currentStep}
                    gap={gap}
                    key={index}
                  />
                )}
                {type === StepsType.FeaturedIcons && (
                  <FeaturedIconStep
                    isHorizontal={isHorizontal}
                    size={singleStepSize}
                    isMinimal={minimalSize || index === steps.length - 1}
                    lineWidth={size / steps.length - 1}
                    finishedLine={index + 1 < currentStep}
                    key={index}
                    icon={step.icon}
                  />
                )}
              </>
            );
          })}
        </XStack>
        {showLabels && !isMinimal && (
          <XStack
            alignItems="center"
            backgroundColor="$bg-primary"
            flexDirection={isHorizontal ? 'row' : 'column'}
            gap={gap}>
            {steps.map((step, index) => {
              return (
                <Label
                  key={index}
                  fontSize={sizes[stepSize].fontSize || '$text-md'}
                  label={step.label || ''}
                  subtitle={step.subtitle || ''}
                  isCurrentStep={isCurrentStep(currentStep, index)}
                  type={type}
                  containerProps={getLabelStyle({
                    index,
                    isHorizontal,
                    type,
                    gap,
                    stepWidth,
                  })}
                />
              );
            })}
          </XStack>
        )}
      </XStack>
    </XStack>
  );
};
