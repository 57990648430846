import React from 'react';

import {dayjs} from '../../../utils/extendedDayjs';
import {Input} from '../../Input';
import {ViewProps} from '../../View';
import {XStack} from '../../XStack';
import {CalendarType} from '../models';
import {Button} from '../../Button';
import {useDatepickerContext} from '../context/DatepickerProvider';
import {CoreTrans} from '../../ScopedTrans';
import {useTranslation} from 'react-i18next';
import {coreScopedKey} from '../../../utils';

type Props = {
  type: CalendarType;
  state: {
    startDate: Date | null;
    endDate: Date | null;
  };
  isRangePicker: boolean;
  showContent: boolean;
} & ViewProps;

export function DatePickerValueInput({type, state, isRangePicker, showContent, ...props}: Props) {
  const {t} = useTranslation();
  const {onDateSelect, focusedDate} = useDatepickerContext();

  const placeholder = t(coreScopedKey('calendar.selectaDate'));
  const {startDate, endDate} = state;

  if (!showContent) return null;

  return (
    <XStack gap="$spacing-lg" {...props}>
      <Input
        maxWidth={isRangePicker ? 136 : 194}
        value={
          dayjs(startDate || focusedDate)
            .calendar(type)
            .format('MMM D, YYYY') || placeholder
        }
      />
      {isRangePicker ? (
        <Input maxWidth={136} value={endDate ? dayjs(endDate).calendar(type).format('MMM D, YYYY') : placeholder} />
      ) : (
        <Button hierarchy="secondary-gray" onPress={() => onDateSelect(dayjs().toDate())}>
          <CoreTrans i18nKey={'calendar.dateRanges.today'} />
        </Button>
      )}
    </XStack>
  );
}
