import React, {useRef, useMemo, useState, useCallback} from 'react';

import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {Agenda} from 'react-native-calendars';
import {LocaleConfig} from 'react-native-calendars';
import {useTheme} from 'tamagui';

import {NoDataMessage} from '../../../../components/NoDataMessage';
import {View} from '../../../../components/View';
import {useAppConfig} from '../../../../context/AppContext';
import {useAppTheme} from '../../../../context/AppThemeContext';
import {Theme} from '../../../../types';
import {useCalendar} from '../../context/CalendarContext';
import {AgendaEventItem} from '../../types';
import {CalendarHeader} from '../CalendarHeader';
import {EventItem} from '../EventItem';

import './localization/en';
import './localization/ar';

export const MobileCalendar = ({eventContent, calendarHeader, calendarRef, noTitle}: any) => {
  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const {theme} = useAppTheme();
  const {background} = useTheme();
  const {events} = useCalendar();

  const {i18n} = useTranslation();
  LocaleConfig.defaultLocale = i18n.language;

  const appConfig = useAppConfig();
  const agendaRef = useRef<Agenda | null>(null);

  const eventsByDate = useMemo(() => {
    const _eventsByDate = events.reduce((acc: any, event) => {
      const eventDate = dayjs(event.date).format('YYYY-MM-DD');

      acc[eventDate] ? acc[eventDate].push(event) : (acc[eventDate] = [event]);
      return acc;
    }, {});

    return _eventsByDate;
  }, [events]);

  const onDayChange = useCallback((day: {dateString: string}) => {
    const selecteDate = dayjs(day.dateString).toDate();
    setSelectedDate(selecteDate);
  }, []);

  const calendarBackground = background?.val;

  return (
    <View key={calendarBackground} backgroundColor="$background" flex={1}>
      {calendarHeader !== undefined
        ? calendarHeader
        : !noTitle && <CalendarHeader selectedDate={selectedDate} onDateChange={setSelectedDate} withPrint={false} />}
      <Agenda
        key={calendarBackground}
        theme={{
          calendarBackground,
          selectedDotColor: appConfig.brandColor,
          todayBackgroundColor: appConfig.brandColor,
          selectedDayBackgroundColor: 'transparent',
          selectedDayTextColor: appConfig.brandColor,
          todayTextColor: 'white',
          dotColor: appConfig.brandColor,
          backgroundColor: appConfig.brandColor,
          arrowColor: appConfig.brandColor,
          agendaDayNumColor: theme === Theme.dark ? 'white' : 'black',
          agendaDayTextColor: theme === Theme.dark ? 'white' : 'black',
          agendaTodayColor: appConfig.brandColor,
          //todo : investigate a better solution
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'stylesheet.agenda.main': {
            reservations: {backgroundColor: 'transparent', flex: 1, marginTop: 100},
          },
        }}
        ref={(element) => {
          agendaRef.current = element;
          if (calendarRef) calendarRef.current = element;
        }}
        items={eventsByDate}
        showClosingKnob
        selected={selectedDate.toString()}
        showSixWeeks
        style={{
          backgroundColor: 'transparent',
        }}
        hideExtraDays={false}
        onDayPress={onDayChange}
        onDayChange={onDayChange}
        extraData={({_, idx}) => idx}
        enableSwipeMonths={true}
        scrollEnabled
        renderEmptyData={() => <NoDataMessage hasNoData />}
        renderItem={(item) => eventContent?.(item) || <EventItem eventItem={item as AgendaEventItem} />}
        markingType="dot"
      />
    </View>
  );
};
