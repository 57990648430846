import React, {ComponentProps, forwardRef, useMemo} from 'react';

import {Stack} from 'tamagui';

import {SHADOW_BASE} from '../../lib/tamagui/constant';
import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export interface ViewProps extends ComponentProps<typeof Stack> {
  shadow?: keyof typeof SHADOW_BASE;
}

function _View({shadow, ...props}: ViewProps, ref) {
  const _props = useDirectionAwareProps(props);
  const shadowStyle = useMemo(() => (shadow ? SHADOW_BASE[shadow] : {}), [shadow]);

  return <Stack {..._props} {...shadowStyle} ref={ref} />;
}

export const View = forwardRef(_View);
