import {State, StateTypes} from '../../types';

export function determineType(value: unknown): State['type'] {
  if (Array.isArray(value)) {
    if (value.every((item) => typeof item === 'number' || typeof item === 'string')) {
      return StateTypes.array;
    }
    return StateTypes.json;
  }

  if (typeof value === 'object') {
    return StateTypes.json;
  }

  if (typeof value === 'number') {
    return StateTypes.number;
  }

  if (typeof value === 'string') {
    return StateTypes.string;
  }

  if (typeof value === 'boolean') {
    return StateTypes.boolean;
  }

  throw new Error('Unsupported data type');
}
